import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { openTooltip } from 'src/app/directives/tooltip.directive';
import { EventPreviewComponent } from 'src/app/pages/@dialogs/event-preview/event-preview.component';
import { getFriendlyAge, getPrimaryAssociation, getRACIroles, getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { ActiveProjectService } from 'src/app/services/active-project.service';
import { Fetch } from 'src/app/services/fetch.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { priorityList } from 'src/app/types/event-enums';
import { DTO, DTORef, RagMap, ReferenceType } from 'src/dto/dto';
import { User_v1 } from 'src/dto/eom/user-v1';
import { Event_v1 } from 'src/dto/pmo/event-v1';
import { EventType } from 'src/dto/pmo/eventtype';


const PRIORITY_LABELS = priorityList.reduce((labels, p) => {
    labels[p.id] = p.label;
    return labels;
}, {});

@Injectable({
    providedIn: "root"
})
export class DtoService {

    public readonly NOTE_ACTIVITY_COLORS = Object.seal([
        "#4caf50",
        "#ffc107",
        "#ffeb3b",
        "#cddc39",
        "#80deea",
        // "#0CD5FF",
        "#03a9f4"
    ]);

    constructor(
        private readonly fetch: Fetch,
        private readonly user: UserService,
        private readonly tenant: TenantService,
        private readonly project: ActiveProjectService,
        private readonly dialog: MatDialog
    ) {
        window['dtoService'] = this;
    }

    getDiscussionColor(numOfDaysSinceLastPost: number) {
        return this.NOTE_ACTIVITY_COLORS[Math.round(numOfDaysSinceLastPost)] || "#1FB1FE";
    }

    // /**
    //  * Find the children based on the property
    //  */
    // getChildren(dto: DTO) {

    // }

    async getResolvedUsers(asset: DTO): Promise<DTORef[]> {
        return asset.assignedTo.filter(a => a.type == ReferenceType.USER).map(a => ({
            id: a.id,
            name: a.name,
            dto: "User_v1",
            description: undefined,
            parentId: undefined,
            idx: undefined,
            icon: undefined
        }));
        // return this.fetch.get(`/api/resolveUsers?asset=${asset.dto}.${asset.id}&$mode=basic`);
    }

    async getFullDTOs(dtoName: string, ids: any[], key = "id", mode?: string) {
        if (!dtoName || !(ids?.length > 0)) return [];

        // If we have multiple possible DTO names, then check all of their endpoint.
        const dtoRequests = dtoName
            .split(",")
            .map(dtoName => DTO.getEndpointAPI(dtoName).get(key + " in (" + ids.join() + ")", mode));
        const dtos = (await Promise.all(dtoRequests)).flat().unique(key);

        dtos.forEach(dto => {
            // Ensure that we have parsed KPI data. Should not be necessary,
            // But we've seen this happen sometimes. TODO: Eliminate this.
            if (typeof dto.kpiData == "string" && dto.kpiData != "")
                dto.kpiData = JSON.parse(dto.kpiData);

            // Ensure we have an icon for each DTORef present in any of the 'related' fields.
            DTO.getMetaData(dto.dto)._related.forEach(relatedProp => {
                dto[relatedProp.prop]?.forEach(dtoRef => {
                    dtoRef.icon = dtoRef.icon || DTO.getMetaData(relatedProp.class)._icon;
                });
            });
        });

        return dtos;
    }

    /**
     * Return the reference type of the current asset.
     * Returns `null` if no reference type is found
     */
    public getAssetReferenceType(asset: DTO) {
        const chunks = asset?.dto?.toUpperCase().replace(/-/g, "_").split('_');
        if (!chunks) return null;
        chunks.pop();
        const dtoId = chunks.join('_');
        return ReferenceType[dtoId] as number;
    }

    /**
     * Get the associated work for a given DTO.
     * @param asset DTO that we're fetching associated work for
     * @param eventTypes Event types (task, issue, risk)
     * @param daysUntilDue Set to 14 to return all tasks that are due within 14 days
     * @param completedWithinDays Set to 14 to return all tasks that were completed between 14 days ago and now
     * @param minPercentComplete Set to filter the tasks returned by their percent complete
     */
    public getAssociatedWork(
        asset: DTO,
        eventTypes?: ("task" | "issue" | "risk")[],
        daysUntilDue?: number,
        completedWithinDays?: number,
        minPercentComplete?: number
    ) {
        const refType = this.getAssetReferenceType(asset);
        const eventType = eventTypes.map(e => EventType[e.toUpperCase()])
            .map(e => ({ eventType: e }));

        let url = `/api/eda/v1.0/data` +
            `?name=associatedwork_get` +
            `&referenceType=${refType}` +
            `&referenceID=${asset.id}` +
            `&mode=full|kpi_notes` +
            `&select=true`;

        if (eventTypes) {
            url += `&eventType=${JSON.stringify(eventType)}`;
        }
        if (daysUntilDue) {
            url += `&daysUntilDue=${Math.round(daysUntilDue)}`;
        }
        if (completedWithinDays) {
            url += `&completedWithinDays=${Math.round(completedWithinDays)}`;
        }
        if (minPercentComplete) {
            url += `&pctComplete=${minPercentComplete}`;
        }

        return this.fetch.getOData<Event_v1[]>(url)
            .then(results => (results?.value || []).map(event => {
                const metadata = DTO.getMetaData(event.dto);
                if (metadata) event.dto = metadata._dto;

                // Ensure deep properties are serialized JSON.
                if (typeof event.roots == 'string')
                    event.roots = JSON.parse(event.roots || "[]");
                if (typeof event.tags == 'string')
                    event.tags = JSON.parse(event.tags as any || '[]');
                if (typeof event.associations == 'string')
                    event.associations = JSON.parse(event.associations as any || '[]');
                if (typeof event.assignments == 'string')
                    event.assignments = JSON.parse(event.assignments as any || '[]');
                if (typeof event.assignedTo == 'string')
                    event.assignedTo = JSON.parse(event.assignedTo as any || '[]');
                if (typeof event.kpiData == 'string')
                    event.kpiData = JSON.parse(event.kpiData as any || '{}');

                // Ensure all Dates are truly deserialized
                if (typeof event.createdOn == "string")
                    event.createdOn = new Date(event.createdOn);
                if (typeof event.endDate == "string")
                    event.endDate = new Date(event.endDate);
                if (typeof event.startDate == "string")
                    event.startDate = new Date(event.startDate);
                if (typeof event.updatedOn == "string")
                    event.updatedOn = new Date(event.updatedOn);
                if (typeof event.completedDate == "string")
                    event.completedDate = new Date(event.completedDate);

                event['_breadcrumb'] = event.roots?.map(r => r.name).join(" / ");
                // event['_associations'] = getAssignedParties(event, this.tenant.users.value);

                let user = asset as User_v1;
                if (asset.dto != "User_v1") {
                    user = this.user.value;
                }

                getRACIroles(event, user, this.tenant.users.value);

                event['_rag'] = RagMap[event.rag] || 4;
                event['_type'] = event['edtcode']?.substring(3) || "TSK";

                // If we have roots, resolve the project, goal, objective, and subtasks levels
                // above the current event.
                if (event.roots) {
                    // Roots in this format are backwards
                    if (typeof event.roots[0]['depth'] == "number") {
                        event.roots.sort((a,b) => a['depth'] - b['depth']);
                        event.roots.reverse();
                    }

                    let tmp = [...event.roots];
                    event['_projectName'] = tmp.pop()?.name;
                    event['_goalName'] = tmp.pop()?.name;
                    event['_objectiveName'] = tmp.pop()?.name;
                    event['_parentTask1Name'] = tmp.pop()?.name;
                    event['_parentTask2Name'] = tmp.pop()?.name;
                    event['_parentTask3Name'] = tmp.pop()?.name;
                    event['_parentTask4Name'] = tmp.pop()?.name;
                }

                // Calculate how long ago the event was created
                const ageMs = Date.now() - new Date(event.startDate).getTime();
                event['_age'] = getFriendlyAge(ageMs);

                const endTime = new Date(event.endDate).getTime();
                event['_overdue'] = endTime < Date.now() && event.progress < 100;

                return event;
            }));
    }

    /**
     * Correlate an event with it's owner and calculate defaults
     */
    public decorateEvent(event: Event_v1, users: User_v1[]) {
        if (typeof event.roots == 'string')
            event.roots = JSON.parse(event.roots);
        if (typeof event.topics == 'string')
            event.topics = JSON.parse(event.topics);
        if (typeof event.tags == 'string')
            event.tags = JSON.parse(event.tags as any);
        if (typeof event.associations == 'string')
            event.associations = JSON.parse(event.associations as any);
        if (typeof event.kpiData == 'string')
            event.kpiData = JSON.parse(event.kpiData as any);
        if (typeof event.assignments == 'string')
            event.assignments = JSON.parse(event.assignments as any);
        if (typeof event.assignedTo == 'string')
            event.assignedTo = JSON.parse(event.assignedTo as any);
        if (typeof event.priority != 'number')
            event.priority = 0;
        if (!Array.isArray(event.tags))
            event.tags = [];
        // Ensure all Dates are truly deserialized
        if (typeof event.createdOn == "string")
            event.createdOn = new Date(event.createdOn);
        if (typeof event.endDate == "string")
            event.endDate = new Date(event.endDate);
        if (typeof event.startDate == "string")
            event.startDate = new Date(event.startDate);
        if (typeof event.updatedOn == "string")
            event.updatedOn = new Date(event.updatedOn);
        if (typeof event.completedDate == "string")
            event.completedDate = new Date(event.completedDate);

        event.icon = DTO.getDtoIcon(event);

        if (typeof event.parentId != 'number')
            event.parentId = null;

        const owner = getPrimaryAssociation(event, users);
        event['_owner'] = owner;
        event['_ownerId'] = owner?.id;
        event['_ownerName'] = owner?.name || `User ${owner?.id.toString()}`;
        event['_ownerPicture'] = getUserProfileImageElement(owner);

        event['_priorityLabel'] = PRIORITY_LABELS[event.priority] || "Unknown";

        event['_children'] = [];
        event['_value'] = true;
        event['_icon'] = event.tags?.find(t => t.name == "icon")?.value;
        event['_category'] = event.tags?.find(t => t.name == "category")?.value;

        const endTime = new Date(event.endDate).getTime();
        event['_overdue'] = endTime < Date.now() && event.progress < 100;


        // Fix bad states.
        event.status = event.status?.replace(" ", "") || "New";
        return event;
    }

    /**
     * Get all events beneath a given Event
     */
    public async getEventHierarchy(parent: Event_v1, ) {

        // Retrieve tasks for 'Applications', 'Users' et al.
        const url = `/api/eda/v1.0/data?name=event_hierarchy_get&select=true&eventId=${parent.id}&mode=full|kpi_notes&daystocompletion=14`;

        // Fetch the events
        let events = (await this.fetch.getOData<Event_v1[]>(url)).value;
        const eventMap = {};

        events.forEach(e => eventMap[e.id] = e);

        // Restore 'roots' property
        events.forEach(event => {
            event.roots = [];
            event['_children'] = [];

            // let current = eventMap[event.parentId];
            let current = event;
            const addNextRoot = () => {
                current = eventMap[current.parentId];
                if (!current) return;

                // Due to a Syncfusion bug, we must reconstitute the object.
                event.roots.push({ ...current });
                addNextRoot();
            };

            addNextRoot();
        });

        // Parse additional properties onto events and load them onto the event map.
        events.forEach(e => this.decorateEvent(e, this.tenant.users.value));

        events.forEach(event => {
            if (typeof event.parentId == 'number') {
                const parent = eventMap[event.parentId];
                parent['_children'].push(event);

                let roots = [...event.roots] as Event_v1[];

                if (roots.at(0).eventType != EventType.PROJECT)
                    roots.reverse();

                const parentIndex = roots.findIndex(c => c.id == parent.id);
                if (parentIndex != -1) {
                    roots.splice(0, parentIndex);
                }

                const ancestryPath = document.createElement('div');
                ancestryPath.classList.add("breadcrumb");

                event['_ancestryPath'] = ancestryPath;

                [...roots, event].forEach(event => {
                    const el = document.createElement('div');
                    el.classList.add("crumb");

                    const inner = document.createElement('div');
                    inner.classList.add("crumb_inner");

                    inner.textContent = event.name;
                    let timeout;
                    inner.onpointerenter = (e) => {
                        timeout = setTimeout(() => {
                            timeout = null;
                            openTooltip(this.dialog, EventPreviewComponent, {asset: event}, inner)
                        }, 300);
                    }
                    inner.onpointerleave = (e) => {
                        if (timeout) clearTimeout(timeout);
                        timeout = null;
                    }

                    el.append(inner);
                    ancestryPath.append(el);
                })
            }

            // Check if the event has an invalid status
            event['_invalidStatus'] = !this.project.isValidStatus(event.status);

            if (event['_invalidStatus']) {
                // Overwrite invalid status to a valid state
                event.status = this.project.eventStatusList[0].key;
            }
        });

        // Sort events by due date
        events.sort((a, b) => {
            // We need to order these things particularly
            if (a.eventType != b.eventType) {
                if (a.eventType as any == EventType.MILESTONE)
                    return 1;

                return (a.eventType as any) - (b.eventType as any);
            }

            // Projects will always compare their start time
            if (a.eventType as any == EventType.PROJECT) {
                // sort by start date
                return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
            }

            // If they both have a rank property, compare that
            if (typeof a.rank == "number" && typeof b.rank == "number")
                return (a.rank + (parseFloat('.' + a.id)) - (b.rank + (parseFloat('.' + a.id))));

            // Else, compare the start date.
            return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        });

        window['_events'] = events;
        window['_eventmap'] = eventMap;

        return {
            events, eventMap
        }
    }

    public getUserFriendlyName(type: string): string {
        const friendlyName: string[] = [];
        for (let i = 0; i < type.length; i++) {
            if (i == 0) {
                friendlyName.push(type[i].toUpperCase());
            }
            else if (type[i] == '-' || type[i] == '_') {
                friendlyName.push(' ');
                i++;
                friendlyName.push(type[i].toUpperCase());
            }
            else {
                friendlyName.push(type[i].toLowerCase());
            }
        }

        return friendlyName.join("");
    }

    public getReferenceType(dto: string): ReferenceType {
        const type = DTO.getMetaData(dto)?._type.toUpperCase().replace(/-/g, "_");
        return ReferenceType[type];
    }

    // TODO: How should we persist this?
    public getAncestryName(asset?: DTO): string {
        let idx = -1;
        let roots = [];
        if (asset) {
            if (asset['roots']) {
                const event = asset as Event_v1;
                roots = [...event.roots].reverse();
            }
            else {
                // TODO: Is this a todo?
                // const type = DTO.getMetaData(asset?.dto, false)?._type;
                // idx = this.currentTrail.findIndex(c => c.id === asset.id && c.type === type);
                // if (idx === -1) idx = this.currentTrail.length;
                // roots = this.currentTrail.slice(0, idx).filter(c => c.name);
            }
        }
        // else {
        //     roots = this.currentTrail.slice(0, this.currentTrail.length).filter(c => c.name);
        // }

        return roots.map(root => root.name).join(' ➔ ');
    }

    getAncestors(asset: DTO): string[] {
        const ancestors: string[] = [];

        if (!asset) return [];
        const metadata = DTO.getMetaData(asset?.dto, false);
        if (!metadata) return [];

        // const level = this.currentTrail.findIndex(c =>
        //     metadata._type === c.type &&
        //     asset.id === c.id);
        // for (let i = 0; i < level; i++) {
        //     ancestors.push(this.currentTrail[i].name || "");
        // }
        return ancestors;
    }

}
