import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent, ConfirmationDialogArguments } from '../components/dialog-confirmation/confirmation-dialog.component';

import { ConsoleLogger } from '@dotglitch/ngx-common';
import DOMPurify from 'dompurify';
import { InformationComponent } from 'src/app/components/dialog-information/information-dialog.component';
import { DialogWrapperAdvancedComponent } from 'src/app/components/dialog-wrapper-advanced/dialog-wrapper-advanced.component';
import { DialogWrapperBasicComponent } from 'src/app/components/dialog-wrapper-basic/dialog-wrapper-basic.component';
import { DTO } from 'src/dto/dto';

import { firstValueFrom } from 'rxjs';
import { LazyLoaderService } from 'src/app/components/lazy-loader/lazy-loader.service';
import { InitDraggableDialogs } from 'src/app/utils/dialog-drag';

const { log, warn, err } = ConsoleLogger("DialogService", "#607d8b");

export type DialogOptions = Partial<Omit<MatDialogConfig<any>, 'data'> & {
    useAdvanced: boolean,
    toggles: any[],
    isDark: boolean,
    isResizable: boolean,
    isModal: boolean,
    killOthers: boolean,
    hideSecurityControl: boolean,
    asset: DTO,
    icon: string,
    title: string,
    /**
     *
     */
    group: string,

    /**
     * List of properties to be provided to @Input() injectors
     */
    inputs: { [key: string]: any },
    /**
     * List of properties to be provided to @Input() injectors
     */
    outputs: { [key: string]: Function },
    /**
     * Context in which to execute callbacks from the `outputs` property via
     * @Output() event Emitters
     */
    parent: any
}>;

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    private readonly dialogs: MatDialogRef<unknown, any>[] = [];

    constructor(
        private readonly dialog: MatDialog,
        private readonly lazyLoader: LazyLoaderService,
        private readonly ngZone: NgZone
    ) {
        ngZone.runOutsideAngular(() => {
            // Init the draggable dialogs outside of the angular zone
            InitDraggableDialogs();
        })
    }

    /**
     * Open a dialog item.
     *
     * @param name Which dialog to open
     * @returns
     */
    open(name: string, opts: DialogOptions = {}): Promise<any> {
        return new Promise((resolve, reject) => {

            const registration = this.lazyLoader.resolveRegistrationEntry(name, opts.group || "default");
            if (!registration)
                return reject(new Error("Cannot open dialog for " + name + ". Could not find in registry."));

            const dialogClass = opts.useAdvanced ? DialogWrapperAdvancedComponent : DialogWrapperBasicComponent;

            let args = {};

            let title = "";
            if (opts.useAdvanced) {
                const titleParts = DOMPurify.sanitize(opts.title || "").split(' ➔ ');
                if (titleParts.length == 1) {
                    title = `<span class="only">${titleParts[0]}</span>`;
                }
                else if (titleParts.length == 2) {
                    title = `<span class="left">${titleParts[0]}</span><span> ➔ </span><span class="right">${titleParts[1]}</span>`;
                }
                else {
                    const firstPart  = titleParts.shift();
                    const lastPart   = titleParts.pop();
                    const middlePart = titleParts.map(p => '<span>' + p + '</span>').join('<span> ➔ </span>');
                    title = `<span class="first">${firstPart}</span><span> ➔ </span><span class="middle">${middlePart}</span><span> ➔ </span><span class="last">${lastPart}</span>`;
                }

                args = {
                    maxHeight: "90vh",
                    maxWidth: "90vw",
                    closeOnNavigation: true,
                    restoreFocus: true,
                    width:  registration['width'],
                    height: registration['height'],
                    ...opts,
                    data: {
                        id: name,
                        inputs:  opts.inputs || {},
                        outputs: opts.outputs || {},
                        parent:  opts.parent,
                        isModal: opts.isModal,
                        toggles: opts.toggles,
                        hideSecurityControl: opts.hideSecurityControl,
                        title: title,
                        tooltip: opts.title,
                        icon: opts.icon,
                        group: opts.group
                    },
                    group: opts.group,
                    panelClass: [
                        "dialog-" + name,
                        opts.isResizable ? 'dialog-resizable' : '',
                        opts.isModal ? 'dialog-draggable' : '',
                        opts.isDark ? 'dialog-dark' : 'dialog-light',
                        ...((Array.isArray(opts.panelClass) ? opts.panelClass : [opts.panelClass]) || [])
                    ],
                    backdropClass: opts.backdropClass ? [...opts.backdropClass, "no-backdrop"] : ["no-backdrop"],
                    disableClose: opts.isModal || opts.disableClose
                };
            }
            else {
                args = {
                    closeOnNavigation: true,
                    restoreFocus: true,
                    width:  registration['width'],
                    height: registration['height'],
                    ...opts,
                    data: {
                        id: name,
                        inputs: opts.inputs || {},
                        outputs: opts.outputs || {},
                        group: opts.group
                    },
                    panelClass: [
                        "dialog-" + name,
                        ...((Array.isArray(opts.panelClass) ? opts.panelClass : [opts.panelClass]) || [])
                    ]
                };
            }

            let dialog = this.dialog.open(dialogClass, args);

            dialog['idx'] = name;
            this.dialogs.push(dialog);

            firstValueFrom(dialog.afterClosed()).then(result => {
                log("Dialog closed " + name, result);
                resolve(result);
            });
        })
    }

    openBasic(name: string, opts: DialogOptions = {}): Promise<any> {
        return new Promise((resolve, reject) => {

            const registration = this.lazyLoader.resolveRegistrationEntry(name, opts.group || "default");
            if (!registration)
                return reject(new Error("Cannot open dialog for " + name + ". Could not find in registry."));


            const args = {
                closeOnNavigation: true,
                restoreFocus: true,
                width: registration['width'],
                height: registration['height'],
                ...opts,
                data: {
                    id: name,
                    inputs: opts.inputs || {},
                    outputs: opts.outputs || {},
                    group: opts.group
                },
                panelClass: [
                    "dialog-" + name,
                    ...((Array.isArray(opts.panelClass) ? opts.panelClass : [opts.panelClass]) || [])
                ]
            };

            let dialog = this.dialog.open(DialogWrapperBasicComponent, args);

            dialog['idx'] = name;
            this.dialogs.push(dialog);

            firstValueFrom(dialog.afterClosed()).then(result => {
                log("Dialog closed " + name, result);
                resolve(result);
            });
        });
    }

    // Close all dialogs matching the given name
    close(name: string) {
        const dialogs = this.dialogs.filter(d => d['idx'] == name);
        dialogs.forEach(dialog => dialog.close());
    }

    /**
     * Method to close _all_ dialogs.
     * Should be used sparingly.
     */
    clearDialog() {
        this.dialogs.forEach(dialog => dialog.close());
    }

    /**
     * Open a confirmation dialog. Will reject if a cancel occurs.
     * @param title title of the dialog
     * @param message main question that a user needs to confirm/deny
     * @returns
     */
    confirmAction(title: string, message: string): Promise<void> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(ConfirmationComponent, {
                maxHeight: "90vh",
                maxWidth: "90vw",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: {
                    title,
                    message
                } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => {
                result ? res() : rej(new Error("Action was rejected by user"));
            });
        });
    }

    critical(title: string, message: string): Promise<boolean> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(ConfirmationComponent, {
                maxHeight: "90vh",
                maxWidth: "90vw",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: { title, message, isCritical: true } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => {
                result ? res(true) : res(false);
            });
        });
    }

    confirm(title: string, message: string): Promise<boolean> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(ConfirmationComponent, {
                maxHeight: "90vh",
                maxWidth: "90vw",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: { title, message } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => {
                result ? res(true) : res(false);
            });
        });
    }

    requestSecret(title: string, message: string, args: { getInput? } = {}): Promise<string | false> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(ConfirmationComponent, {
                maxHeight: "90vh",
                maxWidth: "90vw",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: { title, message, ...args } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => {
                res(result);
            });
        });
    }

    inform(title: string, message: string): Promise<boolean> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(InformationComponent, {
                maxHeight: "90vh",
                maxWidth: "90vw",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: { title, message } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => {
                res(true);
            });
        });
    }

    treeDialog(title: string, message: string, isTree: boolean, asset: DTO | DTO[]): Promise<DTO | undefined> {
        return new Promise((res, rej) => {
            const dialog = this.dialog.open(ConfirmationComponent, {
                maxWidth: "400px",
                panelClass: ["dialog-confirmation"],
                closeOnNavigation: true,
                restoreFocus: true,
                data: { title, message, isTree, asset } as ConfirmationDialogArguments
            });

            firstValueFrom(dialog.afterClosed()).then(result => res(result));
        });
    }
}

