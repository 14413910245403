import { Injectable } from '@angular/core';
import { ConsoleLogger } from '@dotglitch/ngx-common';
import { BehaviorSubject, Observer, Subject, Subscription } from 'rxjs';
import { Account } from 'src/app/components/navmenu/menu.component';
import { AssetStatus } from 'src/app/services/asset.service';
import { TenantService } from 'src/app/services/tenant.service';
import { NotificationDetails, NotificationOn, NotificationWhen, UserPreferences, UserProfile } from 'src/app/types/user';
import { AccessLevel, Assignment, Attachment, AttachmentType, DTO, DTORef, ReferenceType, Tag } from 'src/dto/dto';
import { Persona, Personas } from 'src/dto/eom/persona';
import { Fetch } from './fetch.service';
import { ToasterService } from './toaster.service';

// Check every 4.5 minutes to make sure the session is alive.
const keepAliveInterval = 30 * 1000;

const { log, warn, err: error } = ConsoleLogger("UserService", "#ffc51a");

@Injectable({
    providedIn: 'root'
})
export class UserService extends Subject<UserProfile> {

    public termsAccepted = false;
    public tenants: {
        es_tenant_name: string;
        account_name: string;
    }[];
    public isPowerUser = false;
    public groups: string[] = [];
    public termAgreementDate: string = '';
    public userState: "guest" | "user";
    private _isDynatraceUser = false;
    public get isDynatraceUser() { return this._isDynatraceUser };

    /**
     * User role properties
     */
    public get hasElevateAdminRole() {           return this.groups.includes("ES-ROLE-ELEVATE_ADMIN") };
    public get hasElevateTenantOperatorRole() {  return this.groups.includes("ES-ROLE-ELEVATE_TENANT_OPERATOR") };
    public get hasElevateTenantCreatorRole() {   return this.groups.includes("ES-ROLE-ELEVATE_TENANT_OPERATOR") };
    public get hasElevateSecurityAdminRole() {   return this.groups.includes("ES-ROLE-ELEVATE_SECURITY_ADMIN") };
    public get hasElevateSecurityManagerRole() { return this.groups.includes("ES-ROLE-ELEVATE_SECURITY_MANAGER") };
    public get hasElevateSecurityViewerRole() {  return this.groups.includes("ES-ROLE-ELEVATE_SECURITY_VIEWER") };
    public get hasElevateManagerRole() {         return this.groups.includes("ES-ROLE-ELEVATE_MANAGER") };
    public get hasElevateViewerRole() {          return this.groups.includes("ES-ROLE-ELEVATE_VIEWER") };
    public get hasRegionalManagerRole() {        return this.groups.includes("ES-ROLE-REGIONAL_MANAGER") };

    private _hasTenantAdminRole = false;
    private _hasTenantSecurityAdminRole = false;
    private _hasTenantSecurityManagerRole = false;
    private _hasTenantSuccessManagerRole = false;
    private _hasTenantUserRole = false;
    private _hasTenantViewerRole = false;
    public get hasTenantAdminRole() {           return this._hasTenantAdminRole; }
    public get hasTenantSecurityAdminRole() {   return this._hasTenantSecurityAdminRole; }
    public get hasTenantSecurityManagerRole() { return this._hasTenantSecurityManagerRole; }
    public get hasTenantSuccessManagerRole() {  return this._hasTenantSuccessManagerRole; }
    public get hasTenantUserRole() {            return this._hasTenantUserRole; }
    public get hasTenantViewerRole() {          return this._hasTenantViewerRole; }
    private set hasTenantAdminRole(value) {            this._hasTenantAdminRole = value; }
    private set hasTenantSecurityAdminRole(value) {    this._hasTenantSecurityAdminRole = value; }
    private set hasTenantSecurityManagerRole(value) {  this._hasTenantSecurityManagerRole = value; }
    private set hasTenantSuccessManagerRole(value) {   this._hasTenantSuccessManagerRole = value; }
    private set hasTenantUserRole(value) {             this._hasTenantUserRole = value; }
    private set hasTenantViewerRole(value) {           this._hasTenantViewerRole = value; }

    /**
     * User_v1 properties
     */
    public email: string;
    public title: string; status: string;
    public lastActive: Date;
    public prefix: string;
    public suffix: string;
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public nickname: string;
    public photo: string;
    public personaCode: Persona;
    public persona: string;
    public personaDescription: string;
    public organization: DTORef;
    public preferences: Attachment[];
    public filters: Attachment[];
    public favorites: Attachment[];
    public layouts: Attachment[];
    public subscriptions: Attachment[];
    public id: number;
    public parentId: number;
    public idx: string;
    public name: string;
    public dto: string;
    public roots?: DTORef[];
    public refType?: number;
    public edtCode?: string;
    public icon: string;
    public description: string;
    public tags?: Tag[];
    public assignedTo?: Attachment[];
    public assignments?: Assignment[];
    public associations?: Attachment[];
    public privacy?: number;
    public access?: number;
    public createdOn?: Date;
    public createdBy?: string;
    public updatedOn?: Date;
    public updatedBy?: string;
    public lockedOn?: Date;
    public lockedBy?: string;
    public kpiData?: string;

    public preferences$ = new BehaviorSubject<UserPreferences>({});
    public notificationOn$ = new BehaviorSubject<NotificationOn>({});
    public notificationWhen$ = new BehaviorSubject<NotificationWhen>({});
    public notificationDetails$ = new BehaviorSubject<NotificationDetails>({});
    public favorites$ = new BehaviorSubject<Object>({});
    public sessionExpired = false;

    private preferencesAtt: Attachment;
    private userPreferences: UserPreferences;

    public value: UserProfile;
    constructor(
        private readonly fetch: Fetch,
        private readonly toaster: ToasterService,
        private readonly tenant: TenantService,
        private readonly toast: ToasterService
    ) {
        super();

        let _s;
        this.preferences$.subscribe(p => {
            if (!p?.general) return;

            this.isPowerUser = p.general?.menumode == "advanced";

            p.general.rootScaleLevel = p.general.rootScaleLevel || 100;
            if (p.general.rootScaleLevel != 100) {
                document.body.style['zoom'] = p.general.rootScaleLevel + '%';
            }
            else {
                document.body.style['zoom'] = "";
            }
        });

        window.userService = this;
    }

    override subscribe(
        observer?: Partial<Observer<UserProfile>> | ((value: UserProfile) => void)
    ): Subscription {
        if (this.value != null && typeof observer == 'function') {
            observer(this.value);
        }

        return super.subscribe(observer as any);
    }

    public login() {
        sessionStorage['$elevate-login-hash'] = location.hash;
        sessionStorage['$elevate-login-hash-date'] = new Date().getTime();
        let local = location.href.includes("localhost");

        window.location.href = `/api/dynatrace/login?ngsw-bypass=true&local=${local}`;
    }

    public logout() {
        window.location.href = "/api/logout";
    }

    // TODO: Move somewhere else?
    getPersonaLabel(personaCode?: string) {
        personaCode = personaCode || this.value.persona;
        return Personas[personaCode] || personaCode || 'no persona';
    }

    getCurrentUserPersonaLabel() {
        return this.value.persona;
    }

    _timer: any;
    async loadUserProfile(nextTenant?: string) {
        window.router?.rootPath$.next(null);

        if (this._timer) {
            clearTimeout(this._timer);
        }

        // Check if we are logged in.
        // @ts-ignore
        const tenant = nextTenant || window.location.href.match(/es-tenant=(?<tenant>[^&]+)/)?.groups?.tenant || localStorage["es-tenant"] || "UNKNOWN";
        // Default tenant only in the case where we're initially loading the page
        // and the observable hasn't had time to load a tenant (initial page load)
        const url = "/api/portal/user" + (
                nextTenant
                    ? `?es-tenant=${nextTenant}`
                    : (this.tenant.value
                        ? ""
                        : ("?ES-Tenant=" + tenant.toUpperCase())
                      )
            );

        const user = await this.fetch.get<UserProfile>(url, {}, true).catch(err => {
            error("Error resolving user account", err);
            sessionStorage['$elevate-login-success'] = false;

            window.dtrum?.reportCustomError("Login Error", err.message, JSON.stringify(err), true);
            return err;
        });

        // Some HTTP error
        if (!user || user.stack || user.error) {
            sessionStorage['$elevate-login-success'] = false;
            this.watchForBroadcastSession();

            return {
                type: "unknown",
                error: user as Error
            }
        }

        // Hide any Roles from the tenant list
        user.tenants = user.tenants?.filter(t => !t.es_tenant_name.startsWith("ES-ROLE")) ?? [];
        if (!user.tenants?.length) {
            this.toast.warn(
                "Login Failed",
                "You don't currently have access to an Elevate tenant. Please contact your Services representative."
            );
            return {
                type: "unknown",
                error: new Error("Login Failed")
            }
        }

        if (typeof user.id != 'number') {
            this.toast.warn(
                "Login Failed",
                "We couldn't find your profile in Elevate. Please contact your Services representative."
            );

            return {
                type: "unknown",
                error: new Error("Login Failed")
            }
        }

        this._isDynatraceUser = user.email.endsWith("@dynatrace.com");

        // Tell the world about the newly retrieved user. Also make it globally accessible in its own right.
        this.next(window['user'] = this.value = user);
        this.evaluateRoles();

        // A guest user has no preferences.
        if (user.userState == "guest") {
            return {
                type: "guest",
                user
            };
        }

        this.preferencesAtt = (user.preferences || [])[0] || {
            name: "User Preferences",
            description: `User preferences for ${user.email}`,
            dto: "Attachment_v1",
            source: user.id,
            sourceType: ReferenceType.USER,
            target: user.id,
            targetType: ReferenceType.USER,
            type: AttachmentType.PREFERENCES,
            data: {},
        } as any;

        // If a user doesn't have a perferences object, forcibly generate one
        if (!user.preferences) {
            this.savePreferences();
        }

        let parsedPrefs;
        try {
            parsedPrefs = (typeof this.preferencesAtt.data == "string"
                        ? JSON.parse(this.preferencesAtt.data || '{}')
                        : this.preferencesAtt.data) || {};
        }
        catch (ex) {
            console.warn("Preferences were malformed");
            parsedPrefs = {};
        }

        this.userPreferences = parsedPrefs.mySettings || parsedPrefs;
        this.preferences$.next(this.userPreferences);
        this.favorites$.next(this.value.favorites);

        // If a user has no tenants, we'll handle that independently
        if (!user.tenants || user.tenants.length == 0) {
            // TODO: Handle support for viewing landing page
            location.hash = "#/";

            window.dtrum?.reportCustomError("Login Error", "User has no tenants", "User logged in with no tenants assigned", true);

            return {
                type: "user",
                user,
                error: new Error("You do not have access to any tenants on Elevate.")
            }
        }

        // If the login completed within 5 minutes, we restore the user's last page from their session.
        if (
            sessionStorage['$elevate-login-hash'] &&
            sessionStorage['$elevate-login-hash-date'] &&
            new Date().getTime() - parseInt(sessionStorage['$elevate-login-hash-date']) < 5 * 60 * 1000
        ) {
            window.router.loadRouteString(sessionStorage['$elevate-login-hash']);
        }

        // We always clear their login session.
        // This is intended to prevent anomalies in the login/restore process.
        delete sessionStorage['$elevate-login-hash'];
        delete sessionStorage['$elevate-login-hash-date'];

        // Initialize the tenant
        this.tenant.initialize(user, tenant);

        if (!this.userCompletedSetup() && user.termsAccepted) {
            this.openPersonaSelection();
        }

        // Default apply the properties.
        this.watchSessionState();

        sessionStorage['$elevate-login-success'] = true;
        window.router.loadRootRoutePath();

        return {
            type: "user",
            user
        };
    }

    public switchAccounts(tenant: Account | string) {
        if (typeof tenant == 'object') tenant = tenant.es_tenant_name;

        this.loadUserProfile(tenant).then(p => {
            if (!this.checkTenantAccess(tenant)) {
                this.toaster.warn("Selected invalid tenant.", `Tenant ${tenant} is not present. Please validate your accessing the correct resource and you have sufficient rights to view it.`);
            }
            // window.router.rootPath$.next(null);
            window.router.loadRouteString('#/');
            window.router.loadRootRoutePath();
        })
    }

    public checkTenantAccess(tenant: Account | string) {
        // Warn if the tenant doesn't exist in the user's account list.
        return this.tenants.find(t =>
            t.es_tenant_name == (typeof tenant == 'string' ? tenant : tenant.es_tenant_name)
        )
    }


    private evaluateRoles() {
        Object.keys(this.value).forEach(key => {
            this[key] = this.value[key];
        });
    }

    async openPersonaSelection(): Promise<void> {
        // In report mode, we don't want to show it.
        if (location.href.includes("headless=true")) {
            return;
        }

        return await window.root.dialog.open("PersonaWizard", {
            inputs: { user: this.value },
            height: "760px",
            width: "1060px",
            hasBackdrop: false,
            isModal: true,
            hideSecurityControl: true,
            title: "ENTERPRISE OBSERVABILITY MANAGEMENT PLATFORM - ACE ONLINE GLOBAL SERVICES - powered by ESA",
            icon: "./assets/img/elevate/icon-dtlogo.png",
        });
    }

    public async savePreferences(isLastStepOfWizard = false) {
        if (isLastStepOfWizard) this.userPreferences.general.completedOn = new Date();

        this.preferencesAtt.data = JSON.stringify(this.userPreferences);
        this.preferencesAtt.access = AccessLevel.READWRITE;

        // FUTURE: For Dynatrace users, we also have to write to an endpoint that
        // specifies the 'regional' tenant. Problem: The tenant is a request header.
        // Separately (also here), a Dynatrace user should be 'enriched' with
        // specific information (e.g. general preferences like 'zoom') that is
        // retrieved from the regional database.
        const saveRequest = this.preferencesAtt.id
            ? DTO.attachmentAPI.update(this.preferencesAtt)
            : DTO.attachmentAPI.create(this.preferencesAtt);

        await saveRequest.then(att => {
            this.preferencesAtt.id = att.id;
            // NOTE: The database has been seen to not return the 'data' property.
            // Thus, we have a fallback to mitigate this, if it happens.
            this.userPreferences = JSON.parse(att.data || this.preferencesAtt.data);

            // Let the world know.
            this.preferences$.next(this.userPreferences);
        });
    }

    public updatePreferences(value: UserPreferences) {
        this.userPreferences = value;
        this.preferences$.next(this.userPreferences);
    }

    public updatePreferenceSection(section: string, value: any, isLastStepOfWizard = false) {
        if (!this.userPreferences[section])
            this.userPreferences[section] = {};

        Object.entries(value).forEach(([prop, value]) =>
            this.userPreferences[section][prop] = value
        );

        this.savePreferences(isLastStepOfWizard);
    }

    public getPreference(section: string, prop: string, defVal: any) {
        return this.userPreferences[section]?.[prop] || defVal;
    }

    public updatePreference(section: string, prop: string, value: any, isLastStepOfWizard = false) {
        if (!this.userPreferences[section])
            this.userPreferences[section] = {};

        this.userPreferences[section][prop] = value;

        this.savePreferences(isLastStepOfWizard);
    }

    public userCompletedSetup(): boolean {
        return !!this.userPreferences?.general?.completedOn;
    }

    private getReferenceType(dto: string): ReferenceType {
        const type = DTO.getMetaData(dto)?._type.toUpperCase().replace(/-/g, "_");
        return ReferenceType[type];
    }

    // FAVORTE, DEFAULT, SUBSCRIPTION MANAGEMENT
    getDefaultAssetId(type: string) {
        type = type?.toUpperCase().replace(/-/g, "_");
        if (!type) return -1;

        if (type == ReferenceType[ReferenceType.USER])
            return this.value.id; // You are you're own DEFAULT user.
        else
            return this.value.favorites?.find(fav =>
                fav.targetType == ReferenceType[type] &&
                fav.data == "DEFAULT"
            )?.target || -1;
    }

    public getFavoriteAsset(type: string) {
        const refType = ReferenceType[type.toUpperCase()];
        const fav = this.favorites?.find(f => f.targetType == refType && f.data === "DEFAULT");
        return fav ? {
            id: fav.target,
            parentId: fav.source,
            name: fav.description,
            dto: fav.targetType,
            description: fav.name,
            idx: "",
            icon: ""
        } : null;
    }

    public getUserSubscriptionTo(asset?: DTO): boolean {
        const type = DTO.getMetaData(asset?.dto)?._type?.toUpperCase().replace(/-/g, "_");
        if (!type) return null;
        return !!this.value.subscriptions?.find(fav =>
            fav.targetType == ReferenceType[type] &&
            fav.target == asset.id
        );
    }

    private getAssetFavStatus(asset?: DTO): Attachment | null {
        const type = DTO.getMetaData(asset?.dto)?._type.toUpperCase().replace(/-/g, "_");
        if (!type) return null;
        return this.value.favorites?.find(fav =>
            fav.targetType == ReferenceType[type] &&
            fav.target == asset.id
        );
    }

    private setAssetFavStatus(favorite: Attachment) {
        const currentUser = this.value;

        const fav = currentUser.favorites?.find(fav => fav.id == favorite.id);
        if (fav) {
            fav.data = favorite.data;
            log(`Updated favorite ${favorite.description}. Special status: ${favorite.data}`);
        }
        else {
            currentUser.favorites = currentUser.favorites || [];
            currentUser.favorites.push(favorite);
            log(`Added favorite ${favorite.description}. Special status: ${favorite.data}`);
        }

        // Update user favorites
        // this.next({ ...this.value, favorites: currentUser.favorites });
        this.favorites$.next(currentUser.favorites);
    }

    private remAssetFavStatus(id: number) {
        const currentUser = this.value;

        const idx = currentUser.favorites?.findIndex(fav => fav.id == id);
        if (idx > -1) {
            const favorite = currentUser.favorites.splice(idx, 1)[0];
            log(`Removed favorite ${favorite.description}.`);
        }

        // Update user favorites
        // this.next({ ...this.value, favorites: currentUser.favorites });
        this.favorites$.next(currentUser.favorites);
    }

    getFavStatus(asset: DTO): AssetStatus {
        const favorite = this.getAssetFavStatus(asset);

        return {
            isDefault: favorite?.data == "DEFAULT",
            isFavorite: favorite && favorite.data != "DEFAULT",
            isSubscribed: this.getUserSubscriptionTo(asset)
        }
    }

    async toggleFavorite(asset: DTO): Promise<AssetStatus> {
        const favorite = this.getAssetFavStatus(asset);
        const type = DTO.getMetaData(asset.dto)._type;
        const user = this.value;

        try {
            if (favorite?.data == "DEFAULT") {
                // If this asset is a default asset, then it loses it status
                // and becomes a normal favorite.
                favorite.data = "";
                const attachment = await DTO.attachmentAPI.update(favorite);
                this.setAssetFavStatus(attachment);
            }
            else if (favorite) {
                // If this was a favorite, then we delete it.
                await DTO.attachmentAPI.delete(favorite.id);
                this.remAssetFavStatus(favorite.id);
            }
            else {
                // Else we make it a favorite.
                const attachment = await DTO.attachmentAPI.create({
                    id: -1,
                    idx: "",
                    dto: "",
                    icon: "",
                    access: 1,
                    name: `Favorite ${type} for ${user.name}`,
                    description: asset.name,
                    parentId: null,
                    source: user.id,
                    sourceType: ReferenceType.USER,
                    target: asset.id,
                    targetType: this.getReferenceType(asset.dto),
                    type: AttachmentType.FAVORITE,
                    data: null
                });
                this.setAssetFavStatus(attachment);
            }
        }
        catch (ex) {
            console.error(ex.message || ex);
        }
        return this.getFavStatus(asset);
    }

    async toggleDefault(asset: DTO): Promise<AssetStatus> {
        const favorite = this.getAssetFavStatus(asset);
        const type = DTO.getMetaData(asset.dto)._type;
        const user = this.value;

        try {
            if (favorite?.data == "DEFAULT") {
                // If this asset is a default asset, then it loses it status
                // and becomes a normal favorite.
                favorite.data = "";
                const attachment = await DTO.attachmentAPI.update(favorite);
                this.setAssetFavStatus(attachment);
            }
            else if (favorite) {
                // If this asset was a favorite already, then we elevate its status to 'default'.
                favorite.data = "DEFAULT";
                const attachment = await DTO.attachmentAPI.update(favorite);
                this.setAssetFavStatus(attachment);
            }
            else {
                // This wasn't a favorite or default yet.
                const attachment = await DTO.attachmentAPI.create({
                    id: -1,
                    idx: "",
                    dto: "",
                    icon: "",
                    access: 1,
                    name: `Favorite ${type} for ${user.name}`,
                    description: asset.name,
                    parentId: null,
                    source: user.id,
                    sourceType: ReferenceType.USER,
                    target: asset.id,
                    targetType: this.getReferenceType(asset.dto),
                    type: AttachmentType.FAVORITE,
                    data: "DEFAULT"
                })
                this.setAssetFavStatus(attachment);
            }
        }
        catch (ex) {
            console.error(ex.message || ex);
        }
        return this.getFavStatus(asset);
    }

    async toggleSubscribe(asset: DTO) {
        // TODO: Not yet implemented.
    }

    private async watchSessionState() {
        if (this.sessionExpired) return;

        try {
            let { status } = await this.fetch.get<any>(`/api/dynatrace/session`);

            // When a session expires, we change the rendering to the login page.
            // This approach makes multiple tabs log back in without requiring additional reloads.
            if (status != "active") {
                this.watchForBroadcastSession();
                throw Error("Session has expired.");
            }
        }
        catch (ex) {

            // If we're logged in, message the user.
            if (this.value) {
                this.toaster.warn("Your session has expired.", "Please log-in again to continue.");
                this.sessionExpired = true;
                // Stop checking if the session is alive.
                return;
            }
        }

        // Recursively invoke in a setTimeout. This means we will trigger our wait only after our transaction completes.
        // This also prevents some rare scenarios where thousands of requests would be queued and fired off when a tab is restored.
        this._timer = setTimeout(() => {
            this.watchSessionState();
        }, keepAliveInterval);
    }

    /**
     * Watch sessionStorage for a successful login in another tab.
     * If we detect it, we will re-attempt to load the user profile.
     */
    private watchForBroadcastSession() {
        this._timer = setTimeout(() => {
            if (sessionStorage['$elevate-login-success'] == 'true') {
                // Tell the root to reinit
                window.root.ngOnInit();
            }
            else {
                this.watchForBroadcastSession();
            }
        }, 250);
    }
}
