<div class="header">
    <div class="title">
        <mat-icon>category</mat-icon>
        Contribution Notes
        <mat-icon style="margin-left: 4px;">help</mat-icon>
    </div>
</div>

<div class="notes">
    <div class="add-btn" mat-flat-button (click)="addNote()">
        <mat-icon>add</mat-icon>
        Add Topic
    </div>

    @for (note of notes; track note) {
        <div class="note">
            <div class="topic">{{note.name}}</div>
            <div class="author">{{note.data?.[0]?.['owner']}}</div>
            <div class="date">{{note.data?.[0]?.['time'] | date}}</div>
            <div class="content"
                [innerHTML]="note.description.slice(0, 128) | markdown"
            ></div>

            @if (note.createdBy == user.email || user.hasElevateManagerRole) {
                <div class="controls">
                    <mat-icon matTooltip="Edit" (click)="editNote(note)">edit</mat-icon>
                    <mat-icon matTooltip="Copy reference ID to clipboard" (click)="copyNote(note)">copy</mat-icon>
                    <mat-icon matTooltip="View History" (click)="viewHistory(note)">history</mat-icon>
                    <mat-icon matTooltip="Delete" (click)="deleteNote(note)">delete</mat-icon>
                </div>
            }
        </div>
    }
</div>
