<!-- We use this outer component to hide the DOM until we have something relevant to show -->
<!-- This prevents content flashing and other visual anomalies. -->
@if (isInitDone) {

    @if (showTour) {
        @defer (when showTour) {
            <app-site-tour-overlay (done)="showTour = false" />
        }
    }

    @if (user.value && user.userState != 'guest') {

        @if (headless) {
            @if (rootNavigation?.firstSegment) {
                <ngx-lazy-loader
                    #loader
                    [component]="rootNavigation?.firstSegment"
                    [inputs]="rootNavigation?.properties"
                    (componentLoaded)="onComponentLoaded($event)"
                />
            }
        }
        @else if (!user.termsAccepted) {
            <!-- Users who haven't accepted our Terms and conditions will see the terms dialog and must accept. -->
            <div class="terms-and-conditions">
                <ngx-lazy-loader component="Terms"/>
            </div>
        }
        @else {
            <!-- When users have accepted our terms, we show the main UI. -->

            <!-- Main area with the nav and contents -->
            <div class="main">
                <!-- Navigation menu on the left. -->
                <div style="z-index: 999" class="print-hidden">
                    <app-navmenu #menu></app-navmenu>

                    @if (menu.collapsed) {
                        <div class="account-thing">
                            Account: {{tenant.value.account_name}}
                        </div>
                    }

                </div>

                <div class="content">

                    @if (selectedPage?.longname?.length > 2) {
                        <div class="page-toolbar">
                            <div style="display:flex; flex-direction: column; justify-content: center;">
                                <h3 class="title">{{selectedPage?.longname}}</h3>
                                @if (selectedPage?.description) {
                                    <div class="subtitle">{{selectedPage?.description}}</div>
                                }
                            </div>

                            <div>
                            @if (selectedPage?.docs) {
                                <a class="icon-btn" (click)="dialog.open('documentation', { inputs: selectedPage })">
                                    <img src="assets/img/ui-controls/book.svg" style="width: 26px" alt="Documentation"/>
                                </a>
                            }

                            @if(selectedPage?.video && user.email.endsWith('@dynatrace.com')) {
                                <!-- Watch video. -->
                                <a class="icon-btn" target="_blank" [href]="selectedPage?.video" style="margin-left: 11px">
                                    <img src="assets/img/ui-controls/film.svg" style="width: 26px" alt="Video Documentation"/>
                                </a>
                            }
                            </div>
                        </div>
                    }

                    <div class="page-content" [class]="selectedPage?.state">
                        <div class="content-stage" [style.background]="selectedPage?.background">
                            @if (rootNavigation?.firstSegment) {
                                <ngx-lazy-loader
                                    #loader
                                    [component]="rootNavigation?.firstSegment"
                                    [inputs]="rootNavigation?.properties"
                                    (componentLoaded)="onComponentLoaded($event)"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    }
    @else {
        <!-- If the user is not logged in, they see the login screen. -->
        <!-- Also show the landing pages when a user is still enrolling -->
        <ngx-lazy-loader component="login" />
    }
}

<app-toast></app-toast>
