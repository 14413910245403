import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SplitterAllModule } from '@syncfusion/ej2-angular-layouts';
import { ContributionNotesListComponent } from 'src/app/components/contribution-notes-list/contribution-notes-list.component';
import { LazyLoaderComponent } from 'src/app/components/lazy-loader/lazy-loader.component';
import { AssetDocumentationComponent } from 'src/app/pages/general/asset-navigator/components/asset-documentation/asset-documentation.component';
import { NotesComponent } from 'src/app/pages/general/asset-navigator/components/notes/notes.component';
import { PropertySheetComponent } from 'src/app/pages/general/asset-navigator/components/property-sheet/property-sheet.component';
import { SecurityControlComponent } from 'src/app/pages/general/asset-navigator/components/security-control/security-control.component';
import { EDTCodePipe } from 'src/app/utils/pipes/edtcode.pipe';
import { DTO } from 'src/dto/dto';


@Component({
    selector: "es-dialog-wrapper",
    templateUrl: './dialog-wrapper-advanced.component.html',
    styleUrls: ['./dialog-wrapper-advanced.component.scss'],
    imports: [
        SecurityControlComponent,
        LazyLoaderComponent,
        AssetDocumentationComponent,
        NotesComponent,
        SplitterAllModule,
        MatButtonModule,
        MatIconModule,
        EDTCodePipe,
        PropertySheetComponent,
        ContributionNotesListComponent
    ],
    standalone: true
})
export class DialogWrapperAdvancedComponent {
    public panelOpen = false;
    public panelMode = null;
    public asset: DTO = null;
    public toggles: any[] = [];

    public componentInstance: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public args,
        public dialog: DialogRef,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon('doc', sanitizer.bypassSecurityTrustResourceUrl("./assets/img/book.svg"));
        iconRegistry.addSvgIcon('tag', sanitizer.bypassSecurityTrustResourceUrl("./assets/img/controls/tag.svg"));
        iconRegistry.addSvgIcon('notes', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/notes2.svg'));

        this.asset = this.args.inputs.asset;
        this.toggles = this.args.toggles || [];
        this.toggles.forEach(toggle => {
            if (toggle.selected) {
                this.panelMode = toggle.id;
                this.panelOpen = true;
            }
        });
    }

    private isOverflowing = false;
    overflowTitle(titleBar) {
        // Once true, stay true. Otherwise, flickering...
        // TODO: Reset when resizing. See https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
        this.isOverflowing = this.isOverflowing || titleBar.offsetWidth < titleBar.scrollWidth;
        return this.isOverflowing;
    }

    onPanelOpenChange(mode: string) {
        this.panelOpen = mode != this.panelMode || !this.panelOpen;
        this.panelMode = mode;
    }

    onClose() {
        // Trigger the loaded 'onClose' event instead.
        // This lets the underlying container pass the
        // proper data in it's close event.
        if (typeof this.componentInstance?.onClose == "function") {
            this.componentInstance.onClose();
        }
        else {
            this.dialog.close();
        }
    }
}
