import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ThemeService } from '@dotglitch/ngx-common';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

// What key does the theme get stored under on the 'localstorage' object.
const THEME_KEY = "es-theme";

// TODO: validate interface

@Injectable({
    providedIn: 'root'
})
export class ThemeLoaderService {
    readonly themes = [
        "light",
        "dark"
    ];

    readonly defaultTheme = "light";

    private isSyncfusionLoaded = false;

    // @LocalStorage("theme")
    private _theme;
    get theme() {
        return this._theme;
    }
    set theme(theme: string) {
        if (!theme) return;

        if (!this.themes.includes(theme))
            throw new Error("Cannot set theme " + theme + "! It is not present in the theme registry.");

        if (!this._theme) {
            this._theme = localStorage[THEME_KEY] || this.defaultTheme;
            this.theme$.next(this._theme);
        }

        this._theme = localStorage[THEME_KEY] = theme;

        // this.loadStylesheet("theme", link);
        this.themes.forEach(t => this.document.body.classList.remove(t));
        this.document.body.classList.add(theme);

        this.theme$.next(theme);

        if (this.isSyncfusionLoaded)
            this.loadSyncfusionTheme();
    }

    public theme$ = new BehaviorSubject<string>(null);

    constructor(
        private readonly themeService: ThemeService,
        private readonly user: UserService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.theme = localStorage[THEME_KEY] || this.defaultTheme;

        user.preferences$.subscribe(preferences => {
            // perferences should probably not be an array
            const uTheme = preferences.general?.theme;
            this.theme = uTheme || this.theme;
        });

        this.theme$.subscribe(t => {
            themeService.setTheme(t as any);
        });
    }

    public loadSyncfusionTheme() {
        this.isSyncfusionLoaded = true;

        let link = "/syncfusion-light.css";
        if (this._theme.includes('dark'))
            link = "/syncfusion-dark.css";
        this.loadStylesheet("theme-heavy", link);
    }


    private loadStylesheet(id: string, href: string) {
        let themeLink = this.document.getElementById(id) as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = href;
        }
        else {
            const style = this.document.createElement('link');
            style.id = id;
            style.rel = 'stylesheet';
            style.href = href;

            const head = this.document.getElementsByTagName('head')[0];

            head.appendChild(style);
        }
    }

    public setLightTheme() {
        this.theme = "light";
        this.user.updatePreference("general", "theme", "light");
    }
    public setDarkTheme() {
        this.theme = "dark";
        this.user.updatePreference("general", "theme", "dark");
    }
}
