import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import DOMPurify from 'dompurify';
import { AudienceComponent } from 'src/app/pages/@dialogs/event-properties/audience/audience.component';
import { ElevateTipComponent, TooltipEntries } from 'src/app/pages/general/elevate-tooltip/elevate-tooltip.component';
import { getPersonaLabel, getUserProfileImageElement } from 'src/app/pages/pmo/helper';
import { DialogOptions } from 'src/app/services/dialog.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { UserProfile } from 'src/app/types/user';
import { FormatDate } from 'src/app/utils/pipes/datetime.pipe';
import { DTO, Note, NotesAPI, PartialNote } from 'src/dto/dto';
import { tooltips } from './tooltips';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    providers: [
        HttpClientModule
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        ElevateTipComponent
    ]
})
export class NotesComponent implements AfterViewInit, OnDestroy {
    readonly tooltips: TooltipEntries = tooltips;

    @ViewChild("notesControl") notesContainer: ElementRef;

    public title = "Discussion";
    public subtitle = "";

    private notesControl: NotesControl;

    private _asset: DTO;

    @Input()
    public set asset(asset: DTO) {
        this._asset = asset;
        this.title = `${DTO.getUserFriendlyName(asset.dto)} Discussion`;
        this.initialize();
    }
    public get asset() {
        return this._asset;
    }

    constructor(
        public readonly theme: ThemeLoaderService,
        public dialog: MatDialog
    ) {
    }

    ngAfterViewInit() { this.initialize();   }
    ngOnDestroy() { this.notesControl?.dispose(); }

    onShowAudience() {
        const options : DialogOptions = { isResizable: true, isModal: true };

        this.dialog.open(AudienceComponent, {
            height: "60%",
            width: "60%",
            panelClass: [
                "dialog-audience", // Where does this come from?
                options.isResizable ? 'dialog-resizable' : '',
                options.isModal ? 'dialog-draggable' : '',
                options.isDark ? 'dialog-dark' : 'dialog-light',
                ...(Array.isArray(options.panelClass) ? options.panelClass : [options.panelClass])
            ],
            ...options,
            closeOnNavigation: true,
            restoreFocus: true,
            data: this.asset
        });
    }

    private initialize() {
        if (!this.asset || !this.notesContainer) return;

        let isFirstUpdate = true;

        this.notesControl?.dispose();
        this.notesControl = new NotesControl(
            window.tenant,
            this.notesContainer.nativeElement,
            DTO.getEndpointAPI(this.asset.dto).notesAPI,
            this.asset,
            window.root.user.value,
            notes => {
                if (notes?.length > 0) {
                    const lastUpdate    = notes[notes.length - 1];
                    const lastUpdatedBy = lastUpdate?.name || lastUpdate?.authorName || lastUpdate?.authorEmail || "unknown user";
                    const lastUpdatedOn = lastUpdate?.enteredDate;

                    this.subtitle = `${notes.length} entries. Last contribution by ${lastUpdatedBy} on ${FormatDate(lastUpdatedOn)}.`;
                }
                else {
                    this.subtitle = `Be the first to comment.`;
                }
                if (isFirstUpdate) {
                    isFirstUpdate = false;
                    this.notesControl.scrollToEnd();
                }
            }
        );
    }
}

class NotesControl {
    notesArea: HTMLElement;
    notesAPI: NotesAPI;
    access: number;
    assetId: number;
    userEmail: string;
    notesList: HTMLDivElement;
    noteEditor: HTMLDivElement;
    textArea: any;
    currentNote: any;
    notes: Note[];
    onChange: Function;

    autoRefresh = null;
    refreshInterval = 30; // Seconds.

    constructor(
        private readonly tenant: TenantService,
        notesArea: HTMLElement,
        notesAPI: NotesAPI,
        asset: DTO,
        user: UserProfile,
        onChange?: Function
    ) {
        if (!notesArea || !asset) return;

        this.notesArea = notesArea;
        this.notesAPI  = notesAPI;
        this.access    = asset.access;
        this.assetId   = asset.id;
        this.userEmail = user?.email.toLowerCase();
        this.onChange  = (notes) => {
            this.startAutoRefresh();
            if (onChange) onChange(notes);
        };

        this.notesArea.innerHTML = "";
        //this.notesArea.classList.add("collapsed");

        this.notesList = document.createElement("div");
        this.notesList.className = "Scrollbar NotesList";
        this.notesList.style.overflowY = "auto";
        this.notesList.innerHTML = "<p style='padding-left: 18px'>Loading...</p>";

        this.notesArea.appendChild(this.notesList);

        this.noteEditor = document.createElement("div");
        this.noteEditor.id = "noteEditor";
        this.noteEditor.className = "Scrollbar NoteEditor";
        this.noteEditor.style.display = "flex";
        this.noteEditor.dataset['notetype'] = "0";
        this.noteEditor.dataset['mode'] = "New";
        this.noteEditor.dataset['note'] = null;
        this.noteEditor.dataset['parent'] = null;

        const expandCollapseButton = document.createElement("div");
        expandCollapseButton.className = "expand-collapse";
        expandCollapseButton.addEventListener('click', event => this.notesArea.classList.toggle('collapsed'));
        this.noteEditor.appendChild(expandCollapseButton);

        const visibilityButton = document.createElement("span");
        visibilityButton.className = "visibility-control";
        if (!this.userEmail.endsWith("@dynatrace.com")) {
            visibilityButton.style.visibility = "hidden";
        }
        visibilityButton.title = "Visibility";
        visibilityButton.innerHTML = "<span>Dynatrace Only</span>";
        visibilityButton.addEventListener("mousedown", event => this.toggleVisibility({ currentTarget: this.textArea }), true);

        this.noteEditor.appendChild(visibilityButton);

        this.textArea = document.createElement("textarea");
        this.textArea.className = "Scrollbar NewNote";
        this.textArea.placeholder = "Add a note";
        this.textArea.value = "";
        this.textArea.addEventListener("keypress", event => {
            if (event.keyCode == 13 && !event.shiftKey) {
                event.preventDefault(); // Prevents 'Enter' from creating a new line.
                this.finishEdit(event);
                this.textArea.value = "";
            }
            if (event.keyCode == 27) {
                event.preventDefault(); // Prevents 'Esc' from closing the dialog.
                this.cancelEdit(event);
            }
            return true;
        });

        this.noteEditor.appendChild(this.textArea);

        const controlsElem = document.createElement("span");
        controlsElem.className = "Controls";
        controlsElem.style.visibility = "visible";

        const cancelButton = document.createElement("span");
        cancelButton.className = "icon icon-close";
        cancelButton.title = "Cancel (Esc)";
        cancelButton.innerHTML = "&nbsp;";
        cancelButton.addEventListener("mousedown", event => this.cancelEdit({ currentTarget: this.textArea }), true);

        controlsElem.appendChild(cancelButton);

        const acceptButton = document.createElement("span");
        acceptButton.className = "icon icon-checkmark";
        acceptButton.title = "Accept (Enter)";
        acceptButton.innerHTML = "&nbsp;";
        acceptButton.addEventListener("mousedown", event => this.finishEdit({ currentTarget: this.textArea }), true);

        controlsElem.appendChild(acceptButton);
        this.noteEditor.appendChild(controlsElem);
        this.notesArea.appendChild(this.noteEditor);

        this.noteEditor.style.display = this.access > 2 ? "" : "none !important";
        this.startAutoRefresh(true);
    }

    public scrollToEnd() {
        this.notesList.scrollTo(0, this.notesList.scrollHeight);
    }

    // CAUTION: Keep current scroll position. If the height
    // has changed, we can show something at the bottom to
    // inform the user there are new notes.
    // DONT refresh while the user is replying to something.
    stopAutoRefresh(forever = false) {
        clearInterval(this.autoRefresh);
        this.autoRefresh = forever ? null : -1;
    }
    startAutoRefresh(immediately = false) {
        // If we we paused, retrieve data now and set up a
        // new autoRefresh. Else, do nothing.
        if (this.autoRefresh == -1 || immediately) {
            this.renderAllNotes();
            this.autoRefresh = setInterval(() =>
                this.renderAllNotes(),
                this.refreshInterval * 1000);
        }
    }

    dispose() {
        this.stopAutoRefresh(true); // Forever.
    }

    private hasChanges(notes: Note[]): boolean {
        if (!this.notes?.length ||
             this.notes?.length != notes.length) return true;

        // There are changes if it's NOT true that we can find every note
        // in the previous dataset with the same enteredDate and description.
        return !notes.every(note => {
            const  orgNote = this.notes.find(orgNote => orgNote.id == note.id);
            return orgNote &&
                   orgNote.enteredDate == note.enteredDate &&
                   orgNote.description == note.description;
        });
    }

    async renderAllNotes() {
        if (!this.assetId) return;

        // Preserve current scroll position.
        const scrollTop = this.notesList.scrollTop;

        try {
            let notes = await this.notesAPI.select(this.assetId);
            notes = notes
                .map(note => {
                    note.enteredDate = new Date(note.enteredDate);
                    note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";
                    note.hasReplies  = !!notes.find(n => note.parentId == n.id);
                    return note;
                })
                .sort((a, b) =>
                    a.enteredDate.getTime() - b.enteredDate.getTime()
                );

            if (!this.hasChanges(notes)) return;
            // console.log("Found new notes!");
            this.notes = [...notes];

            // Keep appending notes, ordered by date. If there is a parent,
            // find the parent and append it there. If it doesn't exist yet,
            // wait until it does exist. Once the list stops shrinking,
            // append the rest to the end.
            this.notesList.innerHTML = "";

            let length = 0;
            while (length !== notes.length) {
                // Check if we're still shrinking (i.e. rendering notes).
                // We're really defensive here because we ordered by time,
                // so parents will always exist when a child (reply) is
                // rendered. Theoretically, at least...
                length = notes.length;

                for (let i = 0; i < notes.length; /* NOP */) {
                    const parentNote: HTMLElement = notes[i].parentId
                        ? this.notesList.querySelector("div[data-noteid='" + notes[i].parentId + "']")
                        : this.notesList;

                    if (parentNote) {
                        parentNote.appendChild(this.renderNote(notes[i]));
                        parentNote.dataset['hasreplies'] = 'true';
                        notes.splice(i, 1);
                    }
                    else {
                        // This should not happen, given that parents will
                        // always be rendered before children (replies)
                        // because of the time order.
                        i++;
                    }
                }
            }
            // Add the remaining ones, to be sure. This can only happen if notes that
            // had replies were deleted (but not the replies themselves), which we're
            // preventing in the UI.
            notes.forEach(note => this.notesList.appendChild(this.renderNote(note)));

            // The root should not have this property set to true. Only individual notes
            // can have replies. The algorithm above is indiscriminate in that respect.
            this.notesList.dataset['hasreplies'] = 'false';
        }
        catch(err) {
            this.notes = [];
            this.notesList.innerHTML = "<span class='error'>Could not load notes: " + DOMPurify.sanitize(err.message) + "</span>";

            console.error(err);
        }
        finally {
            this.notesList.scrollTop = scrollTop;
            // console.log("Reporting " + this.notes.length + " notes...");
            this.onChange(this.notes);
        };
    }

    renderNote(note: Note) {
        const noteRow = document.createElement("div");
        noteRow.className = "NoteRow";
        noteRow.dataset['noteid'] = '' + note.id;
        noteRow.dataset['notetype'] = '' + note.privacy;
        if (note.parentId) {
            noteRow.dataset['parentid'] = '' + note.parentId;
        }

        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0);

        const email = note.authorEmail?.trim().toLowerCase();
        const user  = this.tenant.users.value.find(u => u.email?.trim().toLowerCase() == email);
        const canReply  = this.access >= 2;
        const canManage = this.access >= 16 || (
            note.authorEmail == this.userEmail &&
            note.enteredDate.getTime() > startOfDay.getTime() &&
            !note.hasReplies
        );


        noteRow.innerHTML =
            '<span class="Author">' +
                // Name contents are added later (They're real DOM elements)
                '<span class="Name"></span> ' +
                '<span class="Date">' + FormatDate(note.enteredDate) + '</span>' +
                '<span class="Visibility">' +
            (note.privacy == 1
                ? 'Dynatrace Only'
                : note.authorEmail?.split('@')[1].split('.').slice(0, -1).join('.') || "Unknown org"
            ) + ' - ' + (user ? DOMPurify.sanitize(user.title + ' (' + getPersonaLabel(user.persona) + ')') : '(inactive)') +
                '</span>' +
            '</span>' +
            '<span class="Note">' +
                '<span class="Controls">' +
                    '<button type="button" class="e-control e-btn e-lib e-flat e-dialog-edit e-primary">Delete</button>' +
                    '<span class="icon icon-reply' + (canReply  ? '' : ' Hidden') + '" title="Reply">&nbsp;</span>' +
                    '<span class="icon icon-edit'  + (canManage ? '' : ' Hidden') + '" title="Edit">&nbsp;</span>' +
                    '<span class="icon icon-trash' + (canManage ? '' : ' Hidden') + '" title="Delete">&nbsp;</span>' +
                '</span>' +
                '<span class="Bubble">' + DOMPurify.sanitize(note.description) + '</span>' +
            '</span>';

        // Add the profile image (it _must_ be a dom element)
        const nameContainer = noteRow.querySelector(".Name");
        nameContainer.append(getUserProfileImageElement(user, ['small'], '48x48'));
        nameContainer.append(note.name || user?.name || email);

        const confirmButton = noteRow.querySelector("button");
        const replyButton = confirmButton.nextElementSibling;
        const updateButton = replyButton.nextElementSibling;
        const deleteButton = updateButton.nextElementSibling;

        confirmButton.addEventListener("click", this.deleteNote.bind(this));
        confirmButton.addEventListener("blur", this.cancelDelete.bind(this));
        noteRow.addEventListener("mouseleave ", this.cancelDelete.bind(this));
        deleteButton.addEventListener("click", this.initDelete.bind(this));
        updateButton.addEventListener("click", this.initUpdate.bind(this));
        replyButton.addEventListener("click", this.initReply.bind(this));

        return noteRow;
    }

    initUpdate(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const noteId = this.currentNote.dataset.noteid;
        const noteType = this.currentNote.dataset.notetype;
        const parentId = this.currentNote.dataset.parentid;
        const noteElem = this.currentNote.querySelector(".Note");
        const bubbleElem = noteElem.querySelector(".Bubble");
        const controlElem = noteElem.querySelector(".Controls");

        bubbleElem.style.display = "none";
        controlElem.style.display = "none";

        noteElem.appendChild(this.noteEditor);
        this.notesArea.classList.remove('collapsed');
        this.notesList.style.height = "100%";

        this.textArea.value = bubbleElem.innerHTML;
        this.textArea.parentNode.dataset.mode = "Edit";
        this.textArea.parentNode.dataset.note = noteId;
        this.textArea.parentNode.dataset.notetype = noteType;
        this.textArea.parentNode.dataset.parent = parentId;
        this.textArea.focus();
        // So that we can apply a style that hides the date.
        // this.textArea.parentNode.parentNode.parentNode.dataset.mode = "Edit";
    }

    initReply(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const noteId = this.currentNote.dataset.noteid;
        const noteType = this.currentNote.dataset.notetype;
        const noteElem = this.currentNote.querySelector(".Note");
        noteElem.classList.add("Selected");
        noteElem.classList.add("Replying");

        this.currentNote.appendChild(this.noteEditor);
        this.notesArea.classList.remove('collapsed');
        this.notesList.style.height = "100%";

        this.textArea.value = "";
        this.textArea.parentNode.dataset.mode = "Reply";
        this.textArea.parentNode.dataset.noteid = noteId;
        this.textArea.parentNode.dataset.notetype = noteType;
        this.textArea.focus();
    }

    initDelete(event) {
        this.stopAutoRefresh(); // Will resume when we're done.

        this.currentNote = event.currentTarget.parentNode.parentNode.parentNode;

        const confirmButton = this.currentNote.querySelector("button");
        confirmButton.classList.add("Visible");
        confirmButton.focus();
    }

    finishEdit(event) {
        event = event || { currentTarget: this.textArea };

        if (this.currentNote) {
            const noteElem = this.currentNote.querySelector(".Note");
            noteElem.classList.remove("Selected");
            noteElem.classList.remove("Replying");
        }
        const value = event.currentTarget.value.trim();
        if (value) {
            const noteType = event.currentTarget.parentNode.dataset.notetype;
            const mode = event.currentTarget.parentNode.dataset.mode;
            switch (mode) {
                case "New":   this.addNewNote( value, noteType); break;
                case "Edit":  this.updateNote( value, noteType); break;
                case "Reply": this.replyToNote(value, noteType); break;
            }
        }
        else {
            this.startAutoRefresh();
        }
    }

    cancelEdit(event) {
        if (this.currentNote) {
            //  const noteRows = [...this.notesArea.querySelectorAll(".NoteRow")];
            // noteRows.forEach(row => row.dataset.mode = null);

            const noteElem = this.currentNote.querySelector(".Note");
            const bubbleElem = noteElem.querySelector(".Bubble");
            const controlElem = noteElem.querySelector(".Controls");

            noteElem.classList.remove("Selected");
            noteElem.classList.remove("Replying");

            bubbleElem.style.display = "";
            controlElem.style.display = "";

            this.restoreNewNoteControl();
        }
        else {
            this.textArea.value = "";
            this.textArea.parentNode.dataset.mode = "New";
            this.textArea.parentNode.dataset.note = null;
        }

        this.startAutoRefresh();
    }

    cancelDelete(event) {
        event.currentTarget.classList.remove("Visible");
        this.startAutoRefresh();
    }

    addNewNote(value, noteType) {
        const note: PartialNote = {
            referenceId: this.assetId,
            privacy: noteType == "0" ? 0 : 1,
            description: value
        };
        if (this.access < 2)
            return window.alert("You are not allowed to add notes");

        this.notesAPI.create(this.assetId, note)
            .then(note => {
                note.enteredDate = new Date(note.enteredDate);
                note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";
                note.hasReplies = false;

                this.notes.push(note);
                this.notesList.appendChild(this.renderNote(note));
                this.restoreNewNoteControl();
                this.notesList.scrollTo(0, this.notesList.scrollHeight);
            })
            .catch(err => {
                window.alert("Could not add note: " + err);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    updateNote(value, noteType) {
        if (!this.currentNote) return;
        const noteId = parseInt(this.currentNote.dataset.noteid);
        const note = this.notes.find(note => note.id === noteId);
        if (!note) return;

        if (this.access < 2)
            return window.alert("You are not allowed to update notes");

        note.description = value;
        note.privacy = noteType == "0" ? 0 : 1;

        this.notesAPI.update(this.assetId, note)
            .then((note: Note) => {
                const noteElem = this.currentNote.querySelector(".Note");
                const bubbleElem = noteElem.querySelector(".Bubble");
                const controlElem = noteElem.querySelector(".Controls");

                bubbleElem.innerHTML = DOMPurify.sanitize(note.description); // Should be same as value now.

                bubbleElem.style.display = "";
                controlElem.style.display = "";

                this.restoreNewNoteControl();
            })
            .catch(err => {
                window.alert("Could not update note: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    replyToNote(value, noteType) {
        if (!this.currentNote) return;

        if (this.access < 2)
            return window.alert("You are not allowed to add notes");

        const note: PartialNote = {
            parentId: parseInt(this.currentNote.dataset.noteid),
            privacy: noteType == "0" ? 0 : 1,
            description: value
        };

        this.notesAPI.create(this.assetId, note)
            .then(note => {
                note.enteredDate = new Date(note.enteredDate);
                note.authorEmail = note.authorEmail?.toLowerCase() || "anonymous@unknown.org";

                const parentNote = this.notes.find(n => note.parentId == n.id);
                if (parentNote) parentNote.hasReplies = true;

                this.notes.push(note);

                this.currentNote.appendChild(this.renderNote(note));
                this.currentNote.dataset.hasreplies = true;

                this.restoreNewNoteControl();
            })
            .catch(err => {
                window.alert("Could not add reply: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    deleteNote(event) {
        const self = this;
        function collectDescendants(id: number): Note[] {
            const notesToDelete = self.notes.filter(n => n.parentId === id);
            return [...notesToDelete, ...notesToDelete.map(n => collectDescendants(n.id)).flat()];
        }

        if (!this.currentNote) return;
        const noteId = parseInt(this.currentNote.dataset.noteid);
        const note = this.notes.find(note => note.id === noteId);
        if (!note) return;

        if (this.access < 2)
            return window.alert("You are not allowed to delete notes");

        this.notesAPI.delete(this.assetId, note.id)
            .then(conf => {
                // NOTE: The server deletes child notes as well,
                // and so we do the same thing here.
                const deletedNotes = [note.id, ...collectDescendants(note.id)];
                this.notes = this.notes.filter(n => !deletedNotes.includes(n.id));

                const parentNote = this.notes.find(n => note.parentId == n.id);
                if (parentNote) parentNote.hasReplies = !!this.notes.find(n => n.parentId == parentNote.id);

                const parent = this.currentNote.parentNode;
                parent.removeChild(this.currentNote);

                // DEBUG
                // if (!!parent.querySelector(".NoteRow") !== parentNote.hasReplies) {
                //     console.error("Mismatch in expectations around children for note " + parentNote.id);
                // }

                parent.dataset.hasreplies = !!parent.querySelector(".NoteRow");
            })
            .catch(err => {
                window.alert("Could not remove note: " + err.message);
            })
            .finally(() => {
                this.onChange(this.notes);
            });
    }

    restoreNewNoteControl() {
        if (this.currentNote) {
            const bubbleElem = this.currentNote.querySelector(".Note");
            bubbleElem.style.display = "";
        }

        this.textArea.value = "";
        this.textArea.parentNode.dataset.mode = "New";
        this.textArea.parentNode.dataset.note = null;

        this.notesArea.appendChild(this.noteEditor);
        this.notesList.style.height = null;
        this.currentNote = null;
    }

    toggleVisibility(event) {
        const isInteralButton = event.currentTarget;
        const noteType = isInteralButton.parentNode.dataset.notetype;
        isInteralButton.parentNode.dataset.notetype = noteType == "0" ? "1" : "0";
    }
}
