import { Component, Inject, Input, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChildElementDirective } from 'src/app/directives/child-element.directive';
import { UserPreferences } from 'src/app/types/user';
import { Personas } from 'src/dto/eom/persona';
import { User_v1 } from 'src/dto/eom/user-v1';
import { getUserProfileImageElement } from '../../pmo/helper';

@Component({
    selector: 'app-user-preview-card',
    templateUrl: './user-preview-card.component.html',
    styleUrls: ['./user-preview-card.component.scss'],
    host: {
        "[class.is-uninvited]": "!user.invited",
        "[class.is-inactive]": "!user.isActive",
        "[class.is-blocked]": "user.status == 'blocked'"
    },
    imports: [
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        ChildElementDirective
    ],
    standalone: true
})
export class UserPreviewCardComponent {

    @Input() user: User_v1;

    picture: HTMLElement;
    persona: string;
    preferences: UserPreferences;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) readonly data: any
    ) {
        this.user = this.user ?? data?.data;
    }

    ngOnInit() {
        this.picture = getUserProfileImageElement(this.user, [], '240x240', true, false);
        this.persona = Personas[this.user.persona] ?? this.user.persona;

        const prefData = this.user.preferences?.[0]?.data;
        try {
            this.preferences = JSON.parse(prefData)
        } catch(ex) { /* bad prefs */ }
    }
}
