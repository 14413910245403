{
    "assetmenu": [
        {
            "id": 1,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "icon": "group",
            "label": "Assignments",
            "description": "Show who is assigned to this asset.",
            "url": "#/navigator/assignments",
            "isInternal": false,
            "state": "standard",
            "docs": "/wiki/elevate/Panels/Assignments/",
            "dtoName": "all"
        },
        {
            "id": 2,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Associated Work",
            "description": "Show tasks associated with this asset.",
            "url": "#/navigator/ActiveTasks",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "all"
        },
        {
            "id": 22000,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Project",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/project/",
            "dtoName": "Project_v1"
        },
        {
            "id": 22001,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Project_v1"
        },
        {
            "id": 22002,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Project_v1"
        },
        {
            "id": 22003,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Project_v1"
        },
        {
            "id": 22004,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Goal",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/goal/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 22005,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Goal_v1"
        },
        {
            "id": 22006,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Goal_v1"
        },
        {
            "id": 22007,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Goal_v1"
        },
        {
            "id": 22008,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Coverage Goal",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/coverage-goal/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 22009,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 22010,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 22011,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 22012,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Objective",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/objective/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 22013,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Objective_v1"
        },
        {
            "id": 22014,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Objective_v1"
        },
        {
            "id": 22015,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Objective_v1"
        },
        {
            "id": 22016,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Coverage Objective",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/coverage-objective/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 22017,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 22018,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 22019,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 22020,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Task",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/task/",
            "dtoName": "Task_v1"
        },
        {
            "id": 22021,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Task_v1"
        },
        {
            "id": 22022,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Task_v1"
        },
        {
            "id": 22023,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Task_v1"
        },
        {
            "id": 22024,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Issue",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/issue/",
            "dtoName": "Issue_v1"
        },
        {
            "id": 22025,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Issue_v1"
        },
        {
            "id": 22026,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Issue_v1"
        },
        {
            "id": 22027,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Issue_v1"
        },
        {
            "id": 22028,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Risk",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/risk/",
            "dtoName": "Risk_v1"
        },
        {
            "id": 22029,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Risk_v1"
        },
        {
            "id": 22030,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Risk_v1"
        },
        {
            "id": 22031,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Risk_v1"
        },
        {
            "id": 22032,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Meeting",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/meeting/",
            "dtoName": "Meeting_v1"
        },
        {
            "id": 22033,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Meeting_v1"
        },
        {
            "id": 22034,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Meeting_v1"
        },
        {
            "id": 22035,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Meeting_v1"
        },
        {
            "id": 22036,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Event",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/dynatrace-event/",
            "dtoName": "DynatraceEvent_v1"
        },
        {
            "id": 22037,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "DynatraceEvent_v1"
        },
        {
            "id": 22038,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "DynatraceEvent_v1"
        },
        {
            "id": 22039,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "DynatraceEvent_v1"
        },
        {
            "id": 22040,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Portfolio",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/portfolio/",
            "dtoName": "Portfolio_v1"
        },
        {
            "id": 22041,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Portfolio_v1"
        },
        {
            "id": 22042,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Portfolio_v1"
        },
        {
            "id": 22043,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Portfolio_v1"
        },
        {
            "id": 22044,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Application",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/application/",
            "dtoName": "Application_v1"
        },
        {
            "id": 22045,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Application_v1"
        },
        {
            "id": 22046,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Application_v1"
        },
        {
            "id": 22047,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Application_v1"
        },
        {
            "id": 22048,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Platform",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/application/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 22049,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Platform_v1"
        },
        {
            "id": 22050,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Platform_v1"
        },
        {
            "id": 22051,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Platform_v1"
        },
        {
            "id": 22052,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Footprint",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/footprint/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 22053,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Footprint_v1"
        },
        {
            "id": 22054,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Footprint_v1"
        },
        {
            "id": 22055,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Footprint_v1"
        },
        {
            "id": 22056,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Logical Environment",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/logical-environment/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 22057,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 22058,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 22059,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 22060,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Provider",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/provider/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 22061,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Provider_v1"
        },
        {
            "id": 22062,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Provider_v1"
        },
        {
            "id": 22063,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Provider_v1"
        },
        {
            "id": 22064,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Region",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/region/",
            "dtoName": "Region_v1"
        },
        {
            "id": 22065,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Region_v1"
        },
        {
            "id": 22066,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Region_v1"
        },
        {
            "id": 22067,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Region_v1"
        },
        {
            "id": 22068,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Location",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/location/",
            "dtoName": "Location_v1"
        },
        {
            "id": 22069,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Location_v1"
        },
        {
            "id": 22070,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Location_v1"
        },
        {
            "id": 22071,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Location_v1"
        },
        {
            "id": 22072,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Network Zone",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/networkzone/",
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 22073,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 22074,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 22075,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 22076,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Cluster",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/dynatrace-cluster/",
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 22077,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 22078,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 22079,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 22080,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Environment",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/dynatrace-environment/",
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 22081,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 22082,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 22083,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 22084,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Costcenter",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/costcenter/",
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 22085,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 22086,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 22087,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 22088,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Observability Claim",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/observability-claim/",
            "dtoName": "ObservabilityClaim_v1"
        },
        {
            "id": 22089,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "ObservabilityClaim_v1"
        },
        {
            "id": 22090,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "ObservabilityClaim_v1"
        },
        {
            "id": 22091,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "ObservabilityClaim_v1"
        },
        {
            "id": 22092,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Rategroup",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/rategroup/",
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 22093,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 22094,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 22095,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 22096,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Rate Card",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/rate-card/",
            "dtoName": "RateCard_v1"
        },
        {
            "id": 22097,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "RateCard_v1"
        },
        {
            "id": 22098,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "RateCard_v1"
        },
        {
            "id": 22099,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "RateCard_v1"
        },
        {
            "id": 22100,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "User",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/user/",
            "dtoName": "User_v1"
        },
        {
            "id": 22101,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "User_v1"
        },
        {
            "id": 22102,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "User_v1"
        },
        {
            "id": 22103,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "User_v1"
        },
        {
            "id": 22104,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Group",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/group/",
            "dtoName": "Group_v1"
        },
        {
            "id": 22105,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Group_v1"
        },
        {
            "id": 22106,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Group_v1"
        },
        {
            "id": 22107,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Group_v1"
        },
        {
            "id": 22108,
            "parent": -2,
            "order": 1,
            "location": "assetmenu",
            "label": "Organization",
            "isInternal": false,
            "docs": "/wiki/elevate/Assets/group/",
            "dtoName": "Organization_v1"
        },
        {
            "id": 22109,
            "parent": -2,
            "order": 2,
            "location": "assetmenu",
            "label": "Views",
            "isInternal": false,
            "dtoName": "Organization_v1"
        },
        {
            "id": 22110,
            "parent": -2,
            "order": 3,
            "location": "assetmenu",
            "label": "Reports",
            "isInternal": false,
            "dtoName": "Organization_v1"
        },
        {
            "id": 22111,
            "parent": -2,
            "order": 4,
            "location": "assetmenu",
            "label": "Tools",
            "isInternal": false,
            "dtoName": "Organization_v1"
        },
        {
            "id": 220001,
            "parent": 22000,
            "order": 1,
            "location": "assetmenu",
            "label": "Goals",
            "description": "Goals and coverage goals for this project.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220002,
            "parent": 22000,
            "order": 2,
            "location": "assetmenu",
            "label": "Risks",
            "description": "Risks associated with this project.",
            "url": "#/navigator/risks?viewMode=risk&assetMode=risk",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Risks/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220003,
            "parent": 22000,
            "order": 3,
            "location": "assetmenu",
            "label": "Issues",
            "description": "Issues tracked in this project.",
            "url": "#/navigator/Issues?viewMode=issue&assetMode=issue",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Issues/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220004,
            "parent": 22000,
            "order": 4,
            "location": "assetmenu",
            "label": "Files",
            "description": "Project documents, templates, recordings, etc.",
            "url": "#/navigator/FileStorage",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Files/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220011,
            "parent": 22001,
            "order": 4,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All tasks for this project as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220012,
            "parent": 22001,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All tasks for this project as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220013,
            "parent": 22001,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The project's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220014,
            "parent": 22001,
            "order": 3,
            "location": "assetmenu",
            "icon": "calendar_month",
            "label": "Calendar",
            "description": "Meetings and other relevant events for this project.",
            "url": "#/navigator/calendar",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Calendar/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220021,
            "parent": 22002,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this project.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220022,
            "parent": 22002,
            "order": 2,
            "location": "assetmenu",
            "label": "Project Tracking",
            "longname": "Project Tracking",
            "url": "#/navigator/ProjectTracking",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/EngagementTracking/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220023,
            "parent": 22002,
            "order": 3,
            "location": "assetmenu",
            "label": "Weekly Planner",
            "url": "#/navigator/weekly-planner",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/WeeklyPlanner/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220031,
            "parent": 22003,
            "order": 1,
            "location": "assetmenu",
            "label": "Import Events",
            "url": "#/navigator/Power-Import-Wizard-pmo",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ImportEvents/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220032,
            "parent": 22003,
            "order": 2,
            "location": "assetmenu",
            "label": "Export Events",
            "url": "#/navigator/Power-Export-Wizard-pmo?mode=pmo",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ExportEvents/",
            "dtoName": "Project_v1"
        },
        {
            "id": 220043,
            "parent": 22004,
            "order": 3,
            "location": "assetmenu",
            "label": "Objectives",
            "description": "Objectives and coverage objectives for this goal.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 220051,
            "parent": 22005,
            "order": 3,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All tasks for this goal as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 220052,
            "parent": 22005,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All tasks for this goal as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 220053,
            "parent": 22005,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The goal's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 220061,
            "parent": 22006,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this goal.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "Goal_v1"
        },
        {
            "id": 220081,
            "parent": 22008,
            "order": 1,
            "location": "assetmenu",
            "label": "Objectives",
            "description": "Objectives and coverage objectives for this goal.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 220091,
            "parent": 22009,
            "order": 3,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All tasks for this goal as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 220092,
            "parent": 22009,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All tasks for this goal as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 220093,
            "parent": 22009,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The goal's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 220101,
            "parent": 22010,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this goal.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "CoverageGoal_v1"
        },
        {
            "id": 220121,
            "parent": 22012,
            "order": 1,
            "location": "assetmenu",
            "label": "Tasks",
            "description": "Immediate tasks under this objective.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 220131,
            "parent": 22013,
            "order": 3,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All tasks for this objective as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 220132,
            "parent": 22013,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All tasks for this objective as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 220133,
            "parent": 22013,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The objective's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 220141,
            "parent": 22014,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this objective.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "Objective_v1"
        },
        {
            "id": 220161,
            "parent": 22016,
            "order": 1,
            "location": "assetmenu",
            "label": "Tasks",
            "description": "Immediate tasks under this objective.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 220171,
            "parent": 22017,
            "order": 3,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All tasks for this objective as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 220172,
            "parent": 22017,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All tasks for this objective as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 220173,
            "parent": 22017,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The objective's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 220181,
            "parent": 22018,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this objective.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "CoverageObjective_v1"
        },
        {
            "id": 220201,
            "parent": 22020,
            "order": 1,
            "location": "assetmenu",
            "label": "Sub-tasks",
            "description": "Immediate sub-tasks under this task.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220202,
            "parent": 22020,
            "order": 2,
            "location": "assetmenu",
            "label": "Associations",
            "description": "Assets this task is associated with.",
            "url": "#/navigator/associations",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Associations/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220211,
            "parent": 22021,
            "order": 3,
            "location": "assetmenu",
            "icon": "fact_check",
            "label": "Work list",
            "description": "All sub-tasks for this task as a list.",
            "url": "#/navigator/ActiveTasks?assetMode=task",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Worklist/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220212,
            "parent": 22021,
            "order": 2,
            "location": "assetmenu",
            "icon": "view_kanban",
            "label": "Kanban",
            "description": "All sub-tasks for this task as a Kanban board.",
            "url": "#/navigator/kanban",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/Kanban/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220213,
            "parent": 22021,
            "order": 1,
            "location": "assetmenu",
            "icon": "view_timeline",
            "label": "Gantt",
            "description": "The task's hierarchy and timelines.",
            "url": "#/navigator/gantt",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Gantt/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220221,
            "parent": 22022,
            "order": 1,
            "location": "assetmenu",
            "icon": "timer",
            "label": "Timecards",
            "description": "Timecards entered in the context of this task.",
            "url": "#/navigator/Timecards",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/Timecards/",
            "dtoName": "Task_v1"
        },
        {
            "id": 220401,
            "parent": 22040,
            "order": 1,
            "location": "assetmenu",
            "label": "Applications & Platforms",
            "description": "Applications and platforms in this portfolio.",
            "url": "#/navigator/children?property=children&count=footprints",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Portfolio_v1"
        },
        {
            "id": 220441,
            "parent": 22044,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Content",
            "description": "Dynatrace enterprise guidelines.",
            "url": "#/navigator/info?data=appinfodata.json",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Enterprise/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220442,
            "parent": 22044,
            "order": 3,
            "location": "assetmenu",
            "label": "Footprints",
            "description": "Footprints of this application.",
            "url": "#/navigator/children?property=footprints&count=logicalEnvironments",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220443,
            "parent": 22044,
            "order": 2,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=footprints&count=footprints",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Application_v1"
        },
        {
            "id": 220444,
            "parent": 22044,
            "order": 4,
            "location": "assetmenu",
            "label": "Dynatrace Requests",
            "description": "Requests for Dynatrace coverage for this application's observable inventory.",
            "url": "#/navigator/observability-allocations?property=allocations",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceRequests/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220445,
            "parent": 22044,
            "order": 5,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Validate the attribution of monitored assets to this application.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220451,
            "parent": 22045,
            "order": 1,
            "location": "assetmenu",
            "label": "Application Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220452,
            "parent": 22045,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets for this application.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220453,
            "parent": 22045,
            "order": 3,
            "location": "assetmenu",
            "label": "Files",
            "description": "Application documents, templates, standards, etc.",
            "url": "#/navigator/FileStorage",
            "isInternal": false,
            "dtoName": "Application_v1"
        },
        {
            "id": 220461,
            "parent": 22046,
            "order": 1,
            "location": "assetmenu",
            "label": "Problem Analysis",
            "url": "#/navigator/problemsbyasset",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ProblemAnalysis/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220471,
            "parent": 22047,
            "order": 1,
            "location": "assetmenu",
            "label": "Import Footprints",
            "url": "#/navigator/Power-Import-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ImportFootprints/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220472,
            "parent": 22047,
            "order": 2,
            "location": "assetmenu",
            "label": "Export Application",
            "url": "#/navigator/Power-Export-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ExportApplication/",
            "dtoName": "Application_v1"
        },
        {
            "id": 220481,
            "parent": 22048,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Content",
            "description": "Dynatrace enterprise guidelines.",
            "url": "#/navigator/info?data=appinfodata.json",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Enterprise/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220482,
            "parent": 22048,
            "order": 2,
            "location": "assetmenu",
            "label": "Footprints",
            "description": "Footprints of this platform.",
            "url": "#/navigator/children?property=footprints&count=logicalEnvironments",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220483,
            "parent": 22048,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=footprints&count=footprints",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220484,
            "parent": 22048,
            "order": 4,
            "location": "assetmenu",
            "label": "Dynatrace Requests",
            "description": "Requests for Dynatrace coverage for this platform's observable inventory.",
            "url": "#/navigator/observability-allocations?property=allocations",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceRequests/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220485,
            "parent": 22048,
            "order": 5,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Validate the attribution of monitored assets to this platform.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220491,
            "parent": 22049,
            "order": 1,
            "location": "assetmenu",
            "label": "Platform Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220492,
            "parent": 22049,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets for this platform.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220493,
            "parent": 22049,
            "order": 3,
            "location": "assetmenu",
            "label": "Files",
            "description": "Platform documents, templates, standards, etc.",
            "url": "#/navigator/FileStorage",
            "isInternal": false,
            "dtoName": "Platform_v1"
        },
        {
            "id": 220501,
            "parent": 22050,
            "order": 1,
            "location": "assetmenu",
            "label": "Problem Analysis",
            "url": "#/navigator/problem-analysis",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ProblemAnalysis/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220511,
            "parent": 22051,
            "order": 1,
            "location": "assetmenu",
            "label": "Import Footprints",
            "url": "#/navigator/Power-Import-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ImportFootprints/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220512,
            "parent": 22051,
            "order": 2,
            "location": "assetmenu",
            "label": "Export Platform",
            "url": "#/navigator/Power-Export-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ExportApplication/",
            "dtoName": "Platform_v1"
        },
        {
            "id": 220521,
            "parent": 22052,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Content",
            "url": "#/navigator/info?data=appinfodata.json",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Enterprise/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220522,
            "parent": 22052,
            "order": 2,
            "location": "assetmenu",
            "label": "Logical Environments",
            "url": "#/navigator/children?property=logicalEnvironments",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220523,
            "parent": 22052,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=logicalEnvironments&count=footprints",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220524,
            "parent": 22052,
            "order": 4,
            "location": "assetmenu",
            "label": "Dynatrace Requests",
            "description": "Requests for Dynatrace coverage for this footprint's observable inventory.",
            "url": "#/navigator/observability-allocations?property=allocations",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceRequests/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220525,
            "parent": 22052,
            "order": 5,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Validate the attribution of monitored assets to this footprint.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220531,
            "parent": 22053,
            "order": 1,
            "location": "assetmenu",
            "label": "Platform Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220532,
            "parent": 22053,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets for this footprint.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220541,
            "parent": 22054,
            "order": 1,
            "location": "assetmenu",
            "label": "Problem Analysis",
            "url": "#/navigator/problem-analysis",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ProblemAnalysis/",
            "dtoName": "Footprint_v1"
        },
        {
            "id": 220561,
            "parent": 22056,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Content",
            "url": "#/navigator/info?data=appinfodata.json",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Enterprise/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220562,
            "parent": 22056,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=zonesByProvider",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220563,
            "parent": 22056,
            "order": 3,
            "location": "assetmenu",
            "label": "Dynatrace Requests",
            "description": "Requests for Dynatrace coverage for this logical environment's observable inventory.",
            "url": "#/navigator/observability-allocations?property=allocations",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceRequests/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220564,
            "parent": 22056,
            "order": 4,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Validate the attribution of monitored assets to this logical environment.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220571,
            "parent": 22057,
            "order": 1,
            "location": "assetmenu",
            "label": "Environment Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220572,
            "parent": 22057,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets for this logical environment.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220581,
            "parent": 22058,
            "order": 1,
            "location": "assetmenu",
            "label": "Problem Analysis",
            "url": "#/navigator/problem-analysis",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ProblemAnalysis/",
            "dtoName": "LogicalEnvironment_v1"
        },
        {
            "id": 220601,
            "parent": 22060,
            "order": 4,
            "location": "assetmenu",
            "label": "Regions",
            "description": "Regions in which this provider has locations/datacenters.",
            "url": "#/navigator/children?property=regions&count=locations",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220602,
            "parent": 22060,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=regions&count=locations",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220611,
            "parent": 22061,
            "order": 1,
            "location": "assetmenu",
            "label": "Topology Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220612,
            "parent": 22061,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets hosted by this provider.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220631,
            "parent": 22063,
            "order": 1,
            "location": "assetmenu",
            "label": "Import Topology",
            "url": "#/navigator/Power-Import-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ImportProvider/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220632,
            "parent": 22063,
            "order": 2,
            "location": "assetmenu",
            "label": "Export Topology",
            "url": "#/navigator/Power-Export-Wizard-eob",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ExportProvider/",
            "dtoName": "Provider_v1"
        },
        {
            "id": 220641,
            "parent": 22064,
            "order": 1,
            "location": "assetmenu",
            "label": "Locations",
            "description": "Locations or datacenters in this region.",
            "url": "#/navigator/children?property=locations&count=zones",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Region_v1"
        },
        {
            "id": 220642,
            "parent": 22064,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=locations",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Region_v1"
        },
        {
            "id": 220651,
            "parent": 22065,
            "order": 1,
            "location": "assetmenu",
            "label": "Topology Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Region_v1"
        },
        {
            "id": 220652,
            "parent": 22065,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets in this region.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Region_v1"
        },
        {
            "id": 220681,
            "parent": 22068,
            "order": 1,
            "location": "assetmenu",
            "label": "Network Zones",
            "description": "Network zones in this location.",
            "url": "#/navigator/children?property=zones",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Location_v1"
        },
        {
            "id": 220682,
            "parent": 22068,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=zones",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Location_v1"
        },
        {
            "id": 220691,
            "parent": 22069,
            "order": 1,
            "location": "assetmenu",
            "label": "Topology Diagram",
            "url": "#/navigator/dynamic-diagram",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynamicDiagram/",
            "dtoName": "Location_v1"
        },
        {
            "id": 220692,
            "parent": 22069,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets at this location.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Location_v1"
        },
        {
            "id": 220721,
            "parent": 22072,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=inventoryItems",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 220731,
            "parent": 22073,
            "order": 1,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitorable assets in this network zone.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "NetworkZone_v1"
        },
        {
            "id": 220761,
            "parent": 22076,
            "order": 1,
            "location": "assetmenu",
            "label": "Dynatrace Environments",
            "url": "#/navigator/children?property=environments",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 220762,
            "parent": 22076,
            "order": 2,
            "location": "assetmenu",
            "label": "Dynatrace Claims",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceClaims/",
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 220763,
            "parent": 22076,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=dynatraceEnvironments",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 220771,
            "parent": 22077,
            "order": 1,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Assets monitored by this Dynatrace cluster.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "DynatraceCluster_v1"
        },
        {
            "id": 220801,
            "parent": 22080,
            "order": 2,
            "location": "assetmenu",
            "label": "Dynatrace Claims",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/DynatraceClaims/",
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220802,
            "parent": 22080,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=activeGates",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220811,
            "parent": 22081,
            "order": 1,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Assets monitored by this Dynatrace environment.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220812,
            "parent": 22081,
            "order": 2,
            "location": "assetmenu",
            "label": "Traffic Visualizer",
            "description": "A 3D visualization of RUM traffic per region or location.",
            "url": "#/navigator/DtGlobeCityRum",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220814,
            "parent": 22081,
            "order": 4,
            "location": "assetmenu",
            "label": "Audit Log Viewer",
            "description": "Audit logs of this Dynatrace environment.",
            "url": "#/navigator/DtAuditLogs",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220821,
            "parent": 22082,
            "order": 1,
            "location": "assetmenu",
            "label": "Management Zone Stats",
            "description": "Show hosts per management zone.",
            "url": "#/navigator/DtManagementZones",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220822,
            "parent": 22082,
            "order": 2,
            "location": "assetmenu",
            "label": "Monitoring Mode Analyzer",
            "description": "Identify recommended monitoring modes for hosts.",
            "url": "#/navigator/DtHostMonitoringMode",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220823,
            "parent": 22082,
            "order": 3,
            "location": "assetmenu",
            "label": "OneAgent Network Zones",
            "description": "Show OneAgent connectivity to this Dynatrace environment.",
            "url": "#/navigator/DtNetworkZones",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220824,
            "parent": 22082,
            "order": 4,
            "location": "assetmenu",
            "label": "OneAgent Versions",
            "description": "Show OneAgent versions in use.",
            "url": "#/navigator/DtOneAgentVersions",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220826,
            "parent": 22082,
            "order": 6,
            "location": "assetmenu",
            "label": "Tag Analysis",
            "description": "Show tags and values in use per host.",
            "url": "#/navigator/DtTagMapper",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220827,
            "parent": 22082,
            "order": 7,
            "location": "assetmenu",
            "label": "Tag Compliance",
            "description": "Identify hosts without tags or tag values.",
            "url": "#/navigator/DtTagCompliance",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220831,
            "parent": 22083,
            "order": 1,
            "location": "assetmenu",
            "label": "API Tokens",
            "description": "Manage tokens to access the Dynatrace APIs.",
            "url": "#/navigator/ApiTokens",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220832,
            "parent": 22083,
            "order": 2,
            "location": "assetmenu",
            "label": "Batch Monitoring Config",
            "description": "Configure monitoring configuration for OneAgents.",
            "url": "#/navigator/DtBatchHostConfig",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220833,
            "parent": 22083,
            "order": 3,
            "location": "assetmenu",
            "label": "Environment Diff",
            "description": "View and resolve differences between Dynatrace environments.",
            "url": "#/navigator/DtEnvironmentDiff",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220834,
            "parent": 22083,
            "order": 4,
            "location": "assetmenu",
            "label": "Configuration Manager",
            "description": "Export or Import Dynatrace configurations as JSON.",
            "url": "#/navigator/DtApiConfigManager",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220835,
            "parent": 22083,
            "order": 5,
            "location": "assetmenu",
            "label": "Entity Export",
            "description": "Export Dynatrace entities as JSON or CSV.",
            "url": "#/navigator/DtEntityExport",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220836,
            "parent": 22083,
            "order": 6,
            "location": "assetmenu",
            "label": "USQL Export",
            "description": "Export data produced by USQL queries.",
            "url": "#/navigator/DtUSQLExport",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220841,
            "parent": 22084,
            "order": 1,
            "location": "assetmenu",
            "label": "Organizations",
            "description": "Organizations that use this costcenter.",
            "url": "#/navigator/children?property=organization",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 220842,
            "parent": 22084,
            "order": 2,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Observability claims that use this costcenter.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 220851,
            "parent": 22085,
            "order": 1,
            "location": "assetmenu",
            "label": "Charging Applications",
            "description": "Applications, footprints and environments that charge to this cost center.",
            "url": "#/navigator/ComingSoon",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/BlueprintAssets/",
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 220852,
            "parent": 22085,
            "order": 2,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitored assets actually charged to this cost center.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "Costcenter_v1"
        },
        {
            "id": 220891,
            "parent": 22089,
            "order": 1,
            "location": "assetmenu",
            "label": "Observable Inventory",
            "description": "Monitored assets recognized under this claim.",
            "url": "#/navigator/observability-inventory",
            "isInternal": false,
            "state": "default",
            "docs": "/wiki/elevate/Panels/ObservableInventory/",
            "dtoName": "ObservabilityClaim_v1"
        },
        {
            "id": 220921,
            "parent": 22092,
            "order": 1,
            "location": "assetmenu",
            "label": "Rate Cards",
            "description": "Rate cards for this rategroup.",
            "url": "#/navigator/rate-cards",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 220922,
            "parent": 22092,
            "order": 2,
            "location": "assetmenu",
            "label": "Observability Claims",
            "description": "Observability claims that use this rategroup.",
            "url": "#/navigator/observability-claims?property=claims",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/ObservabilityClaims/",
            "dtoName": "Rategroup_v1"
        },
        {
            "id": 221001,
            "parent": 22100,
            "order": 1,
            "location": "assetmenu",
            "label": "Preferences",
            "url": "#/navigator/UserPreferences",
            "isInternal": false,
            "state": "limited",
            "docs": "/wiki/elevate/Panels/Preferences/",
            "dtoName": "User_v1"
        },
        {
            "id": 221002,
            "parent": 22100,
            "order": 2,
            "location": "assetmenu",
            "label": "Groups",
            "description": "Groups this user is a member of.",
            "url": "#/navigator/memberships?property=groups&dialogMode=false",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Memberships/",
            "dtoName": "User_v1"
        },
        {
            "id": 221031,
            "parent": 22103,
            "order": 1,
            "location": "assetmenu",
            "label": "API Tokens",
            "description": "Manage tokens to access the Dynatrace APIs.",
            "url": "#/navigator/ApiTokens",
            "isInternal": false,
            "dtoName": "User_v1"
        },
        {
            "id": 221081,
            "parent": 22108,
            "order": 1,
            "location": "assetmenu",
            "label": "Sub-organizations",
            "description": "Immediate sub-organizations under this organization.",
            "url": "#/navigator/children?property=children&count=children",
            "isInternal": false,
            "state": "fixed",
            "docs": "/wiki/elevate/Panels/Children/",
            "dtoName": "Organization_v1"
        },
        {
            "id": 221084,
            "parent": 22108,
            "order": 1,
            "location": "assetmenu",
            "label": "Visualizer",
            "url": "#/navigator/visualizer?property=applications&count=applications",
            "isInternal": false,
            "state": "fixed",
            "dtoName": "Organization_v1"
        },
        {
            "id": 221107,
            "parent": 22002,
            "order": 4,
            "location": "assetmenu",
            "label": "Weekly Planner Generator",
            "url": "#/navigator/Weekly-Generator",
            "isInternal": false,
            "docs": "/wiki/elevate/Panels/WeeklyPlanner/",
            "dtoName": "Project_v1"
        },
        {
            "id": 221110,
            "parent": 22082,
            "order": 8,
            "location": "assetmenu",
            "label": "License Breakdown",
            "description": "Break down license consumption by Management Zone",
            "url": "#/navigator/DtLicenseBreakdown",
            "isInternal": false,
            "dtoName": "DynatraceEnvironment_v1"
        },
        {
            "id": 220024,
            "parent": 22002,
            "order": 4,
            "location": "assetmenu",
            "label": "Project Documentation",
            "description": "Generated project documentation",
            "url": "#/navigator/documentation-generator",
            "dtoName": "Project_v1",
            "group": "ES-ROLE-ELEVATE_ADMIN"
        }
    ],
    "personamenu": [
        {
            "id": 21000,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "apps",
            "label": "My Applications",
            "longname": "Enterprise Applications",
            "description": "Manage enterprise application portfolio",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "dtoName": "Application_v1"
        },
        {
            "id": 21054,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21055,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "fact_check",
            "label": "My Work List",
            "longname": "Associated Work",
            "url": "#/navigator?$scope=user.{$user.id}&maximized=true",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21056,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "source",
            "label": "My Projects",
            "longname": "Projects",
            "url": "/#/navigator?$scope=project?expand=left",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21057,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "assignment_ind",
            "label": "Services Engagements",
            "longname": "Service Report",
            "url": "#/ServiceReport",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21058,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "apps",
            "label": "Enterprise Apps",
            "longname": "Applications",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21059,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21060,
            "parent": 0,
            "order": 7,
            "location": "personamenu",
            "icon": "dvr",
            "label": "Dynatrace Environments",
            "longname": "Environments",
            "url": "#/navigator?$scope=saas",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21062,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTCS"
        },
        {
            "id": 21063,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "fact_check",
            "label": "My Work List",
            "longname": "Associated Work",
            "url": "#/navigator?$scope=user.{$user.id}&maximized=true",
            "isInternal": false,
            "persona": "DTCS"
        },
        {
            "id": 21064,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "source",
            "label": "Enterprise Projects",
            "longname": "Projects",
            "url": "#/navigator?$scope=project",
            "isInternal": false,
            "persona": "DTCS"
        },
        {
            "id": 21065,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "analytics",
            "label": "License Details",
            "longname": "License Consumption",
            "url": "#/licensereport",
            "isInternal": false,
            "persona": "DTCS"
        },
        {
            "id": 21066,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "DTCS"
        },
        {
            "id": 21067,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTAE"
        },
        {
            "id": 21068,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "work_outline",
            "label": "My Accounts",
            "longname": "Accounts",
            "url": "#/accounts",
            "isInternal": true,
            "persona": "DTAE"
        },
        {
            "id": 21069,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "ads_click",
            "label": "POCs",
            "longname": "Sales Projects",
            "url": "#/navigator?$scope=project",
            "isInternal": true,
            "persona": "DTAE"
        },
        {
            "id": 21070,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "analytics",
            "label": "License Details",
            "longname": "License Consumption",
            "url": "#/licensereport",
            "isInternal": false,
            "persona": "DTAE"
        },
        {
            "id": 21071,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "payments",
            "label": "Rate Groups",
            "longname": "Chargeback Rate Groups",
            "url": "#/navigator?$scope=rategroup",
            "isInternal": false,
            "persona": "DTAE"
        },
        {
            "id": 21072,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "DTAE"
        },
        {
            "id": 21073,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21074,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "work_outline",
            "label": "My Accounts",
            "longname": "Accounts",
            "url": "#/accounts",
            "isInternal": true,
            "persona": "DTSE"
        },
        {
            "id": 21075,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "ads_click",
            "label": "POCs",
            "longname": "Sales Projects",
            "url": "#/navigator?$scope=project",
            "isInternal": true,
            "persona": "DTSE"
        },
        {
            "id": 21076,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "fact_check",
            "label": "My Work List",
            "longname": "Associated Work",
            "url": "#/navigator?$scope=user.{$user.id}&maximized=true",
            "isInternal": false,
            "persona": "DTAE"
        },
        {
            "id": 21077,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Infrastruture Topology",
            "longname": "Import Blueprint",
            "url": "#/navigator?$scope=infratopology",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21078,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "apps",
            "label": "Enterprise Apps",
            "longname": "Applications",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21079,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "analytics",
            "label": "License Details",
            "longname": "License Consumption",
            "url": "#/licensereport",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21080,
            "parent": 0,
            "order": 7,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21081,
            "parent": 0,
            "order": 8,
            "location": "personamenu",
            "icon": "cloud_upload",
            "label": "Import Cloud Blueprint",
            "longname": "Import Blueprint",
            "url": "#/Power-Import-Wizard-eob",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21082,
            "parent": 0,
            "order": 9,
            "location": "personamenu",
            "icon": "drive_folder_upload",
            "label": "Import Project",
            "longname": "Import Project",
            "url": "#/Power-Import-Wizard-pmo",
            "isInternal": false,
            "persona": "DTSE"
        },
        {
            "id": 21084,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "inventory",
            "label": "Employee Milestones",
            "longname": "Employee Milestones",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Milestones_Dashboard&workspace=General%2BPortal",
            "isInternal": false,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21085,
            "parent": 0,
            "order": 7,
            "location": "personamenu",
            "icon": "workspace_premium",
            "label": "Skills & Certs",
            "longname": "Industry Skills & Certifications",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Skills_Certs_Dashboard&workspace=Employee+Portal",
            "isInternal": false,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21086,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "rate_review",
            "label": "Global Feedback",
            "longname": "Feedback Report",
            "url": "#/PowerBI?reportId=Hidden-FeedbackReport&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21087,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "assignment_ind",
            "label": "Services Engagements",
            "longname": "Service Support",
            "url": "#/ServiceReport",
            "isInternal": false,
            "persona": "DTSM"
        },
        {
            "id": 21088,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTSM"
        },
        {
            "id": 21090,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "DTEX"
        },
        {
            "id": 21091,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "person_add",
            "label": "User Management",
            "longname": "Manage User Access",
            "url": "#/navigator?$scope=user",
            "isInternal": false,
            "persona": "DTSM"
        },
        {
            "id": 21092,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "assignment_ind",
            "label": "Services Engagements",
            "longname": "Service Report",
            "url": "#/ServiceReport",
            "isInternal": false,
            "persona": "DTEX"
        },
        {
            "id": 21093,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "topic",
            "label": "Enterprise Projects",
            "longname": "Projects Overview",
            "url": "#/navigator?$scope=project",
            "isInternal": false,
            "persona": "DTSM"
        },
        {
            "id": 21094,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "work_outline",
            "label": "My Accounts",
            "longname": "Accounts within the general portal",
            "url": "#/Accounts",
            "isInternal": true,
            "persona": "DTCS"
        },
        {
            "id": 21095,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "topic",
            "label": "Enterprise Projects",
            "longname": "Projects Overview",
            "url": "#/navigator?$scope=project",
            "isInternal": false,
            "persona": "DTEX"
        },
        {
            "id": 21097,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "inventory",
            "label": "Employee Milestones",
            "longname": "Employee Milestones Report ",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Milestones_Dashboard&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTEX",
            "background": "#00a08c"
        },
        {
            "id": 21098,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "rate_review",
            "label": "Global Feedback",
            "longname": "Feedback Overview",
            "url": "#/PowerBI?reportId=Hidden-FeedbackReport&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTEX",
            "background": "#00a08c"
        },
        {
            "id": 21099,
            "parent": 0,
            "order": 8,
            "location": "personamenu",
            "icon": "reviews",
            "label": "Consultant Overview",
            "longname": "Performance Reviews",
            "isInternal": true,
            "persona": "DTSM"
        },
        {
            "id": 21101,
            "parent": 21099,
            "order": 2,
            "location": "personamenu",
            "label": "EMEA",
            "longname": "EMEA Performance Reviews",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_EMEA&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21102,
            "parent": 21099,
            "order": 3,
            "location": "personamenu",
            "label": "APAC",
            "longname": "APAC Performance Review",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_APAC&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21103,
            "parent": 21099,
            "order": 4,
            "location": "personamenu",
            "label": "UK",
            "longname": "UK Performance Review",
            "url": "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_UK&workspace=General+Portal",
            "isInternal": true,
            "persona": "DTSM",
            "background": "#00a08c"
        },
        {
            "id": 21104,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "DTSM"
        },
        {
            "id": 21105,
            "parent": 0,
            "order": 8,
            "location": "personamenu",
            "icon": "backup",
            "label": "Import Blueprint",
            "longname": "Enterprise Observability Blueprint",
            "url": "#/Power-Import-Wizard-eob?mode=eob",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21106,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Infrastructure Topology",
            "longname": "Infrastructure Topology",
            "url": "#/navigator?$scope=Infratopology",
            "isInternal": false,
            "persona": "DTSA"
        },
        {
            "id": 21107,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Infrastructure Topology",
            "longname": "Infrastructure Topology",
            "url": "#/navigator?$scope=Infratopology",
            "isInternal": false,
            "persona": "AIAR"
        },
        {
            "id": 21108,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "apps",
            "label": "Enterprise Apps",
            "longname": "Applications",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "persona": "AIAR"
        },
        {
            "id": 21110,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "schema",
            "label": "Observability Coverage",
            "longname": "Enterprise Observability Coverage Analysis",
            "url": "#/coverage2",
            "isInternal": false,
            "persona": "PROJ"
        },
        {
            "id": 21111,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "grid_view",
            "label": "Enterprise Platforms",
            "longname": "Platforms",
            "url": "#/navigator?$scope=platform",
            "isInternal": false,
            "persona": "AIAR"
        },
        {
            "id": 21114,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "apps",
            "label": "My Applications",
            "longname": "Applications Overview",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "persona": "APPM"
        },
        {
            "id": 21115,
            "parent": 0,
            "order": 0,
            "location": "personamenu",
            "icon": "fact_check",
            "label": "My Work List",
            "longname": "Associated Work",
            "url": "#/navigator?$scope=user.{$user.id}&maximized=true",
            "isInternal": false,
            "persona": "APPM"
        },
        {
            "id": 21117,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Import App Blueprint",
            "url": "#/Power-Import-Wizard-eob?&mode=eob",
            "isInternal": false,
            "persona": "APPM"
        },
        {
            "id": 221091,
            "parent": 0,
            "order": 1,
            "location": "personamenu",
            "icon": "space_dashboard",
            "label": "Elevate Daily",
            "longname": "Elevate Daily",
            "url": "#/ElevateDaily",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221092,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "analytics",
            "label": "License Details",
            "longname": "License Consumption",
            "url": "#/licensereport",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221093,
            "parent": 0,
            "order": 3,
            "location": "personamenu",
            "icon": "assignment_ind",
            "label": "Services Engagements",
            "longname": "Service Report",
            "url": "#/ServiceReport",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221094,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "support",
            "label": "Support Tickets",
            "longname": "Support Report",
            "url": "#/SupportReport",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221095,
            "parent": 0,
            "order": 5,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Infrastruture Topology",
            "longname": "Import Blueprint",
            "url": "#/navigator?$scope=Infratopology",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221096,
            "parent": 0,
            "order": 6,
            "location": "personamenu",
            "icon": "apps",
            "label": "Enterprise Platforms",
            "longname": "Platforms",
            "url": "#/navigator?$scope=platform",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221097,
            "parent": 0,
            "order": 7,
            "location": "personamenu",
            "icon": "apps",
            "label": "Enterprise Apps",
            "longname": "Applications",
            "url": "#/navigator?$scope=application",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221098,
            "parent": 0,
            "order": 8,
            "location": "personamenu",
            "icon": "dvr",
            "label": "Dynatrace Environments",
            "longname": "Environments",
            "url": "#/navigator?$scope=saas",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221099,
            "parent": 0,
            "order": 9,
            "location": "personamenu",
            "icon": "work_outline",
            "label": "Organizations",
            "longname": "Companies & Orgs",
            "url": "#/navigator?$scope=organization",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221100,
            "parent": 0,
            "order": 10,
            "location": "personamenu",
            "icon": "work_outline",
            "label": "Groups",
            "longname": "Groups",
            "url": "#/navigator?$scope=group",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221102,
            "parent": 0,
            "order": 11,
            "location": "personamenu",
            "icon": "person_add",
            "label": "Users",
            "longname": "Users",
            "url": "#/navigator?$scope=user",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221103,
            "parent": 0,
            "order": 12,
            "location": "personamenu",
            "icon": "topic",
            "label": "Enterprise Projects",
            "longname": "Projects Overview",
            "url": "#/navigator?$scope=project",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221104,
            "parent": 0,
            "order": 13,
            "location": "personamenu",
            "icon": "fact_check",
            "label": "My Work List",
            "longname": "My Assigned Work",
            "url": "#/navigator?$scope=user.{$user.id}&maximized=true",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221105,
            "parent": 0,
            "order": 14,
            "location": "personamenu",
            "icon": "backup",
            "label": "Import Blueprint",
            "longname": "Import Blueprint Data",
            "url": "#/Power-Import-Wizard-eob?mode=eob",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221106,
            "parent": 0,
            "order": 15,
            "location": "personamenu",
            "icon": "drive_folder_upload",
            "label": "Import Project",
            "longname": "Import Project",
            "url": "#/Power-Import-Wizard-pmo?mode=pmo",
            "isInternal": false,
            "persona": "EOTM"
        },
        {
            "id": 221108,
            "parent": 0,
            "order": 2,
            "location": "personamenu",
            "icon": "cloud",
            "label": "Infrastruture Topology",
            "longname": "Import Blueprint",
            "url": "#/navigator?$scope=Infratopology",
            "isInternal": false,
            "persona": "APPM"
        },
        {
            "id": 221109,
            "parent": 0,
            "order": 4,
            "location": "personamenu",
            "icon": "topic",
            "label": "My Projects",
            "longname": "Projects Overview",
            "url": "#/navigator?$scope=project",
            "isInternal": false,
            "persona": "APPM"
        }
    ]
}
