import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-window-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

    @Input() error: any;
    stacktrace: string;
    codeSnippet: string;
}
