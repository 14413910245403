import { Fetch } from "src/app/services/fetch.service";
import { EventType } from 'src/dto/pmo/eventtype';
import { User_v1 } from "src/dto/eom/user-v1";
import { TenantService } from 'src/app/services/tenant.service';
import DOMPurify from 'dompurify';
import { DynatraceEnvironment_v1 } from "src/dto/eob/dynatrace-environment-v1";

export type TypeConstraint = {
    dto: string,
    prop?: string,
    value?: string
}

export type SubtypeConstraint = {
    value: string,
    label?: string,
    matIcon?: string,
    parent?: boolean | TypeConstraint[] | { dto: string, type: object },
    forceDto?: string
}


export const RagMap = {
    "red":   0,
    "amber": 1,
    "green": 2,
    "gray":  3
}

export const OrganizationTypes: SubtypeConstraint[] = [
    {
        value: "COMPANY",
        parent: false
    },
    {
        value: "DIVISION",
        parent: [{ dto: "Organization_v1", prop: "orgType", value: "COMPANY" }]
    },
    {
        value: "DEPARTMENT",
        parent: [{ dto: "Organization_v1", prop: "orgType", value: "DIVISION" }]
    },
    {
        value: "TEAM",
        parent: [{ dto: "Organization_v1", prop: "orgType", value: "DEPARTMENT" }]
    }
];

export const DynatraceEventTypes: SubtypeConstraint[] = [
    {
        value: "PRIVATE_VALUE_EVENT",
        label: "Private Value Event",
        parent: false,
        forceDto: "DynatraceEvent_v1",
        matIcon: "energy_savings_leaf"
    },
    {
        value: "PUBLIC_VALUE_EVENT",
        label: "Public Value Event",
        parent: false,
        forceDto: "DynatraceEvent_v1",
        matIcon: "savings"
    },
    {
        value: "DYNATRACE_VALUE_EVENT",
        label: "Dynatrace Value Event",
        parent: false,
        forceDto: "DynatraceEvent_v1",
        matIcon: "savings"
    },
    {
        value: "SAAS_CLUSTER_RELEASE",
        label: "SaaS Cluster Release",
        parent: false,
        forceDto: "DynatraceEvent_v1",
        matIcon: "new_releases"
    },
    {
        value: "MANAGED_CLUSTER_RELEASE",
        label: "Managed Cluster Release",
        parent: false,
        forceDto: "DynatraceEvent_v1",
        matIcon: "new_releases"
    }
];

export const MeetingEventTypes: SubtypeConstraint[] = [
    {
        value: "CALENDAR_EVENT",
        label: "Calendar Event",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "event",
    },
    {
        value: "MEETING_EVENT",
        label: "Meeting",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "perm_contact_calendar",
    },
    {
        value: "QUARTERLY_BUSINESS_REVIEW",
        label: "Quarterly Business Review",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "business_center",
    },
    {
        value: "VELOCITY_SERVICES_EVENT",
        label: "Velocity Services Event",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "auto_awesome",
    },
    {
        value: "ESA_SERVICES_EVENT",
        label: "ESA Services Event",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "architecture",
    },
    {
        value: "EPM_SERVICES_EVENT",
        label: "EPM Services Event",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "work_history",
    },
    {
        value: "PROJECT_MEETING",
        label: "Project Meeting",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1", prop: "subType", value: "PROJECT" }
        ],
        forceDto: "Meeting_v1",
        matIcon: "table_bar",
    },
    {
        value: "TRAINING_SESSION",
        label: "Training Session",
        parent: false,
        forceDto: "Meeting_v1",
        matIcon: "school"
    }
];

export const PlanningEventTypes: SubtypeConstraint[] = [
    {
        value: "PROJECT",
        parent: false,
        forceDto: "Project_v1"
    },
    {
        value: "GOAL",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1", prop: "subType", value: "PROJECT" }
        ],
        forceDto: "Goal_v1"
    },
    {
        value: "OBJECTIVE",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1", prop: "subType", value: "GOAL" }
        ],
        forceDto: "Objective_v1"
    },
    {
        value: "COVERAGE_GOAL",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1", prop: "subType", value: "PROJECT" }
        ],
        forceDto: "CoverageGoal_v1"
    },
    {
        value: "COVERAGE_OBJECTIVE",
        parent: [
            { dto: "CoverageGoal_v1" },
            { dto: "Event_v1", prop: "subType", value: "COVERAGE_GOAL" }
        ],
        forceDto: "CoverageObjective_v1"
    }
];

export const TaskEventTypes: SubtypeConstraint[] = [
    {
        value: "TASK",
        parent: [
            { dto: "Objective_v1" },
            { dto: "Event_v1", prop: "subType", value: "OBJECTIVE" },
            { dto: "Task_v1" },
            { dto: "Event_v1", prop: "subType", value: "TASK" }
        ],
        // TODO descriptionTemplate: "",
        forceDto: "Task_v1"
    },
    {
        value: "COVERAGE_TASK",
        parent: [
            { dto: "CoverageObjective_v1" },
            { dto: "Event_v1", prop: "subType", value: "COVERAGE_OBJECTIVE" }
        ],
        forceDto: "CoverageTask_v1"
    }
];

export const NonTaskEventTypes: SubtypeConstraint[] = [
    {
        value: "ISSUE",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1_v1", prop: "subType", value: "PROJECT" }
        ],
        forceDto: "Issue_v1"
    },
    {
        value: "RISK",
        parent: [
            { dto: "Project_v1" },
            { dto: "Event_v1_v1", prop: "subType", value: "PROJECT" }
        ],
        forceDto: "Risk_v1"
    }
];

export type EventAttachmentType = number;
export const EventAttachmentType = {
    FILE: 1,
    NEWS: 9,
    QUESTIONNAIRE: 10,
    RESPONSE: 11,
    FEEDBACK: 12,
    POST: 13,
    DOCUMENTATION: 14,

    1: "FILE",
    9: "NEWS",
    10: "QUESTIONNAIRE",
    11: "RESPONSE",
    12: "FEEDBACK",
    13: "POST",
    14: "DOCUMENTATION",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}


export type  AttachmentType = number;
export const AttachmentType = {
    ASSIGNMENT: 1,
    FILTER: 2,
    LAYOUT: 3,
    FAVORITE: 4,
    FILE: 5,
    ASSOCIATION: 6,
    PREFERENCES: 7,
    AUDIENCE: 8,
    CONTENT: 9,
    DEPENDENCY: 10,
    SUBSCRIPTION: 11,
    CONTRIBUTION: 12,

    1: "ASSIGNMENT",
    2: "FILTER",
    3: "LAYOUT",
    4: "FAVORITE",
    5: "FILE",
    6: "ASSOCIATION",
    7: "PREFERENCES",
    8: "AUDIENCE",
    9: "CONTENT",
    10: "DEPENDENCY",
    11: "SUBSCRIPTION",
    12: "CONTRIBUTION",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}

export type  ReferenceType = number;
export const ReferenceType = {
    EVENT: 3,
    DYNATRACE_CLUSTER: 1,
    DYNATRACE_ENVIRONMENT: 2,
    LOGICAL_ENVIRONMENT: 4,
    DYNATRACECLUSTER: 1,
    DYNATRACEENVIRONMENT: 2,
    LOGICALENVIRONMENT: 4,
    APPLICATION: 5,
    GROUP: 6,
    USER: 7,
    COSTCENTER: 8,
    FOOTPRINT: 9,
    NETWORKZONE: 10,
    LOCATION: 11,
    REGION: 12,
    PROVIDER: 13,
    OBSERVABILITY_ALLOCATION: 14,
    OBSERVABILITY_CLAIM: 15,
    OBSERVABLE_INVENTORY_ITEM: 16,
    OBSERVABILITYALLOCATION: 14,
    OBSERVABILITYCLAIM: 15,
    OBSERVABLEINVENTORYITEM: 16,
    ORGANIZATION: 17,
    PORTFOLIO: 18,
    RATECARD: 19,
    RATEGROUP: 20,
    PROJECT: 21,
    GOAL: 22,
    OBJECTIVE: 23,
    TASK: 24,
    COVERAGE_GOAL: 25,
    COVERAGE_OBJECTIVE: 26,
    COVERAGE_TASK: 30,
    ISSUE: 27,
    RISK: 28,
    PLATFORM: 29,
    RESOLVEDUSERS: 33,
    MEETING: 34,
    DYNATRACE_EVENT: 35,

    1: "DYNATRACE_CLUSTER",
    2: "DYNATRACE_ENVIRONMENT",
    3: "EVENT",
    4: "LOGICAL_ENVIRONMENT",
    5: "APPLICATION",
    6: "GROUP",
    7: "USER",
    8: "COSTCENTER",
    9: "FOOTPRINT",
    10: "NETWORKZONE",
    11: "LOCATION",
    12: "REGION",
    13: "PROVIDER",
    14: "OBSERVABILITY_ALLOCATION",
    15: "OBSERVABILITY_CLAIM",
    16: "OBSERVABLE_INVENTORY_ITEM",
    17: "ORGANIZATION",
    18: "PORTFOLIO",
    19: "RATECARD",
    20: "RATEGROUP",
    21: "PROJECT",
    22: "GOAL",
    23: "OBJECTIVE",
    24: "TASK",
    25: "COVERAGE_GOAL",
    26: "COVERAGE_OBJECTIVE",
    27: "ISSUE",
    28: "RISK",
    29: "PLATFORM",
    30: "COVERAGE_TASK",
    33: "RESOLVEDUSERS",
    34: "MEETING",
    35: "DYNATRACE_EVENT",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}

export interface Attachment extends DTO {
    source: number;
    sourceType: ReferenceType;
    sourceName?: string;
    target: number;
    targetType: ReferenceType;
    targetName?: string;
    type: AttachmentType;
    role?: number;
    data: string;
}

export interface EventAttachment extends DTO {
    referenceType?: ReferenceType;
    referenceId?: number;
    reference?: string;
    type?: EventAttachmentType;
    data?: string;
    status: string; // draft, published
    title?: string;
    rank?: number;
    publicationStartDate?: Date;
    publicationEndDate?: Date;
}

export const RaciRoles = [
    { value: 128, label: "Accountable" },
    { value: 16, label: "Responsible" },
    { value: 5, label: "Consulted & Informed" },
    { value: 4, label: "Consulted" },
    { value: 1, label: "Informed" },
]

export const AccessLevels = [
    { value: 0, label: "Revoke",     minLevel: 4 },
    { value: 1, label: "Read Only",  minLevel: 4 },
    { value: 2, label: "Limited",    minLevel: 2 },
    { value: 4, label: "Read Write", minLevel: 4 },
    { value: 8, label: "Owner",      minLevel: 8 },
];

export const AccessLevel = {
    0:  { label: "REVOKED",    icon: 'access-revoke.svg' },
    1:  { label: "READ-ONLY",  icon: 'access-read-only.svg' },
    2:  { label: "LIMITED",    icon: 'access-limited.svg' },
    4:  { label: "READ/WRITE", icon: 'access-read-write.svg' },
    8:  { label: "OWNER",      icon: 'access-owner.svg' },
    16: { label: "ADMIN",      icon: 'access-admin.svg' },
    REVOKED:   0,
    READONLY:  1,
    LIMITED:   2,
    READWRITE: 4,
    OWNER:     8,
    ADMIN:    16
};

export interface Assignment {
    role: number;
    raci: string;
    id:   number;
    type: ReferenceType;
    name: string;
    onBehalfOf?: number[];
}

export type ChargebackKPIs = {
    type: "projected" | "actual";
    value?: number;
    change?: number;
    date?: string;
    history?: number[];
}
export type CoverageKPI = {
    itemType: string;       // Item type, e.g. HOST, CONTAINER, LPAR, etc.
    managed: number;        // Number of managed entries in the OI
    monitored: number;      // Number of monitored entries in the OI
    pctManaged?: number;    // % of managed entries in the OI
    pctMonitored?: number;  // % of monitored entries in the OI
    unmonitored?: number;   // Number of managed entries not monitored
    unmanaged?: number;     // Number of monitored but unmanaged entries
}
export type SyncInventoryKPI = {
    // TBD
}
export type SyncSummaryKPI = {
    syncDate: Date;
    newProviders?: number;
    newRegions: number;
    newLocations: number;
    newNetworkZones: number;
    newApplications: number;
    newFootprints:   number;
    newEnvironments: number;
    newEntities: number; // This is across all Dynatrace Environments
    knownProviders?: number;
    knownRegions?: number;
    knownLocations?: number;
    knownNetworkZones?: number;
    knownApplications?: number;
    knownFootprints?: number;
    knownEnvironments?: number;
    knownEntities?: number; // This is across all Dynatrace Environments
    foundIn?: DynatraceEnvironment_v1[];
}
export type CoverageKPIs = {
    type: "coverage";
    date?: string;
    values?: CoverageKPI[] | { [key: string]: CoverageKPI };
}
export type ProgressKPIs = {
    type: "progress";
    startDate?: string;
    endDate?: string;
    completedDate?: string;
    hardEnd?: string;
    effort?: string;
    progress?: number;
    status?: string;
    priority?: number;
    rag?: string;
    duration?: number;
    durationType?: string;
    durationUnit?: string;
    children?: number;
    assigned?: number;
}
export type EventKPIs = {
    type: "tasks" | "issues" | "risks";
    responsible?: number;
    accountable?: number;
    consulted?: number;
    informed?: number;
    unassigned?: number;
    overdue?: number;
    duesoon?: number;
    scheduled?: number;
    red?: number;
    amber?: number;
    green?: number;
    grey?: number;
    total?: number;
    prio1?: number;
    prio2?: number;
    prio3?: number;
    prio4?: number;
    prio5?: number;
}
export type NoteKPIs = {
    lastTime?: number;
    ageOfLast?: number;
    lastAuthor?: string;
    recentCount?: number;
}
export type KPIData = {
    kpi_tasks_assigned?: EventKPIs;
    kpi_tasks_associated?: EventKPIs;
    kpi_tasks_contained?: EventKPIs;
    kpi_risks_assigned?: EventKPIs;
    kpi_risks_associated?: EventKPIs;
    kpi_risks_contained?: EventKPIs;
    kpi_issues_assigned?: EventKPIs;
    kpi_issues_associated?: EventKPIs;
    kpi_issues_contained?: EventKPIs;
    kpi_progress?: ProgressKPIs;
    kpi_coverage?: CoverageKPIs;
    kpi_chargeback_actual?: ChargebackKPIs;
    kpi_chargeback_projected?: ChargebackKPIs;
    kpi_sync_summary?: SyncSummaryKPI;
    kpi_notes?: NoteKPIs;
}

export interface DTO {
    id: number;
    parentId?: number;
    idx?: string;
    name: string;
    dto: string;
    roots?: DTORef[];
    refType?: number;
    edtCode?: string;
    icon?: string;
    description?: string;
    tags?: Tag[];
    /**
     * Holds the parties assigned to this asset, with permission and RACI role.
     */
    assignedTo?: Attachment[];
    /**
     * Holds the assets to which this party has been assigned access.
     */
    assignments?: Attachment[];
    /**
     * Holds the blueprint assets associated with (i.e. relevant for) this asset.
     */
    associations?: Attachment[];
    topics?: Attachment[];
    privacy?: number;
    access?: number;
    createdOn?: Date;
    createdBy?: string;
    updatedOn?: Date;
    updatedBy?: string;
    lockedOn?: Date;
    lockedBy?: string;
    kpiData?: KPIData;
    email?: string;
    displayName?: string;
}

export interface DTORef extends DTO {}
export interface PartialDTO {
    id: number;
    type?: number;
    [key: string]: Date | string | number | boolean | null | DTORef;
}

export interface Note {
    id: number;
    parentId?: number;
    hasReplies?: boolean;
    name?: string;
    description: string;
    privacy: number;
    referenceType: ReferenceType;
    referenceId: number;
    authorId?: number;
    authorName?: string;
    authorEmail?: string;
    enteredDate?: Date;
}
export interface PartialNote {
    id?: number;
    parentId?: number;
    referenceId?: number;
    privacy: number;
    description: string;
}

export interface Tag { name: string; value: string; actual?: string; }
export interface TagForEditor {
    name: string;
    value: string;
    actual?: string;
    originalTag?: Tag;

    values:    string[];

    isNew?:    boolean;
    isDelete?: boolean;
    hasChanged?: boolean;
    isValid?:  boolean;
    isSaving?: boolean;
    hasError?: boolean;

    error?: string;
}

export class RestAPI {
    constructor(private fetch: Fetch) {}

    get<T>(endpoint: string): Promise<T> {
        return this.fetch.get(endpoint).then(result => {
            window.assetCache.storeAsset(result as any)
            return result as T;
        });
    }

    post<T>(endpoint: string, data: any): Promise<T> {
        return this.fetch.post(endpoint, data).then(result => {
            if (!Array.isArray(result)) {
                window.assetCache.storeAsset(result as any)
            }
            return result as T;
        });
    }

    put<T>(endpoint: string, data: any): Promise<T> {
        return this.fetch.put(endpoint, data).then(result => {
            if (!Array.isArray(result)) {
                window.assetCache.storeAsset(result as any)
            }
            return result as T;
        });
    }

    patch<T>(endpoint: string, data: any): Promise<T> {
        return this.fetch.patch(endpoint, data).then(result => {
            if (!Array.isArray(result)) {
                window.assetCache.storeAsset(result as any)
            }
            return result as T;
        });
    }

    delete<T>(endpoint: string): Promise<T> {
        return this.fetch.delete(endpoint).then(result => {
            if (!Array.isArray(result)) {
                window.assetCache.removeAsset(result as any)
            }
            return result as T;
        });
    }
}

export class AttachmentAPI {
    private attEndpoint = "/api/eda/v1.0/attachment";

    /**
     * Add a new attachment of the given type to the DTO.
     *
     * @param {Attachment} att - The attachment.
     * @returns {Promise<Attachment>} The created attachment.
     */
    create(att: Attachment): Promise<Attachment>;
    /**
     * Add attachments of the given type to the DTO.
     *
     * @param {Attachment[]} atts - The attachments.
     * @returns {Promise<Attachment[]>} The created attachments.
     */
    create(atts: Attachment[]): Promise<Attachment[]>;
    /** @ignore */
    create(atts: any): Promise<any> {
        if (!atts || atts.length === 0) return new Promise((res, rej) => res([]));

        (Array.isArray(atts) ? atts : [atts]).forEach(att => {
            // We can't have 'null' in the value of a dropdown,
            // so we used -1 where we really meant 'null'.
            if (att.access === -1) att.access = 255;
        });

        return Array.isArray(atts)
            ? DTO.http.post(`${this.attEndpoint}/bulk`, atts, undefined, true)
            : DTO.http.post(`${this.attEndpoint}`, atts, undefined, true);
    }

    /**
     * Update an attachment for the DTO.
     *
     * @param {Attachment} att - The attachment.
     * @returns {Promise<Attachment>} The updated attachment.
     */
    update(att: Attachment): Promise<Attachment>;
    /**
     * Update attachments for the DTO.
     *
     * @param {Attachment[]} atts - The attachments.
     * @returns {Promise<Attachment[]>} The updated attachments.
     */
    update(atts: Attachment[]): Promise<Attachment[]>;
    /** @ignore */
    update(atts: any): Promise<any> {
        if (!atts || atts.length === 0) return new Promise((res, rej) => res([]));

        return Array.isArray(atts)
            ? DTO.http.patch(`${this.attEndpoint}/bulk`, atts, undefined, true)
            : DTO.http.patch(`${this.attEndpoint}/${atts.id}`, atts, undefined, true);
    }

    /**
     * Delete an attachment for the DTO.
     *
     * @param {number} attId - The attachment's ID.
     * @returns  {Promise<boolean>} The confirmation.
     */
    delete(attId: number): Promise<boolean>;
    /**
     * Delete attachments for the DTO.
     *
     * @param {number[]} attIdList - The attachments's IDs.
     * @returns  {Promise<boolean>} The confirmation.
     */
    delete(attIdList: number[]): Promise<boolean>;
    /** @ignore */
    delete(attIds: any): Promise<boolean> {
        if (!attIds || attIds.length === 0) return new Promise((res, rej) => res(true));

        return Array.isArray(attIds)
            ? DTO.http.delete(`${this.attEndpoint}/${attIds.join(',')}`, undefined, true)
            : DTO.http.delete(`${this.attEndpoint}/${attIds}`, undefined, true);
    }
}

export class EventAttachmentAPI {
    private attEndpoint = "/api/eda/v1.0/event-attachment-v1";

    /**
     * Add a new attachment of the given type to the DTO.
     *
     * @param {EventAttachment} att - The attachment.
     * @returns {Promise<EventAttachment>} The created attachment.
     */
    create(att: EventAttachment): Promise<EventAttachment>;
    /**
     * Add attachments of the given type to the DTO.
     *
     * @param {EventAttachment[]} atts - The attachments.
     * @returns {Promise<EventAttachment[]>} The created attachments.
     */
    create(atts: EventAttachment[]): Promise<EventAttachment[]>;
    /** @ignore */
    create(atts: any): Promise<any> {
        if (!atts || atts.length === 0) return new Promise((res, rej) => res([]));

        (Array.isArray(atts) ? atts : [atts]).forEach(att => {
            // We can't have 'null' in the value of a dropdown,
            // so we used -1 where we really meant 'null'.
            if (att.access === -1) att.access = null;
        });

        return Array.isArray(atts)
            ? DTO.http.post(`${this.attEndpoint}/bulk`, atts, undefined, true)
            : DTO.http.post(`${this.attEndpoint}`, atts, undefined, true);
    }

    /**
     * Update an attachment for the DTO.
     *
     * @param {EventAttachment} att - The attachment.
     * @returns {Promise<EventAttachment>} The updated attachment.
     */
    update(att: EventAttachment): Promise<EventAttachment>;
    /**
     * Update attachments for the DTO.
     *
     * @param {EventAttachment[]} atts - The attachments.
     * @returns {Promise<EventAttachment[]>} The updated attachments.
     */
    update(atts: EventAttachment[]): Promise<EventAttachment[]>;
    /** @ignore */
    update(atts: any): Promise<any> {
        if (!atts || atts.length === 0) return new Promise((res, rej) => res([]));

        return Array.isArray(atts)
            ? DTO.http.patch(`${this.attEndpoint}/bulk`, atts, undefined, true)
            : DTO.http.patch(`${this.attEndpoint}/${atts.id}`, atts, undefined, true);
    }

    /**
     * Delete an attachment for the DTO.
     *
     * @param {number} attId - The attachment's ID.
     * @returns  {Promise<boolean>} The confirmation.
     */
    delete(attId: number): Promise<boolean>;
    /**
     * Delete attachments for the DTO.
     *
     * @param {number[]} attIdList - The attachments's IDs.
     * @returns  {Promise<boolean>} The confirmation.
     */
    delete(attIdList: number[]): Promise<boolean>;
    /** @ignore */
    delete(attIds: any): Promise<boolean> {
        if (!attIds || attIds.length === 0) return new Promise((res, rej) => res(true));

        return Array.isArray(attIds)
            ? DTO.http.delete(`${this.attEndpoint}/${attIds.join(',')}`, undefined, true)
            : DTO.http.delete(`${this.attEndpoint}/${attIds}`, undefined, true);
    }
}

export class TagAPI {
    private tagEndpoint = "/api/eda/v1.0/tags";

    select(): Promise<Tag[] | TagForEditor[]>;
    select(dtoName: string): Promise<Tag[]>;
    select(asset: DTO): Promise<Tag[]>;
    select(scope?: any) {
        if (!scope)
            return DTO.http.get(`${this.tagEndpoint}`, undefined, true);

        if (typeof scope == "string")
            return DTO.http.get(`${this.tagEndpoint}/${scope}`, undefined, true);

        const meta = DTO.getMetaData(scope.dto);
        const type = meta._type.replace("-", "_").toUpperCase();

        return DTO.http.get(`${this.tagEndpoint}/${type}/${scope.id}`, undefined, true);
    }

    async create(asset: DTO | DTO[], tag: Tag): Promise<boolean> {
        if (!tag) return new Promise((res, rej) => res(false));

        const assets = Array.isArray(asset) ? asset : [asset];
        const meta   = DTO.getMetaData(assets[0]?.dto);
        const type   = meta._type.replace("-", "_").toUpperCase();
        const iden   = assets.map(a => a.id).join(',');
        // Yep - a PUT.
        try {
            await DTO.http.put(`${this.tagEndpoint}/${type}/${iden}`, tag, undefined, true);

            assets.forEach(asset => {
                asset.tags = asset.tags || [];
                const prev = asset.tags.findIndex(t => t.name == tag.name);
                if (prev >= 0) asset.tags.splice(prev, 1);
                asset.tags.push({...tag});
            });
            return true;
        }
        catch (ex) {
            return false;
        }
    }

    update(asset: DTO | DTO[], tag: Tag): Promise<boolean> {
        return this.create(asset, tag);
    }

    async delete(asset: DTO | DTO[], tagName: string): Promise<boolean> {
        if (!tagName) return new Promise((res, rej) => res(false));

        const assets = Array.isArray(asset) ? asset : [asset];
        const meta = DTO.getMetaData(assets[0]?.dto);
        const type = meta._type.replace("-", "_").toUpperCase();
        const iden = assets.map(a => a.id).join(',');

        try {
            await DTO.http.delete(`${this.tagEndpoint}/${type}/${iden}/${tagName}`, undefined, true);

            assets.forEach(asset => {
                asset.tags = asset.tags || [];
                const prev = asset.tags.findIndex(t => t.name == tagName);
                if (prev >= 0) asset.tags.splice(prev, 1);
            });
            return true;
        }
        catch (ex) {
            return false;
        }
    }
}

export class NotesAPI {
    private assetType: string;
    private referenceType: ReferenceType;
    private notesEndpoint = "/api/eda/v1.0/notes";

    constructor(dtoName: string) {
        this.assetType = getDTOTypeName(dtoName);
        this.referenceType = ReferenceType[this.assetType.toUpperCase()];
    }

    select(assetId: number): Promise<Note[]> {
        // API: "/notes/{assetType}/{assetId}"
        return DTO.http.get(`${this.notesEndpoint}/${this.assetType}/${assetId}`, undefined, true);
    }
    create(assetId: number, data: PartialNote): Promise<Note> {
        const note: Note = {
            id: -1,
            referenceId: assetId,
            referenceType: this.referenceType,
            parentId: data.parentId,
            privacy: data.privacy,
            description: data.description
        /*  Handled in controller
            authorId?: number;
            authorName: string;
            authorEmail: string;
            enteredDate: Date;
         */
        }
        return DTO.http.post(this.notesEndpoint, note, undefined, true);
    }
    update(assetId: number, note: Note): Promise<Note> {
        if (note.referenceId !== assetId) throw new Error("Mismatching asset IDs on note");
        return DTO.http.patch(`${this.notesEndpoint}/${note.id}`, note, undefined, true);
    }
    delete(id: number, noteId: number): Promise<Note> {
        return DTO.http.delete(`${this.notesEndpoint}/${noteId}`, undefined, true);
    }
}

export class DTOEndpointAPI {
    constructor(
        private dtoRegistry: DTORegistry
    ) { }


    private parseJSONizedProperties(assetOrList) {
        function getRandomEventKPIs(type: "tasks" | "risks" | "issues"): EventKPIs {
            const total = Math.round(100 * Math.random());

            return {
                type,
                responsible: 0,
                accountable: 0,
                consulted: 0,
                informed: 0,
                unassigned: Math.round(0.2 * total),
                overdue: Math.round(0.2 * total),
                duesoon: Math.round(0.3 * total),
                scheduled: Math.round(0.5 * total),
                red: Math.round(0.2 * total),
                amber: Math.round(0.3 * total),
                green: Math.round(0.4 * total),
                grey: Math.round(0.1 * total),
                total: total,
                prio1: Math.round(0.1 * total),
                prio2: Math.round(0.3 * total),
                prio3: Math.round(0.5 * total),
                prio4: 0,
                prio5: 0,
            }
        }
        function getRandomNoteKPIs(): NoteKPIs {
            const ageInHours = Math.round(72 * Math.random());

            return {
                lastTime: new Date().getTime() - (ageInHours * 60 * 60 * 1000),
                ageOfLast: ageInHours,
                lastAuthor: "Author " + ageInHours,
                recentCount: Math.round(100 * Math.random())
            }
        }
        const assetList = Array.isArray(assetOrList) ? assetOrList : [assetOrList];

        let date: Date;
        for (const asset of assetList) {
            if (!asset) continue;

            // Parse any dates.
            for (const prop in asset) {

                // If the property is roots and we're looking at an event, we
                // will reconstruct the roots property to make sure the following is true
                //  - the order of `roots` is correct
                //  - there are no null or missing root entries
                if (prop == "roots" && typeof asset['eventType'] == 'number') {
                    const roots = asset[prop] as DTO[];
                    const newRoots = [] as DTO[];

                    // under roots, the project will be the last element
                    let parentId = asset.parentId;
                    for (let i = 0; roots && i < roots.length; i++) {
                        let parent = roots.find(r => r.id == parentId);

                        // If the roots end prematurely we will exit
                        // before adding it to the newRoots list
                        if (!parent) break;

                        newRoots.push(parent);
                        parentId = parent.parentId;
                    }

                    // Apply the 'sorted' root list.
                    asset['roots'] = newRoots;
                }
                else if (typeof asset[prop] == "string" && asset[prop].endsWith('Z')) {
                    date = new Date(asset[prop]);
                    asset[prop] = date.valueOf() ? date : asset[prop];
                }
            }

            // Parse any KPIData if present and in string form.
            try {
                asset.kpiData = (
                    typeof asset.kpiData == "string" && asset.kpiData != ""
                        ? JSON.parse(asset.kpiData)
                        : asset.kpiData
                    ) || {};

                // The KPI object may be wrapped in an array by the database.
                const knownKPIs = [
                    "kpi_tasks_assigned", "kpi_issues_assigned", "kpi_risks_assigned",
                    "kpi_tasks_associated", "kpi_issues_associated", "kpi_risks_associated",
                    "kpi_tasks_contained", "kpi_issues_contained", "kpi_risks_contained",
                    "kpi_chargeback_actual", "kpi_chargeback_projected", "kpi_coverage",
                    "kpi_notes"];
                if (Object.keys(asset.kpiData[0] || {}).some(p => knownKPIs.includes(p))) {
                    asset.kpiData = asset.kpiData[0];
                    // Now it's no longer an array, but a 'modern' KPI object.
                }

                // Backwards compatibility - an array with one flat object with all kinds of properties.
                if (Array.isArray(asset.kpiData)) {
                    const kpiData = asset.kpiData[0];
                    asset.kpiData = {
                        kpi_tasks_associated: {
                            type: "tasks",
                            overdue:    kpiData.OverdueTasks,
                            duesoon:    kpiData.TasksDueInSevenDays,
                            scheduled:  kpiData.OpenTaskCount - kpiData.TasksDueInSevenDays - kpiData.OverdueTasks,
                            unassigned: kpiData.UnassignedTaskCount,
                            total:      kpiData.OpenTaskCount
                        },
                        kpi_issues_associated: {
                            type: "issues",
                            red:   kpiData.RedIssueCount || 0,
                            amber: kpiData.AmberIssueCount || 0,
                            green: kpiData.GreenIssueCount || 0,
                            grey:  kpiData.GreyIssueCount || 0,
                            total: (kpiData.RedIssueCount || 0) + (kpiData.AmberIssueCount || 0) + (kpiData.GreenIssueCount || 0) + (kpiData.GreyIssueCount || 0)
                        },
                        kpi_risks_associated: {
                            type: "risks",
                            red:   kpiData.RedRiskCount || 0,
                            amber: kpiData.AmberRiskCount || 0,
                            green: kpiData.GreenRiskCount || 0,
                            grey:  kpiData.GreyRiskCount || 0,
                            total: (kpiData.RedRiskCount || 0) + (kpiData.AmberRiskCount || 0) + (kpiData.GreenRiskCount || 0) + (kpiData.GreyRiskCount || 0)
                        },
                        kpi_notes: null
                    };
                }
                else {
                    if (!asset.kpiData) {
                        asset.kpiData = {};
                    }

                    // It's a 'modern' KPI object. All we need to do is set the 'type' property.
                    // NOTE: The KPITile component will add labels, initialize defaults, and so on.
                    if (asset.kpiData.kpi_tasks_assigned)    asset.kpiData.kpi_tasks_assigned.type    = "tasks";
                    if (asset.kpiData.kpi_issues_assigned)   asset.kpiData.kpi_issues_assigned.type   = "issues";
                    if (asset.kpiData.kpi_risks_assigned)    asset.kpiData.kpi_risks_assigned.type    = "risks";
                    if (asset.kpiData.kpi_tasks_associated)  asset.kpiData.kpi_tasks_associated.type  = "tasks";
                    if (asset.kpiData.kpi_issues_associated) asset.kpiData.kpi_issues_associated.type = "issues";
                    if (asset.kpiData.kpi_risks_associated)  asset.kpiData.kpi_risks_associated.type  = "risks";
                    if (asset.kpiData.kpi_tasks_contained)   asset.kpiData.kpi_tasks_contained.type   = "tasks";
                    if (asset.kpiData.kpi_issues_contained)  asset.kpiData.kpi_issues_contained.type  = "issues";
                    if (asset.kpiData.kpi_risks_contained)   asset.kpiData.kpi_risks_contained.type   = "risks";
                    if (asset.kpiData.kpi_chargeback_actual) asset.kpiData.kpi_chargeback_actual.type = "actual";
                    if (asset.kpiData.kpi_chargeback_projected) asset.kpiData.kpi_chargeback_projected.type = "projected";
                    if (asset.kpiData.kpi_coverage)          asset.kpiData.kpi_coverage.type          = "coverage";
                    if (asset.kpiData.kpi_notes)             asset.kpiData.kpi_notes.type             = "notes";
                }
            }
            catch (ex) {
                console.error(`Could not parse KPI data - ${ex.message}: '${JSON.stringify(asset.kpiData)}'`);
            }

            // Give DTORefs an icon. Note: sometimes it's just a single DTORef.
            DTO.getMetaData(asset.dto)._related.forEach(relatedProp => {
                let dtoRefs = asset[relatedProp.prop] || [];
                if (!Array.isArray(dtoRefs)) dtoRefs = [dtoRefs];
                dtoRefs.forEach(dtoRef => {
                    dtoRef.icon = dtoRef.icon || DTO.getMetaData(relatedProp.class)?._icon || './assets/dtos/missing-dto.svg';
                });
            });
        }
        return assetOrList;
    }


    get<T = DTO>(dtoName: string, id: number): Promise<T>;
    get<T = DTO>(dtoName: string, id: number, descendants: boolean): Promise<T[]>;
    get<T = DTO>(dtoName: string, id: number, mode: string): Promise<T[]>;
    get<T = DTO>(dtoName: string, idList: number[]): Promise<T[]>;
    get<T = DTO>(dtoName: string, criteria: URLSearchParams): Promise<T[]>;
    get<T = DTO>(dtoName: string, filter: string): Promise<T[]>;
    get(dtoName: string, p: any, descendantsOrMode?: boolean | string): any {
        const emptyResult = new Promise((res, rej) => res([]));

        if (!dtoName) return emptyResult;
        if (Array.isArray(p) && p.length == 0) return emptyResult;

        p = !p ? ""
          : Array.isArray(p) ? ("/" + p.join(","))
          : typeof p == "number" ? ("/" + p)
          : typeof p == "string" ? ("?$filter=" + p.toString())
          : ("?" + p.toString());

        const b = descendantsOrMode === true ? "/branch" : "";
        const mode = typeof descendantsOrMode === "string" ? `$mode=${descendantsOrMode}` : null;
        if (mode) p += p == "" ? `?${mode}` : `&${mode}`;

        const metadata: DTOMetaData = DTO.getMetaData(dtoName);
        return DTO.http.get(`${metadata._endpoint}${b}${p}`, undefined, true).then(this.parseJSONizedProperties);
    }

    post<T = DTO>(dtoName: string, data: T): Promise<T>;
    post<T = DTO>(dtoName: string, data: T[]): Promise<T[]>;
    post(dtoName: string, data: any): any {
        const metadata: DTOMetaData = DTO.getMetaData(dtoName);

        return Array.isArray(data)
            ? DTO.http.post(`${metadata._endpoint}/bulk`, data.map(asset => stripUnsafeProperties(asset)), undefined, true).then(this.parseJSONizedProperties)
            : DTO.http.post(`${metadata._endpoint}`, stripUnsafeProperties(data), undefined, true).then(this.parseJSONizedProperties);
    }

    put<T = DTO>(dtoName: string, id: number, data: T): Promise<T>;
    put<T = DTO>(dtoName: string, data: T[]): Promise<T[]>;
    put(dtoName: string, id: any, data?: any): any {
        const metadata: DTOMetaData = DTO.getMetaData(dtoName);

        return typeof id == "number"
            ? DTO.http.put(`${metadata._endpoint}/${id}`, stripUnsafeProperties(data), undefined, true).then(this.parseJSONizedProperties)
            : DTO.http.put(`${metadata._endpoint}/bulk`, stripUnsafeProperties(data), undefined, true).then(this.parseJSONizedProperties);
    }

    patch<T = DTO>(dtoName: string, id: number, data: PartialDTO): Promise<T>;
    patch<T = DTO>(dtoName: string, data: PartialDTO[]): Promise<T[]>;
    patch(dtoName: string, id: any, data?: any): any {
        const metadata: DTOMetaData = DTO.getMetaData(dtoName);

        if (!id) throw new Error("Cannot update property on unknown id");

        if (typeof id == "number") {
            data = stripUnsafeProperties(data);
            return DTO.http.patch(`${metadata._endpoint}/${id}`, data, undefined, true).then(this.parseJSONizedProperties);
        }
        else {
            data = id.map(data => stripUnsafeProperties(data));
            return DTO.http.patch(`${metadata._endpoint}/bulk`, data, undefined, true).then(this.parseJSONizedProperties);
        }
    }

    delete(dtoName: string, id: number): Promise<boolean>;
    delete(dtoName: string, idList: number[]): Promise<boolean>;
    delete(dtoName: string, ids: any): any {
        const metadata: DTOMetaData = DTO.getMetaData(dtoName);

        return typeof ids == "number"
            ? DTO.http.delete(`${metadata._endpoint}/${ids}`, undefined, true)
            : DTO.http.delete(`${metadata._endpoint}/${ids.join(',')}`, undefined, true);
    }
}

export class BoundEndpointAPI<T = DTO> {
    private unboundAPI: DTOEndpointAPI;
    private _notesAPI: NotesAPI;
    public get notesAPI() : NotesAPI {
        return this._notesAPI;
    }

    constructor(
        private dtoName: string,
        dtoRegistry: DTORegistry
    ) {
        this.unboundAPI = new DTOEndpointAPI(dtoRegistry);
        this._notesAPI = new NotesAPI(dtoName);
    }

    /**
     * Retrieve a DTO from the server with the given ID.
     *
     * @param id - ID of the DTO.
     * @returns {Promise<T>} A promise that resolves with a single DTO.
     */
    get(id: number): Promise<T>;
    /**
     * Retrieves DTOs from the server with the given IDs.
     *
     * @param idList - IDs of the requested DTOs.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    get(idList: number[]): Promise<T[]>;
    /**
     * Retrieve a DTO from the server with the given ID.
     *
     * @param id - ID of the DTO.
     * @param descendants - Retrieve the descendents of this DTO.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    get(id: number, descendants: boolean): Promise<T[]>;
     /**
     * Retrieve a collection of DTOs from the server that match the
     * given OData filter.
     *
     * @param filter - An OData query string.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    get(criteria: URLSearchParams): Promise<T[]>;
    /**
     * Retrieve a collection of DTOs from the server that have the
     * property values indicated in the criteria object.
     *
     * @param criteria - An object with properties and values.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    get(filter: string, mode?: string): Promise<T[]>;
    /** @ignore */
    get(p: any, descendantsOrMode?: boolean | string): Promise<T | T[]> {
        return this.unboundAPI.get(this.dtoName, p, descendantsOrMode as any);
    }

    /**
     * Create the provided DTO in the database.
     *
     * NOTE: The 'id' property will be ignored.
     *
     * @param {T} data - A DTO object to be created in the database.
     * @returns  {Promise<T>} The created DTO, with a new ID.
     * @memberof BoundEndpointAPI
     */
    post(data: T): Promise<T>;
    /**
     * Create the provided DTOs in the database.
     *
     * NOTE: The 'id' properties will be ignored.
     *
     * @param {T[]} data - The DTO objects to be created in the database.
     * @returns  {Promise<T[]>} The created DTOs, with new IDs.
     * @memberof BoundEndpointAPI
     */
    post(data: T[]): Promise<T[]>;
    /** @ignore */
    post(data: any): Promise<T | T[]> {
        return this.unboundAPI.post(this.dtoName, data);
    }

    /**
     * Replace the provided DTO in the database.
     *
     * @param {number} id - The ID of the DTO as stored in the database.
     * @param {T} data - The DTO object to be replaced in the database.
     * @returns  {Promise<T>} The replaced DTO.
     * @memberof BoundEndpointAPI
     */
    put(id: number, data: T): Promise<T>;
    /**
     * Replace the provided DTOs in the database.
     *
     * NOTE: The 'id' properties in the DTOs need to refer to the corresponding records in the database.
     *
     * @param {T[]} data - The DTO objects to be replaced in the database.
     * @returns  {Promise<T[]>} The replaced DTO.
     * @memberof BoundEndpointAPI
     */
    put(data: T[]): Promise<T[]>;
    /** @ignore */
    put(id: any, data?: any): Promise<T | T[]> {
        return this.unboundAPI.put(this.dtoName, id, data);
    }

    /**
     * Update the provided DTO in the database.
     *
     * NOTE: The DTO object should only have the properties that need to be updated.
     *
     * @param {number} id - The ID of the DTO as stored in the database.
     * @param {PartialDTO} data - The partial DTO object to be updated in the database.
     * @returns  {Promise<T>} The complete, updated DTO.
     * @memberof BoundEndpointAPI
     */
    patch(id: number, data: PartialDTO): Promise<T>;
    /**
     * Update the provided DTOs in the database.
     *
     * NOTE: The 'id' properties need to refer to the corresponding records in the database,
     * and the DTO objects should only have the properties that need to be updated.
     *
     * @param {PartialDTO[]} data - The partial DTO objects to be updated in the database.
     * @returns  {Promise<T[]>} The complete, updated DTOs.
     * @memberof BoundEndpointAPI
     */
    patch(data: PartialDTO[]): Promise<T[]>;
    /** @ignore */
    patch(id: any, data?: any): Promise<T | T[]> {
        return this.unboundAPI.patch(this.dtoName, id, data);
    }

    /**
     * Delete the DTO with the given ID in the database.
     *
     * @param {number} id - The ID of the DTO as stored in the database.
     * @returns  {Promise<boolean>}
     * @memberof BoundEndpointAPI
     */
    delete(id: number): Promise<boolean>;
    /**
     * Delete the DTOs with the given IDs in the database.
     *
     * @param {number[]} idList - The IDs of the DTOs as stored in the database.
     * @returns  {Promise<boolean>}
     * @memberof BoundEndpointAPI
     */
    delete(idList: number[]): Promise<boolean>;
    /** @ignore */
    delete(id: any): Promise<boolean> {
        return this.unboundAPI.delete(this.dtoName, id);
    }
}

export type DTOProperties = {
    prop:   string;   // property name to get the value back from the control
    label:  string;   // Human-readable name of the property.
    hint:   string;   // Assistive string to clarify what to select.
    type:   string;   // Data type of the property.
    hidden?: boolean; // Whether the property should be shown.
    fixed: boolean;   // Whether the value is fixed. E.g. a parent cannot be changed.
    source?: string;  // If the type is a DTO: where to get the DTOs than can be selected.
    depth?: number;   // Depth in DTO hierarchy.
    values?: any;     // If the type is an enum: the available values.
    default?: any;    // The default value if the value is not nullable.
    required?: boolean;  // Whether the property has to have a value.
    readonly?: boolean;  // Whether the value should be shown, but as read-only.
    isExportable?: boolean;
    isimportable?: boolean;
    relatedProp?: string; // Is there another prop that this one duplicates (e.e. parentId)?
}

export type KeySet = string | string[];
export type importSpec = string[];
export type ExportSpec = string[];

export type DTOMetaData = {
    /** @private */
    _label: string,
    /** @private */
    _dto: string,
    /** @private */
    _type: string,
    /** @private */
    _colors: { primaryThemeColor: string, secondaryThemeColor: string, tertiaryThemeColor: string },
    /** @private */
    _keysets?: KeySet[],
    /** @private */
    _properties: DTOProperties[],
    /** @private */
    _related?: {
        prop: string,
        type: string,
        class: string,
        label: string
    }[],
    /** @private */
    _endpoint: string,
    /** @private */
    _depth?: number,
    /** @private */
    _docLink?: string,
    /** @private */
    _prototype?: any,
    _childrenAt?: string,
    _parentDTONames?: string;
    _rootDTOName?: string;
    _icon?: string;
    _cssClass?: string;
}

export type DTOMethods<T = DTO> = {
    /**
     * Insert this DTO in the database.
     *
     * NOTE: The 'id' property will be ignored.
     *
     * @returns  {Promise<T>} The complete, updated DTO.
     */
    insert?: (version?: number) => Promise<T>;

    /**
     * Update the specified properties in this DTO in the database.
     *
     * @param {string[]} propList - The list of properties to update.
     * @returns  {Promise<T>} The complete, updated DTO.
     */
    update?:  (propList: string[], version?: number) => Promise<T>;

    /**
     * Replace this entire DTO in the database.
     *
     * @returns  {Promise<T>} The complete, updated DTO.
     */
    replace?: (version?: number) => Promise<T>;

    /**
     * Save this entire DTO to the database.
     *
     * NOTE: This method effectively acts as an "UPSERT" (merge).
     *
     * @param {string[] | "all"} propList - The list of properties to save. Default is "all".
     * @param {KeySet} keySet - The key(s) that make(s) this DTO unique. Default is "id".
     * @returns  {Promise<T>} The updated or newly created DTO.
     *
     * @description
     *  Because this DTO may or may not yet exist in the database, the
     *  caller may provide a keyset to specify how the corresponding
     *  record can be uniquely identified. If it exists, the DB updates
     *  the specified properties. If it doesn't, the DB performs an
     *  insert. When sucessful, the DB response is the full DTO with at
     *  least the 'id' property populated (the default keyset).
	 *
     *  Note that if a propList is provided, only those properties will
     *  be submitted (plus the key fields of course).
	 *
     *  This method uses a POST, but does not have the "create-and-fail-if-exists" behavior.
	 *
     *
     *  The following examples assume that the allowed keysets are "id",
     *  "email" and ["firstName", "lastName"].
     * @example
     * // Update one or more properties.
     * theUser.nickName = "The one and only";
     * theUser.address = "1 Sunset Boulevard";
     * // Save all props using 'id' as ID.
     * theUser.save();
     * // Save all props using 'id' as ID.
     * theUser.save("all");
     * // Save name and address using 'id' as ID.
     * theUser.save(["nickName", "address"]);
     * // Save name and address using 'id' as ID.
     * theUser.save(["nickName", "address"], "id");
     * // Save name and address using 'email' as ID.
     * theUser.save(["nickName", "address"], "email");
     * // Save name and address using 'firstName' plus 'lastName' as ID.
     * theUser.save(["nickName", "address"], ["firstName", "lastName"]);
     * // Throws ERROR: 'address' and 'city' are not allowed as keyset.
     * theUser.save(["nickName", "address"], ["address", "city"]);
     */
    save?:    (propList?: string[] | "all", keySet?: KeySet, version?: number) => Promise<T>;

    /**
     * Delete this DTO in the database.
     *
     * @returns  {Promise<boolean>}
     */
    delete?:  () => Promise<boolean>;

    /**
     * Retrieve the DTORef object(s) at the given property with
     * their/its respective complete DTO equivalent(s).
     *
     * @param {string} propName - The name of the property that contains one or more DTORefs.
     * @returns  {Promise<DTO[] | DTO>} The complete DTOs.
     */
    get?: (propName: string) => Promise<DTO[] | DTO>;
}

export type DTOClassMethods<T = DTO> = {
    /**
     * The bound (type-aware) DTO endpoint API.
     */
    endpointAPI: BoundEndpointAPI<T>,
    /**
     * Converts a plain object into a DTO with corresponding methods.
     * @param obj - The plain object.
     * @returns {T} The DTO-ified object.
     */
    from: (obj: any) => T,

    /**
     * Retrieve all DTOs from the server of the given type.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    select(): Promise<T[]>,
    /**
     * Retrieve a DTO from the server with the given ID.
     * @param id - ID of the DTO.
     * @returns {Promise<T>} A promise that resolves with a single DTO.
     */
    select(id: number): Promise<T>,
    /**
     * Retrieve the descendants of a DTO from the server with the given ID.
     * @param id - ID of the DTO.
     * @param descendants - ID of the DTO.
     * @returns {Promise<T>} A promise that resolves with a single DTO.
     */
    select(id: number, descendants: boolean): Promise<T[]>,
    /**
     * Retrieve a collection of DTOs from the server that have the
     * property values indicated in the criteria object.
     * @param criteria - An object with properties and values.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    select(criteria: URLSearchParams): Promise<T[]>,
    /**
     * Retrieve a collection of DTOs from the server that match the
     * given OData filter.
     * @param filter - An OData query string.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    select(filter: string/* TODO: Implement in class methods, mode?: string*/): Promise<T[]>,
    /** @ignore */
    select(p: any, descendants?: boolean): Promise<T | T[]>,

    /**
     * imports an array of objects into the database.
     * @param {object[]} data - An array of objects that adheres to the
     *                          standard import/export format.
     * @param {importSpec} spec - A definition of the hierarchy of objects,
     *                          the keysets and the properties to store for each.
     * @returns {Promise<boolean>}
     */
    //import(data: object[], spec: importSpec): Promise<boolean>,

    /**
     * Exports an array of objects from the database.
     * @param id - ID of the DTO.
     * @param spec - A definition of the hierarchy of objects,
     *               and the properties to export for each.
     * @returns {Promise<object[]>}
     */
    //export(id: number, spec: ExportSpec): Promise<object[]>,
    /**
     * Exports an array of objects from the database.
     * @param criteria - An object with properties and values.
     * @param spec - A definition of the hierarchy of objects,
     *               and the properties to export for each.
     * @returns {Promise<object[]>}
     */
    //export(criteria: URLSearchParams, spec: ExportSpec): Promise<object[]>,
    /**
     * Exports an array of objects from the database.
     * @param filter - An OData query string.
     * @param spec - A definition of the hierarchy of objects,
     *               and the properties to export for each.
     * @returns {Promise<object[]>}
     */
    //export(filter: string, spec: ExportSpec): Promise<object[]>,
    /** @ignore */
    //export(p: any, spec: ExportSpec): Promise<object[]>
}

export class DTORegistry {
    private registry = new Map<string, DTOMetaData>();
    public static http: Fetch;
    public get http() {
        return DTORegistry.http
    }
    public get dtoList() {
        return this.registry.keys();
    }

    public tagAPI = new TagAPI();
    public attachmentAPI = new AttachmentAPI();
    public eventAttachmentAPI = new EventAttachmentAPI();

    // TODO: Kill the distinct between RestAPI and Fetch.
    // Globally, there is only one: RestAPI.
    private static httpAPI: RestAPI;
    private static tenant: TenantService;
    public endpointAPI: DTOEndpointAPI = new DTOEndpointAPI(this);

    public static init(fetch: Fetch, tenant: TenantService) {
        this.http = fetch;
        this.httpAPI = new RestAPI(this.http);
        this.tenant = tenant;
    }

    register(data: DTOMetaData) {
        // Ensure everybody has a keyset, and for compound keys,
        // sort them for later easy comparison in 'DTO.save()'.
        if (!(data._keysets?.length > 0)) data._keysets = ["id"];
        data._keysets.forEach(keyset => {
            if (Array.isArray(keyset)) keyset = keyset.sort().join(",");
        });
        data._prototype = this.createBoundPrototype(data._dto),

        this.registry.set(data._dto, data);  // Application_v1
        this.registry.set(data._dto.toLocaleLowerCase(), data);  // application_v1
        this.registry.set(data._type, data); // application
    }

    createBoundPrototype<T>(dtoName: string): DTOMethods<T> {
        const api = new BoundEndpointAPI(dtoName, this);
        // const attachmentMeta = DTO.getMetaData("Attachment");

        return {
            get(propName: string): Promise<DTO[]> {
                // NOTE: We can look at the metadata of the props, or
                // even better, at the dto of each DTORef at the prop,
                // and then support multiple DTO types in the same list.
                // For now: Single type of DTO in a DTORef[] only.
                const metadata = DTO.getMetaData(this.dto);
                const propMeta = metadata._related.find(p => p.prop === propName);

                // If we don't get metadata back, the property is mismatched
                if (!propMeta)
                    throw new Error(propName + " does not exist on " + this.dto);

                const refDTOMeta =  DTO.getMetaData(propMeta.type);
                const dtoRefAPI = new BoundEndpointAPI(refDTOMeta._dto, this);
                const dtoList = this[propName] as DTORef[];
                const idList = dtoList?.map(dto => dto.id) || [];

                if (idList.length == 0)
                    return new Promise(r => r([]));

                return dtoRefAPI.get(`id in (${idList.join(",")})`);
            },
            insert<T>(version?: number): Promise<T> {
                return api.post(this) as Promise<T>;
            },
            update<T>(propList: string | string[], version?: number): Promise<T> {
                let that = stripUnsafeProperties(this);
                if (propList !== "all") {
                    // Clone the object because we don't want to mutate data in 'this'.
                    that = structuredClone(that);
                    for (let prop in that) {
                        if (prop.startsWith('_'))
                            delete that[prop];
                        if (!propList.includes(prop) && prop != 'id')
                            delete that[prop];
                    }
                }

                return api.patch(this.id, that) as Promise<T>;
            },
            replace<T>(version?: number): Promise<T> {
                return api.put(this.id, this) as Promise<T>;
            },
            delete(): Promise<boolean> {
                return api.delete(this.id);
            },
            save<T>(
                propList: string[] | "all"  = "all",
                keySet:  KeySet = "id",
                version:  number = 0
            ): Promise<T> {
                // Abort if this is already stored in the backend
                if (typeof this.id == "number")
                    throw new Error("Cannot 'save' ontop of an already existing DTO!");

                // It's easier if everything is an array.
                keySet = Array.isArray(keySet) ? keySet : [keySet];

                // To enable quick comparison, in the registry process we sorted
                // and joined any compound keys. We do the same for the provided
                // keylist so that we can equality-check if that value is valid.
                const compoundKey = keySet.sort().join(",");
                const metadata = DTO.getMetaData(this.dto);

                const isValidKeyset = metadata._keysets.includes(compoundKey);
                if (!isValidKeyset) throw new Error("Invalid key fields");

                const hasKeyValues  = keySet.every(key => typeof this[key] !== "undefined");
                if (!hasKeyValues)  throw new Error("Missing key values");


                let that = this;
                if (propList !== "all") {
                    // Clone the object because we don't want to destroy data in 'this'.
                    that = JSON.parse(JSON.stringify(this));
                    for (let prop in that) {
                        if (!propList.includes(prop) && !keySet.includes(prop))
                            delete that[prop];
                    }
                }
                return DTORegistry.http
                    .put(`${metadata._endpoint}?keys=${keySet.join(",")}&version=${version}`, that) as Promise<T>; // Add any error to the DTO.
            }
        }
    }

    getDTOAncestry(dtoName): string[] {
        /*
            If every DTO specifies what kinds of direct parent it has,
            then we can quickly collect a complete list of ancestor DTO names.

            Logical Env => [ 'Organization_v1', 'Application_v1,Platform_v1', 'Footprint_v1' ]
         */
        const dtoTrail = [];
        while (dtoName) {
            const metadata = this.getMetaData(dtoName);

            // No metadata or no parent DTO name or the parent DTO is the same as this one, we stop.
            if (!metadata || !metadata._parentDTONames || dtoName == metadata._parentDTONames?.split(',')[0]) {
                dtoName = null;
            }
            else {
                dtoTrail.push(metadata._parentDTONames);
                // A DTO may have multiple possible types of parents,
                // but we're assuming that each of _those_ have the
                // same type of parent. So, only use the first one
                // for the next iteration.
                dtoName = metadata._parentDTONames?.split(',')[0];
            }
        }
        return dtoTrail;
    }

    getMetaData(name?: string, rejectIfUnknown?: true): DTOMetaData;
    getMetaData(name?: string, rejectIfUnknown?: false): DTOMetaData | undefined;
    getMetaData(name?: string, rejectIfUnknown?: boolean): DTOMetaData | undefined {
        if (!name) return undefined;

        const metadata = this.registry.get(name)
                      || this.registry.get(name.toLowerCase())
                      || this.registry.get(name.toLowerCase().replace("_", "-"));
        if (!metadata && rejectIfUnknown) {
            throw new Error("Unknown asset type: " + name);
        }
        return metadata;
    }

    getReferenceType(name?: string): ReferenceType {
        const meta = this.getMetaData(name);
        const type = meta._type.replace("-", "_");
        return ReferenceType[type.toUpperCase()];
    }

    getDtoName(refType: ReferenceType): string {
        const name = ReferenceType[refType]?.toLowerCase();
        const meta = this.getMetaData(name);
        return meta._dto;
    }

    getUserFriendlyName(name: string): string {
        const metadata = this.registry.get(name || 'unknown');
        if (!metadata) return "Asset";

        const friendlyName: string[] = [];
        for (let i = 0; i < metadata._type.length; i++) {
            if (i == 0) {
                friendlyName.push(metadata._type[i].toUpperCase());
            }
            else if (metadata._type[i] == '-') {
                friendlyName.push(' ');
                i++;
                friendlyName.push(metadata._type[i].toUpperCase());
            }
            else {
                friendlyName.push(metadata._type[i]);
            }
        }

        return friendlyName.join("");
    }

    getUserIcon(user: string | User_v1, size: string = "48x48", classes: string[] = []) {
        let theUser: User_v1;

        if (!user) {
            return `<div class='profile-image ${classes.join(' ')}' title="(Unavailable)" data-id="-1"><img src="/assets/dtos/user.svg"/></div>`;
        }

        if (typeof user == "string") {
            const email = user.toLowerCase().trim();
            theUser = DTORegistry.tenant.users.value.find(u => u.email?.toLowerCase().trim() == email)
                || {
                id: -1,
                parentId: -1,
                idx: "-1",
                dto: "User_v1",
                icon: null,
                description: "System user",
                name: "System Admin",
                email: "elevate@dynatrace.com",
                title: "System user",
                status: "default",
                lastActive: new Date(),
                prefix: "",
                suffix: "",
                firstName: "System",
                lastName: "Admin",
                middleInitial: "",
                nickname: "",
                photo: "",
                persona: "Dynatrace Service Associate",
                personaCode: "DTSA",
                personaDescription: "Dynatrace Service Associate",
                organization: null,
                preferences: [],
                filters: [],
                favorites: [],
                layouts: [],
                subscriptions: []
            } as User_v1;
        }
        else {
            theUser = user;
        }

        const inits = theUser.name?.split(" ")?.map(o => o[0]?.toUpperCase()).join("") || theUser.email || "N/A";
        const title = DOMPurify.sanitize(theUser.name);

        let icon = theUser.email == "elevate@dynatrace.com"
            ? "/Images/male.png"
            : theUser.email?.endsWith("@dynatrace.com")
                ? DOMPurify.sanitize(`/api/azure/image/` + theUser.email + (size ? (`/` + size) : '' + `?initials=` + inits + `&v=` + (theUser.id % 10)))
                : DOMPurify.sanitize(theUser.photo);
        if (icon == "/Images/male.png" || icon == "/Images/female.png")
            icon = null; // Will become a generic user icon, below.

        const image = (icon && icon.length > 5)
            ? `<img src='${icon}'/>`
            : inits.length > 2
                ? `<div class="initials variant-${theUser.id % 10}">${inits}</div>`
            : `<img src="/assets/dtos/user.svg"/>`;

        return `<div class='profile-image ${classes.join(' ')}' title="${title}" data-id="${theUser.id}">${image}</div>`;
    }

    getAssetIcon(type: number | DTO, id?: number, size: string = "48x48", classes: string[] = []) {
        if (type == ReferenceType.USER) {
            const user = DTORegistry.tenant.users.value.find(u => u.id == id);
            if (!user)
                return `<div class='profile-image ${classes.join(' ')}' title="(Unknown)" data-id="${id}"><img src="/assets/dtos/user.svg"/></div>`;

            const inits = user.name.split(" ").map(o => o[0]?.toUpperCase()).join("") || "N/A";
            const title = DOMPurify.sanitize(user.name);
            const email = user.email?.toLowerCase().trim();
            const icon = email?.endsWith("@dynatrace.com")
                ? DOMPurify.sanitize(`/api/azure/image/` + email + (size ? (`/` + size) : '' + `?initials=` + inits + `&v=` + (user.id % 10)))
                : DOMPurify.sanitize(user.photo);

            const image = (icon && icon.length > 5)
                ? `<img src="${icon}"/>`
                : inits.length > 2
                ? `<div class="initials variant-${user.id % 10}">${inits}</div>`
                : `<img src="/assets/dtos/user.svg"/>`;

            return `<div class='profile-image ${classes.join(' ')}' title="${title}" data-id="${id}">${image}</div>`;
        }
        if (typeof type == "number") {
            const assetType = ReferenceType[type].toLowerCase();
            // TODO: Get image url from metadata
            const image = `<img src="/assets/dtos/${assetType.replace("_", "-")}.svg"/>`;
            // const style = type == ReferenceType.ORGANIZATION ? "border-radius: unset;" : "";
            const style = "border-radius: unset;";

            // TODO: the profile-image class in main.css should not use
            // the border-radius. Only users have that.
            // We need to unify all profile picture getting
            // and make everythign call this function here.
            return `<div class='profile-image ${classes.join(' ')}' style="${style}" title="${assetType.replace("_", " ") }" data-id="${id}">${image}</div>`;
        }
        else if (type.dto == "User_v1") {
            return this.getUserIcon(type as User_v1);
        }
        else {
            const icon = this.getDtoIcon(type);
            const image = `<img src="${icon}"/>`;
            const style = type.dto == "Organization_v1" ? "border-radius: unset;" : "";

            return `<div class='profile-image ${classes.join(' ')}' style="${style}" title="${type.name}" data-id="${id}">${image}</div>`;
        }
    }

    getDtoIcon(asset: DTO): string {
        // This is an event of some form.
        if (typeof asset['eventType'] == 'number') {
            return "/assets/dtos/" + {
                [EventType.SAAS_CLUSTER_RELEASE]: "task",
                [EventType.MANAGED_CLUSTER_RELEASE]: "task",
                [EventType.MILESTONE]: "milestone",
                [EventType.PRIVATE_VALUE_EVENT]: "task",
                [EventType.PUBLIC_VALUE_EVENT]: "task",
                [EventType.DYNATRACE_VALUE_EVENT]: "task",
                [EventType.QUARTERLY_BUSINESS_REVIEW]: "task",
                [EventType.VELOCITY_SERVICES_EVENT]: "task",
                [EventType.ESA_SERVICES_EVENT]: "task",
                [EventType.EPM_SERVICES_EVENT]: "task",
                [EventType.PROJECT_MEETING]: "task",
                [EventType.TRAINING_SESSION]: "task",

                [EventType.OBJECTIVE]: "objective",
                [EventType.COVERAGE_OBJECTIVE]: "objective",
                [EventType.GOAL]: "goal",
                [EventType.COVERAGE_GOAL]: "goal",
                [EventType.TASK]: "task",
                [EventType.COVERAGE_TASK]: "task",
                [EventType.PROJECT]: "project",
                [EventType.ISSUE]: "issue",
                [EventType.RISK]: "risk",
            }[asset['eventType']] + ".svg";
        }
        // This is some other asset.
        else {
            return "/assets/dtos/" + {
                "Application_v1": "application",
                "Company_v1": "company",
                "CostCenter_v1": "costcenter",
                "Division_v1": "division",
                "DynatraceCluster_v1": "dynatrace-cluster",
                "DynatraceEnvironment_v1": "dynatrace-environment",
                "Footprint_v1": "footprint",
                "Group_v1": "group",
                "Location_v1": "location",
                "LogicalEnvironment_v1": "logical-environment",
                "Networkzone_v1": "networkzone",
                "ObservableEnvironment_v1": "observable-environment",
                "Organization_v1": "organization",
                "Platform_v1": "platform",
                "Portfolio_v1": "portfolio",
                "Provider_v1": "provider",
                "Ratecard_v1": "ratecard",
                "Region_v1": "region",
                "Role_v1": "role",
                "User_v1": "user",
                "Zone_v1": "zone"
            }[asset.dto] + ".svg";
        }
    }

    /* Usage: myDto.capacityUnit = getEnumValue("b", CapacityUnitValues);
    getEnumValue<T = string>(value: string, allowed: string[]): T {
        return allowed.find(item => item.toLowerCase().trim() == value.toLowerCase().trim()) as unknown as T;
    } */

    /**
     * Create an empty DTORef that is intended to be used to
     * set or update a single reference to another DTO.
     * @param {number} id
     * @param {string} dto
     * @returns  {DTORef}
     * @memberof DTORegistry
     */
    createDTORef(id: number, dto: string): DTORef {
        return {
            id: id,
            idx: null,
            dto: dto,
            name: null,
            icon: null,
            parentId: null,
            description: null
        };
    }

    /**
     * Returns a Fetch-like interface to make arbitry HTTP requests.
     * @returns {RestAPI}
     * @memberof DTORegistry
     */
    getEndpointAPI(): RestAPI;
    /**
     * Returns a DTOEndpointAPI interface for generic DTO requests.
     * @returns {DTOEndpointAPI}
     * @memberof DTORegistry
     */
    getEndpointAPI(dtoName: "DTO"): DTOEndpointAPI;
    /**
     * Returns a DTOEndpointAPI interface bound to the specified type of DTO.
     * @param dtoName - Name of the DTO (e.g. 'Application_v1').
     * @returns {BoundEndpointAPI}
     * @throws {Error} If the DTO type cannot be found.
     * @memberof DTORegistry
     */
    getEndpointAPI(dtoName: string): BoundEndpointAPI;
    getEndpointAPI(dtoName?: any): any {
        // If we have a DTO name, then return the appropriate (bound) EndpointAPI from the registry.
        // Otherwise return the generic unbound DTOEndpointAPI.
        if (!dtoName) {
            return DTORegistry.httpAPI;
        }

        if (dtoName === "DTO") {
            return this.endpointAPI;
        }

        const metadata = this.registry.get(dtoName)
                      || this.registry.get(dtoName.toLowerCase())
                      || this.registry.get(dtoName.toLowerCase().replace("_", "-")) as any;
        if (!metadata) {
            throw new Error("Unknown asset type: " + dtoName);
        }

        return metadata.endpointAPI;
    }

    /**
     * Converts a plain object into a DTO with corresponding methods.
     * Note that contrary to the 'bound' (type-aware) implementations
     * of this method, the object's 'dto' property is consulted to
     * create the properly bound (endpoint-aware) DTO methods.
     * @param obj - The plain object.
     * @returns {T} The DTO-ified object.
     */
    from(obj: any): DTO {
        const cleaned = stripUnsafeProperties(obj);
        // NOTE: This is apparently a relatively expensive operation.
        return Object.setPrototypeOf(cleaned, this.createBoundPrototype(cleaned.dto));
    }

    /**
     * Retrieve all DTOs from the server of the given type.
     * @param {string} dtoName - Name of the DTO (e.g. 'Application_v1' or 'application').
     * @returns {Promise<DTO[]>} A promise that resolves with the DTOs.
     */
    select(dtoName: string): Promise<DTO[]>;
    /**
     * Retrieve a DTO from the server with the given ID.
     * @param {string} dtoName - Name of the DTO (e.g. 'Application_v1' or 'application').
     * @param id - ID of the DTO.
     * @returns {Promise<DTO>} A promise that resolves with a single DTO.
     */
    select(dtoName: string, id: number): Promise<DTO>;
    /**
     * Retrieve a collection of DTOs from the server that have the
     * property values indicated in the criteria object.
     * @param {string} dtoName - Name of the DTO (e.g. 'Application_v1' or 'application').
     * @param criteria - An object with properties and values.
     * @returns {Promise<DTO[]>} A promise that resolves with the DTOs.
     */
    select(dtoName: string, criteria: URLSearchParams): Promise<DTO[]>;
    /**
     * Retrieve a collection of DTOs from the server that match the
     * given OData filter.
     * @param {string} dtoName - Name of the DTO (e.g. 'Application_v1' or 'application').
     * @param filter - An OData query string.
     * @returns {Promise<T[]>} A promise that resolves with the DTOs.
     */
    select(dtoName: string, filter: string): Promise<DTO[]>;
    /** @ignore */
    select(dtoName: string, p: any = '', descendants?: boolean): any {
        return this.getEndpointAPI(dtoName).get(p, descendants).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => this.from(a) as DTO)
            : this.from(res)
    )}
}
export const DTO = new DTORegistry();

function getDTOTypeName(dtoName: string): string {
    dtoName = dtoName.split("_")[0];

    const typeName: string[] = [];
    for (let i = 0; i < dtoName.length; i++) {
        if (i !== 0 && dtoName[i].toUpperCase() === dtoName[i]) {
            typeName.push('_');
        }
        typeName.push(dtoName[i].toLowerCase());
    }
    return typeName.join("");
}

/**
 * Strip off all of the objects, functions and keys from an object.
 * Omitted object should be safe from circular JSON serialization exceptions
 */
function stripUnsafeProperties <T = DTO> (asset: T): T {
    const data = {};

    Object.entries(asset).forEach(([key, value]) => {
        if (value instanceof Date) {
            // Convert valid dates to strings. Strip invalid ones.
            if (!Number.isNaN(value.getTime()))
                data[key] = (value as Date).toISOString();
        }
        else if (typeof value == "object") {
            if (value === null) {
                data[key] = value;
                return;
            }
            // Preserve DTORefs, stripping superfluous properties.
            // Strip non-DTORefs.
            if (!Number.isNaN(value.id) && typeof value.dto == "string")
                data[key] = { id: value.id, name: value.name, dto: value.dto };
        }
        else if (typeof value == "function" || key.startsWith("_")) {
            // Strip functions and internal properties.
        }
        else if (typeof value == "undefined") {
            // Do not add the property.
            return;
        }
        else {
            // Other things are OK.
            data[key] = value;
        }
    });

    return data as T;
}
