import { Component, Input } from '@angular/core';
import { DTO, DTOMetaData, ReferenceType } from 'src/dto/dto';

@Component({ template: '' })
export class AssetComponent<T extends DTO = DTO> {
    private readonly debounce = 2000;
    private __lastUpdate: number = 0;

    public metadata: DTOMetaData;

    private __asset: T;
    private __lastAsset: T;
    @Input() set asset(value: T) {

        // Clear out any old asset.
        if (!value) {
            if (this.__asset) {
                this.__asset = null;
                this.__lastAsset = null;
                this.__lastUpdate = null;

                // Clear the component
                this.ngOnInit();
            }
            return;
        };


        // Now we have the actual asset.
        this.__asset = value;
        this.metadata = DTO.getMetaData(this.__asset['dto']);
        const assetChanged = (this.__lastAsset && (this.__lastAsset.dto != value.dto || this.__lastAsset.id != value.id));

        // Detect asset changes without rebuilding the entire component
        if (
            // Was used for reports. May no longer be necessary
            location.href.includes("headless=true") ||
            // Detect if the asset was replaced after a significant time delay
            (this.__lastUpdate && Date.now() - this.__lastUpdate > this.debounce) ||
            // Detect if the asset materially changes.
            assetChanged
        ) {
            this.ngOnInit();
        }

        this.__lastUpdate = Date.now();
        this.__lastAsset = value;
    }
    get asset() {
        return this.__asset;
    }

    ngOnInit() {
        throw new Error("You are extending the 'AssetComponent'. You MUST override the ngOnInit hook!");
    }

    /**
     * Return the reference type of the current asset.
     * Returns `undefined` if no reference type is found
     */
    getAssetReferenceType() {
        const chunks = this.asset?.['dto']?.toUpperCase().replace(/-/g, "_").split('_');
        if (!chunks) return null;
        chunks.pop();
        const dtoId = chunks.join('_');
        return ReferenceType[dtoId] as number;
    }
}
