import { Injectable } from '@angular/core';
import { ConsoleLogger, LogIcon } from '@dotglitch/ngx-common';
import { BehaviorSubject } from 'rxjs';
import { NavigationScope } from 'src/app/pages/general/asset-navigator/components/asset-list/asset-list.component';
import { AssetCacheService } from 'src/app/services/asset-cache.service';

import { DialogService } from 'src/app/services/dialog.service';
import { DtoService } from 'src/app/services/dto.service';
import { Fetch } from 'src/app/services/fetch.service';
import { UserService } from 'src/app/services/user.service';
import { AccessLevel, DTO, DynatraceEventTypes, MeetingEventTypes, ReferenceType } from 'src/dto/dto';
import { EventType } from 'src/dto/pmo/eventtype';

const { log, warn, err } = ConsoleLogger("AssetService", "#4db6ac");


const meetingEventTypes = MeetingEventTypes.map(met => EventType[met.value]);
const dynatraceEventTypes = DynatraceEventTypes.map(met => EventType[met.value]);

export type AssetStatus = {
    isDefault: boolean;
    isFavorite: boolean;
    isSubscribed: boolean;
}

export type DTOAction = {
    action: "create" | "update" | "read" | "delete" | "move" | null;
    asset?:  DTO;
    assets?: DTO[];
};

export type Crumb = DTO | NavigationScope;

@Injectable({
    providedIn: 'root'
})
export class AssetService {

    public rootAsset: DTO;
    public rootDto:   string;
    public rootScope: NavigationScope;
    public rootTrail: Crumb[] = [];

    // Nobody should subscribe to this!
    public rootAsset$ = new BehaviorSubject<DTO>(null);
    public rootScope$ = new BehaviorSubject<NavigationScope>(null);

    constructor(
        private readonly dto: DtoService,
        private readonly dialog: DialogService,
        private readonly fetch: Fetch,
        private readonly assetCache: AssetCacheService,
        private readonly userService: UserService
    ) {

    }

    public async getAsset(asset: DTO | string) {
        if (typeof asset == 'string') {
            const [dto, id] = asset.split('.');

            if (id && id != "null" && id != "undefined") {
                const cached = this.assetCache.getAssetFromCache(asset);
                if (cached) {
                    return cached;
                }
                else {
                    const meta = DTO.getMetaData(dto);
                    const dtoAPI = DTO.getEndpointAPI(meta._dto);

                    return dtoAPI.get(parseInt(id)).then(result => {
                        this.assetCache.storeAsset(result);
                        return result;
                    });
                }
            }

            return null;
        }
        else {
            // Detect if it's a dtoref and forcibly fetch it
            const meta = DTO.getMetaData(asset.dto);
            if (!meta) throw new Error("Invalid asset");
            const props = meta._properties.filter(p => p.prop != 'idx');
            const isFullDto = props.some(p => asset.hasOwnProperty(p.prop));

            // If we don't get a full asset for whatever reason, load the proper asset
            if (!isFullDto) {
                const dtoAPI = DTO.getEndpointAPI(meta._dto);

                return dtoAPI.get(asset.id).then(result => {
                    this.assetCache.storeAsset(result);
                    return result;
                });
            }

            return asset;
        }
    }

    // Parse the scope from the URI path.
    async setRootScope(scope: string | DTO | DTO[]) {
        if (!scope) return null;

        // Coerce the scope to always be a normalized string.
        if (Array.isArray(scope)) {
            scope = scope.map(c => c.dto + "." + c.id).join("/");
        }
        else if (typeof scope != "string") {
            scope = scope.dto + '.' + scope.id;
        }
        console.log("Setting root scope: " + scope);

        // Normalize all of the chunks from the scope and clear out any invalid ones.
        // Note that the last (root) scope may not have an ID. In that case, nothing
        // we can do for that one. getAsset will return a null. But we load all the
        // assets so that the user can navigate back without delay.
        const chunks    = scope.split("/").map(c => this.normalizeAssetIdentifier(c));
        const promises  = chunks.filter(c => !!c?.id).map(chunk => this.getAsset(chunk.type + '.' + chunk.id));
        const assets    = await Promise.all(promises);
        const rootAsset = assets.pop();

        // If we have roots in the asset, and there are more of them than parent
        // assets we've retrieved, then lets use those to reconstitute the trail.
        let rootTrail = [];
        if (rootAsset?.roots?.length > assets.length) {
            const promises = rootAsset.roots.reverse().map(root => this.getAsset(root));
            rootTrail = await Promise.all(promises);
            // Reverse the roots,as in tree mode breadcrumb is not working as expected.
            rootAsset?.roots?.reverse();
        }
        else if (rootAsset && assets.length == 0) {
            // We have no parents. But we _should_ have parents...
            let asset = rootAsset;
            do {
                let parent = null;

                const parentDTONames = DTO.getMetaData(asset.dto)?._parentDTONames;
                // Traverse up, but for non-organization root assets we stop at the Organization level.
                if (parentDTONames && asset.parentId &&
                    rootAsset.dto != "Organization_v1" && parentDTONames != "Organization_v1"
                ) {
                    try {
                        const promises = parentDTONames.split(",").map(dtoName => this.getAsset(dtoName + "." + asset.parentId));
                        parent = await Promise.any(promises);
                        if (parent) rootTrail.push(parent);
                    }
                    catch (e) {
                        parent = null;
                    }
                }

                asset = parent;
            } while (asset);
            rootTrail.reverse();  // Now we have the actual parents!
        }
        else {
            rootTrail = assets;   // We use the parent assets we got.
        }

        // If we have an actual rootAsset, awesome. Otherwise, we add a crumb
        // that at least tells us what kind of things to retrieve.
        this.rootAsset  = rootAsset
        this.rootTrail  = rootAsset
                        ? [...rootTrail, rootAsset]
                        : [...rootTrail, {   // This is a NavigationScope object. The AssetList and CrumbTrail need it.
                            dto:      chunks[chunks.length - 1]?.type,
                            criteria: rootTrail.length > 0
                                    ? [{ field: 'parentId', oper: 'eq', value: "" + this.rootTrail[rootTrail.length - 1]?.id }]
                                    : null
                          }];
        this.rootScope  = this.rootTrail[this.rootTrail.length - 1] as NavigationScope;

        this.rootAsset$.next(this.rootAsset); // Right now, nobody is subscribed.
        this.rootScope$.next(this.rootScope); // This is to update the colors in the UI.

        // Return the updated, complete version of the scope back to the caller.
        return this.rootTrail.map(c => c.dto + (c.id ? ("." + c.id) : "")).join("/");
    }

    /**
     * Make a best-effort to normalize the
     *
     *
     * @param identifier
     *  - e.g. project.123
     *  - e.g. Project_v1.123
     *  - e.g. EDTPRJ-000123
     *  - e.g. dynatrace-environment.123
     */
    normalizeAssetIdentifier(identifier: string) {

        // Resolve the dto and idx from the identifier string
        const [dto, idx] = (() => {
            // Detect if the format is EDTTSK-000000
            if (/[A-Z]{6}-\d+/.test(identifier)) {
                return identifier.split('-');
            }
            else if (/[a-z\-_\d]{3,}\.\d+$/.test(identifier)) {
                // The format should be loosely
                return identifier.split('.');
            }
            else if (/[a-z\-_\d]{3,}\.undefined$/.test(identifier)) {
                return [identifier.split('.')[0], null];
            }
            return [identifier, null];
        })();

        // Normalize whatever the provided dto identifier is.
        const meta = DTO.getMetaData(dto);

        // Unknown dto name
        if (!meta) return null;

        const id = +parseInt(idx);

        if (Number.isNaN(id)) return { type: meta._type, id: null };

        return { type: meta._type, id };
    }

    // TODO: support handling partial complex DTO metadata
    createAsset(dto: string | Partial<DTO>, parent?: DTO, options?: any) {
        const refType = DTO.getReferenceType(typeof dto == "string" ? dto : dto.dto);

        if (refType == ReferenceType.USER) {
            return this.dialog.open("UserInvite", {
                inputs: {},
                height: "auto",
                width: "700px",
                hasBackdrop: false,
                isModal: true,
                hideSecurityControl: true,
                title: "User Invitation Wizard",
                icon: "./assets/img/elevate/icon-dtlogo.png",
            });
        }

        const asset = typeof dto == "string"
            ? {
                idx: "",
                name: "",
                dto: dto,
                refType: refType
            } as Partial<DTO>
            : dto;

        asset.id = undefined; // No ID means: create!
        asset.parentId = parent?.id;

        return this.openAssetPropertiesDialog(asset, parent, options);
    }

    async moveAsset(asset: DTO | DTO[], currentParent: DTO, newParent?: DTO) {
        // If a parent is not provided, let the user select one.
        // Once we have a parent, check if the to-be-moved asset(s)
        // have a parent of a different type than the specified one.
        // If so, show a warning dialog. If not, or if the user
        // accepts this, proceed.
        const assetList = Array.isArray(asset) ? asset : [asset];

        // Validate: Are all assets in the list of the same type?
        // Among what is currently selected, take the first one
        // and check everybody is of the same type (dtoName).
        const dto = assetList[0]?.dto;
        const pid = assetList[0]?.parentId;

        if (!assetList.every(x => x.dto == dto && x.parentId == pid)) {
            // TODO: Show user an error: No all assets are of the same type
            // or have the same parent.
            return false;
        }
        if (!assetList.every(x => x.access <= 16)) {
            // TODO: Show user an error: User is not an owner of every asset.
            return false;
        }

        const metaData = DTO.getMetaData(dto);

        // TBD: How do we know which parents are actually allowed?
        // 1) For event-like things: Projects, Goals, Objectives, Tasks.
        // 2) For non-event-like things: Only the types of parents as
        //      identified in _parentDTONames.
        const allowedParentTypes: string[] = metaData._parentDTONames?.split(',');
        if (!newParent) {
            // const parentMetadata = DTO.getMetaData(metaData._rootDTOName);
            // let candidates: DTO[] = await this.fetch.get(parentMetadata._endpoint);

            // TODO: We do want to show the complete hierarchy. It's just that
            // the user HAS to select a new parent that is of one of the allowed types.

            // TODO: What is this even for?
            // const itemIndex = candidates.findIndex(x => x.id == this.assetList$.value.selectedId);
            // const itemToShift = candidates[itemIndex];

            // candidates.splice(itemIndex, 1);
            // candidates.unshift(itemToShift);

            // Here, we are getting result in form of a DTO[] as we are directly getting
            // that from the tree component with @Output(selectChange), and @Output(selectChange) is of type DTO[]
            // TODO: Handle selecting a parent...?
            // newParent = await this.selectParent(candidates);
        }

        if (!newParent)
            return false;

        if (!allowedParentTypes.includes(newParent?.dto)) {
            // tell the user and return false;
        }

        // if (newParent.access <= 4) {
        // TODO: Show user an error: User is not allowed to write to this asset,
        // and thus cannot add children to it.
        // const confirmation = await this.warnUser(dto, currentParent.dto, newParent.dto);
        // if (!confirmation) return false;
        //     alert("User is not allowed to write to this asset")
        //     return false;
        // }

        if (newParent.dto != currentParent.dto) {
            // So, the new parent is of a different type than the current one.
            // What does that mean for our assets?
            // We know that (for instance) the original parent was an Objective,
            // the user just selected a Project. What does that mean for those
            // (apparently) Tasks? They become Goals. How do we know what they
            // are going to be?
            // Let's just warn the user that _something_ is going to happen.
            // In the future we can become more articulate about it...
            // const confirmation = await this.dialog.confirm.warnUser(dto, currentParent.dto, newParent.dto);
            const confirmation = await this.dialog.confirm("WARNING",
                `You are moving one or more ${DTO.getUserFriendlyName(dto)}(s) ` +
                `(and their descendants) from a ${DTO.getUserFriendlyName(currentParent.dto)} ` +
                `to a ${DTO.getUserFriendlyName(newParent.dto)}. This will change their types.`
            );
            if (!confirmation) return false;
        }

        try {
            const patchRequests = assetList.map(asset =>
                DTO.getEndpointAPI(asset.dto)
                    .patch(asset.id, { id: asset.id, parentId: newParent.id })
            );
            const patchResults = await Promise.all(patchRequests);
            patchResults.forEach(patchResult => {
                // Find the asset in the original assetList and update
                // the parentId in it.
                const asset = assetList.find(a => a.id == patchResult.id);
                asset.parentId = patchResult.parentId;
                // Alternative: replace it with the new one!
                // assetList.splice(indexOfOriginal, 1, assetFromServer);
            });
            return assetList; // Now have updated parentIds.
        }
        catch (err) {
            // Tell the user and return.
            log(LogIcon.circle_green, "Could not move: " + (err.message || err));
            return false;
        }
    }

    async deleteAsset(asset: DTO | DTO[], isCascading = true): Promise<DTO[]> {
        const list = Array.isArray(asset) ? asset : [asset];
        const ref = list.length > 1 ? `these ${list.length} assets` : `this asset`;

        if (!list.every(i => i.access >= AccessLevel.OWNER)) {
            await this.dialog.inform("Action Denied", `You do not have permission to delete ${ref}!`);
            return [];
        }

        // If deleting an asset may have cascading
        // effects, we're going to have to psychologically pressure the user by
        // asking them to type in their email address and the name of their first-
        // born. Which we will check...
        const hasNoChildren = list.every(asset => {
            const childrenAt = DTO.getMetaData(asset.dto)._childrenAt;
            // EX: 'children' or 'users,platforms,applications'
            return !childrenAt || childrenAt.split(",").every(prop => !(asset[prop]?.length > 0));
        });

        let isConfirmed = hasNoChildren;

        if (!isConfirmed) {
            isConfirmed = await this.dialog.critical("Caution!", `Deleting ${ref} may have cascading effects.`);
        }

        if (isConfirmed) {
            const deletions = list.map(asset => {
                const dtoAPI = DTO.getEndpointAPI(asset.dto);
                return dtoAPI.delete(asset.id);
            });
            const results = await Promise.all(deletions);
            return results
                .map((success, index) => success ? list[index] : null)
                .filter(r => r);
        }
        return [];
    }

    openAssetPropertiesDialog(asset: Partial<DTO>, parent?: DTO, context?: any): Promise<DTOAction> {

        // If we get an event without a DTO, we will coerce the real DTO based on
        // the event type.
        if ((!asset.dto || asset.dto == 'Event_v1') && typeof asset['eventType'] == 'number') {
            asset.dto =
                meetingEventTypes.includes(asset['eventType']) && "Meeting_v1" ||
                dynatraceEventTypes.includes(asset['eventType']) && "DynatraceEvent_v1" ||
                asset['eventType'] == EventType.PROJECT && "Project_v1" ||
                asset['eventType'] == EventType.GOAL && "Goal_v1" ||
                asset['eventType'] == EventType.COVERAGE_GOAL && "CoverageGoal_v1" ||
                asset['eventType'] == EventType.OBJECTIVE && "Objective_v1" ||
                asset['eventType'] == EventType.COVERAGE_OBJECTIVE && "CoverageObjective_v1" ||
                asset['eventType'] == EventType.TASK && "Task_v1" ||
                asset['eventType'] == EventType.COVERAGE_TASK && "CoverageTask_v1";
        }

        //const assetInput = typeof asset.id == 'number' ? { id: asset.id, dto: asset.dto } : asset;

        return this.dialog.open(asset.dto, {
            group: "asset-editor",
            width: asset.dto != "User_v1" ? "1300px" : "800px",
            height: asset.dto != "User_v1" ? "700px" : "500px",
            inputs: { asset, context },
            toggles: [
                ...(
                    asset.id > 0
                    ? [
                        { id: 'notes', icon: 'notes', title: 'Open or close the discussion panel', selected: true },
                        { id: 'tag', icon: 'tag', title: 'Open or close the property sheet panel' },
                        { id: 'contribution', icon: 'category', title: 'Open or close the contribution notes panel' },
                    ] : []
                ),
                // TODO: re-enable when the wiki is useful
                // { id: 'doc', icon: 'doc', title: 'Open or close the documentation panel' },
            ],
            useAdvanced: true,
            isModal: true,
            isResizable: true,
            hasBackdrop: false,
            icon: DTO.getMetaData(asset.dto)?._icon || "./assets/dtos/dto2.svg",
            title: typeof asset.id != "number"
                ? "Create New " + this.getAssetTypeName(asset as DTO) + (parent ? " under " + parent.name : "")
                : this.dto.getAncestryName(asset as DTO) || this.getAssetTypeName(asset as DTO)
        }).catch(e => { console.error(e); });
    };

    /**
     * Get a user-friendly label for a given DTO type
     * e.g.
     */
    public getAssetTypeName(asset?: DTO): string | undefined {
        // TODO: Fix this properly in the DTOs?
        const eventType = {
            "PRIVATE_VALUE_EVENT_V1": "Meeting_v1",
            "PUBLIC_VALUE_EVENT_V1": "Meeting_v1",
            "DYNATRACE_VALUE_EVENT_V1": "Meeting_v1",
            "QUARTERLY_BUSINESS_REVIEW_V1": "Meeting_v1",
            "VELOCITY_SERVICES_EVENT_V1": "Meeting_v1",
            "ESA_SERVICES_EVENT_V1": "Meeting_v1",
            "EPM_SERVICES_EVENT_V1": "Meeting_v1",
            "PROJECT_MEETING_V1": "Meeting_v1",
            "TRAINING_SESSION_V1": "Meeting_v1",
        }[asset?.dto?.toUpperCase()];

        const metadata = DTO.getMetaData(eventType ? eventType : asset?.dto);
        if (!metadata)
            throw new Error("Could not find metadata for dto: " + asset?.dto);
        return metadata._type.split('-').map((word: string) => word[0].toUpperCase() + word.substring(1)).join(' ');
    }

    public getUserFriendlyName(type: string): string {
        const friendlyName: string[] = [];
        for (let i = 0; i < type.length; i++) {
            if (i == 0) {
                friendlyName.push(type[i].toUpperCase());
            }
            else if (type[i] == '-' || type[i] == '_') {
                friendlyName.push(' ');
                i++;
                friendlyName.push(type[i].toUpperCase());
            }
            else {
                friendlyName.push(type[i].toLowerCase());
            }
        }

        return friendlyName.join("");
    }

    /**
     * Get a subarray of all the user's favorites.
     * NOTE: The first item in the returned list is the DEFAULT asset.
     *       If no default asset is available, it's the original order.
     *
     * TODO: data filter and (possibly other) components need to use this logic
     */
    public getFavorites(list: DTO[], type: string) {
        const meta = DTO.getMetaData(type);
        const refType = ReferenceType[meta?._type.toUpperCase()];

        const favs = this.userService.favorites
            .filter(fav => fav.targetType == refType)
            .sort((a, b) => a.data == "DEFAULT" ? -1 : b.data == "DEFAULT" ? 1 : 0);

        const favList = favs
            .map(fav => list.find(asset => asset.id == fav.target))
            .filter(asset => !!asset);

        return favList;
    }
}
