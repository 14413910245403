import { Component, Input } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { priorityList } from 'src/app/types/event-enums';
import { EDTCodePipe } from 'src/app/utils/pipes/edtcode.pipe';
import { Event_v1 } from 'src/dto/pmo/event-v1';


@Component({
    selector: 'app-event-edtcode',
    templateUrl: './event-edtcode.component.html',
    styleUrls: ['./event-edtcode.component.scss'],
    imports: [
        MatIconModule,
        EDTCodePipe
    ],
    standalone: true
})
export class EventEdtcodeComponent {
    readonly priorityList = priorityList;
    readonly priorityMap = {};

    readonly ragColors = {
        1: "var(--palette-red-background-color)",
        2: "var(--palette-amber-background-color)",
        3: "var(--palette-green-background-color)",
        4: "var(--palette-gray-background-color)",
        "red": "var(--palette-red-background-color)",
        "amber": "var(--palette-amber-background-color)",
        "green": "var(--palette-green-background-color)",
        "gray": "var(--palette-gray-background-color)"
    }

    @Input() asset: Event_v1;
    @Input() v2: '' | 'true' = '';
    @Input() showEdtCode = true;

    constructor(
        public readonly theme: ThemeLoaderService,
        private readonly iconRegistry: MatIconRegistry,
        private readonly sanitizer: DomSanitizer,
    ) {
        iconRegistry.addSvgIcon('prio-diamond', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/priority.svg'));
        iconRegistry.addSvgIcon('priv-dynatrace', sanitizer.bypassSecurityTrustResourceUrl('./assets/img/privacy-dynatrace.svg'));
        priorityList.forEach(p => this.priorityMap[p.id] = p);
    }
}
