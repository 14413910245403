import DOMPurify from 'dompurify';
import { openTooltip } from 'src/app/directives/tooltip.directive';
import { UserPreviewCardComponent } from 'src/app/pages/eom/user-preview-card/user-preview-card.component';
import { Attachment, AttachmentType, DTO, DTORef, ReferenceType } from 'src/dto/dto';
import { Organization_v1 } from 'src/dto/eom/organization-v1';
import { Personas } from 'src/dto/eom/persona';
import { User_v1 } from 'src/dto/eom/user-v1';
import { Event_v1 } from 'src/dto/pmo/event-v1';
import { EventType } from 'src/dto/pmo/eventtype';

export const ACCOUNTABLE_ROLE = 128;
export const RESPONSIBLE_ROLE = 16;
export const CONSULTED_INFORMED_ROLE = 5;
export const CONSULTED_ROLE = 4;
export const INFORMED_ROLE = 1;
export const roleNames = {
    128: "Accountable",
    16: "Responsible",
    5: "Consulted & Informed",
    4: "Consulted",
    1: "Informed"
};
export const eventCategories = [
    { label: "red", class: "red" },
    { label: "orange", class: "orange" },
    { label: "peach", class: "peach" },
    { label: "yellow", class: "yellow" },
    { label: "green", class: "green" },
    { label: "light-teal", class: "light-teal" },
    { label: "olive", class: "olive" },
    { label: "blue", class: "blue" },
    { label: "purple", class: "purple" },
    { label: "pink", class: "pink" },
    { label: "steel-light", class: "steel-light" },
    { label: "steel-gray", class: "steel-gray" },
    { label: "light-gray", class: "light-gray" },
    { label: "gray", class: "gray" },
    { label: "black", class: "black" },
    { label: "dark-red", class: "dark-red" },
    { label: "dark-orange", class: "dark-orange" },
    { label: "brown", class: "brown" },
    { label: "dark-yellow", class: "dark-yellow" },
    { label: "dark-green", class: "dark-green" },
    { label: "dark-teal", class: "dark-teal" },
    { label: "dark-olive", class: "dark-olive" },
    { label: "dark-blue", class: "dark-blue" },
    { label: "dark-purple", class: "dark-purple" },
    { label: "dark-magenta", class: "dark-magenta" },
]

export type RaciAssignment = {
    id: number;
    name: string;
    email: string;
    type: number;
    role: number;
}


export const getGroupMemberships = (user: User_v1) => {
    const assignments: Attachment[] = user?.assignments as any[] || [];
    return assignments.filter(a => a.targetType == ReferenceType.GROUP);
}

export const getOrganizationRoots = async (org: DTORef) => {
    const roots = org ? [org] : [];
    while (org?.parentId) {
        org = await Organization_v1.select(org.parentId);
        if (org) roots.push(org);
    }
    return roots;
}

export const raciRolesPresent = (assignments: Attachment[]): {
    hasAccountable: boolean,
    hasResponsible: boolean,
    hasConsulted:   boolean,
    hasInformed:    boolean
} => {
    let hasAccountable = false;
    let hasResponsible = false;
    let hasConsulted = false;
    let hasInformed = false;

    assignments.forEach(assignment => {
        hasAccountable = hasAccountable || assignment.role == ACCOUNTABLE_ROLE;
        hasResponsible = hasResponsible || assignment.role == RESPONSIBLE_ROLE;
        hasConsulted   = hasConsulted   || assignment.role == CONSULTED_ROLE || assignment.role == CONSULTED_INFORMED_ROLE;
        hasInformed    = hasInformed    || assignment.role == INFORMED_ROLE  || assignment.role == CONSULTED_INFORMED_ROLE;
    });

    return {
        hasAccountable,
        hasResponsible,
        hasConsulted,
        hasInformed
    }
}

/**
 * Get the RACI associations
 */
export const getAssignedParties = (event: Event_v1, users: User_v1[]) => {
    let creator     = getCreator(event, users);
    let accountable = null;
    let responsible = null;
    let consulted   = [];
    let informed    = [];

    let assignment: Attachment;
    let assignee;
    for (let i = 0; i < event.assignedTo?.length; i++) {
        assignment = event.assignedTo[i];
        if (assignment.type != AttachmentType.ASSIGNMENT) {
            console.warn(`Invalid attachment type ${assignment.type} in assignment`);
            continue;
        }

        if (assignment.sourceType == ReferenceType.USER) {
            assignee = users.find(u => u.id == assignment.source);
            // Sometimes users do not have names in results from 'user/v1.0'.
            // Individual components will fall back to using the email address,
            // but it has been observed that the assignments typically do have
            // the user's name. So, we try that here, and fall back to the email
            // address if wven that fails.
            if (assignee && !assignee.name?.trim()) assignee.name = assignment.name || assignee.email;
        }
        else if (assignment.sourceType == ReferenceType.GROUP) {
            assignee = {
                id:    assignment.source,
                name:  assignment.name,
                email: assignment.name,
                dto:   "Group_v1"
            };
        }
        else if (assignment.sourceType == ReferenceType.ORGANIZATION) {
            assignee = {
                id:    assignment.source,
                name:  assignment.name,
                email: assignment.name,
                dto:   "Organization_v1"
            };
        }
        else {
            console.warn(`Invalid source type ${assignment.type} for assignment`);
            continue;
        }

        // If we can't find the assignee, we will create a "Removed User"
        // to stub whatever is missing.
        if (!assignee) {
            const type = ReferenceType[assignment.sourceType].toLowerCase() as string;
            const [first, ...chars] = type.split('');
            const dto = first.toUpperCase() + chars.join('');

            assignee = {
                email: "no-reply@elevate.dynatrace.com",
                name: "Removed " + dto,
                // Who we _think_ the user was.
                _name: assignment.name,
                id: assignment.source,
                dto: dto + '_v1'
            }
            // console.warn(`Cannot find assignee ${assignment.source} - ${assignment.name}`);
            // continue;
        }

        if (assignment.role == ACCOUNTABLE_ROLE) {
            accountable = assignee;
        }
        else if (assignment.role == RESPONSIBLE_ROLE) {
            responsible = assignee;
        }
        else if (assignment.role == CONSULTED_ROLE) {
            consulted.push(assignee);
        }
        else if (assignment.role == CONSULTED_INFORMED_ROLE) {
            consulted.push(assignee);
            informed.push(assignee);
        }
        else if (assignment.role == INFORMED_ROLE) {
            informed.push(assignee);
        }
    }

    return {
        accountable,
        responsible,
        consulted,
        informed,
        creator
    }
}

export const getRACIroles = (event: Event_v1, user: User_v1, users: User_v1[]) => {
    if (!event['_associations'])
        event['_associations'] = getAssignedParties(event, users);

    if (!user) return;

    const {
        accountable,
        responsible,
        consulted,
        informed,
        creator
    } = event['_associations'];

    const raciRoles = [];
    if (accountable
            ? findAssignment(accountable, user)
            : user.email?.toLowerCase() == creator?.email?.toLowerCase()
    ) raciRoles.push("a");

    if (responsible
            ? findAssignment(responsible, user)
            : false
    ) raciRoles.push("r");

    if (consulted
            ? consulted.find(party => findAssignment(party, user))
            : false
    ) raciRoles.push('c');

    if (informed
            ? informed.find(party => findAssignment(party, user))
            : false
    ) raciRoles.push('i');

    event['_raciRoles'] = raciRoles;

    const assignments = event.assignedTo || [];
    event['responsible_name'] = assignments.find(a => a.role == 16)?.name  || "(none)";
    event['accountable_name'] = assignments.find(a => a.role == 128)?.name || event.createdBy;
}

const findAssignment = (party, user: User_v1) =>
    party.dto == "User_v1"          ? user.email?.toLowerCase() == party.email?.toLowerCase() :
    party.dto == "Group_v1"         ? user['_grpNames']?.find(g => g.email?.toLowerCase() == party.email?.toLowerCase()) :
    party.dto == "Organization_v1"  ? user['_orgNames']?.find(g => g.email?.toLowerCase() == party.email?.toLowerCase()) :
    false;

/**
 * Get the most primary association
 */
export const getPrimaryAssociation = (event: Event_v1, users: User_v1[]) => {
    const { accountable, responsible, creator } = getAssignedParties(event, users);

    return responsible || accountable || creator || null;
}

/**
 * Get the creator of an event
 */
export const getCreator = (event: Event_v1, users: User_v1[]) => {
    const createdByEmail = event.createdBy?.toLowerCase();
    return users.find(u => u.email?.toLowerCase() == createdByEmail) || {
        id: -1,
        name:  event.createdBy,
        email: event.createdBy,
        dto: "User_v1"
    };
};

type AzureProfilePictureSize = '48x48' | '64x64' | '96x96' | '120x120' | '240x240' | '360x360' | '432x432' | '504x504' | '648x648';

export const getPersonaLabel = (personaCode: string) => {
    return Personas[personaCode] || personaCode || 'no persona';
}

/**
 * Get a profile picture URL
 */
const getUserProfileImage = (user: User_v1, size?: AzureProfilePictureSize) => {

    const name = user?.name;
    const initials = name?.split(" ").map(o => o[0]?.toUpperCase()).join("") || "N/A";

    const email = user.email?.toLowerCase().trim();
    const img =
            email == "automation@"
            ? '/assets/img/ace.svg'
            : email?.endsWith("@dynatrace.com")
            ? DOMPurify.sanitize(`/api/azure/image/` + email + (size ? (`/` + size) : '' + `?initials=` + initials + `&v=` + (user.id % 10)))
            : DOMPurify.sanitize(user.photo);

    if (!img || !email)
        return '/assets/img/ace.svg'

    // Sanitize the URL just to be on the safe side
    return DOMPurify.sanitize(img);
}

const imageCache: {
    [key: string]: HTMLImageElement
} = {}

/**
 * Get an HTML Element that will load the users profile
 * picture. Uses a memory cache and will show a spinner
 * while the image is loading
 */
export const getUserProfileImageElement = (user: User_v1, classes: string[] = [], size?: AzureProfilePictureSize, defaultInitials = true, showPopup = true) => {
    const wrapper = document.createElement("div");
    wrapper.classList.add("profile-image", ...classes);
    wrapper.setAttribute("data-id", (user?.id ?? 0).toString());
    wrapper.setAttribute("data-email", user?.email ?? "unknown@dynatrace.com");
    wrapper.setAttribute("data-dto", (user?.dto ?? "Unknown_v1").toString());

    const url = user ? getUserProfileImage(user, size) : "";
    // const title = user ? DOMPurify.sanitize(user.name) : "";
    const initials = user?.name?.split(" ").map(o => o[0]?.toUpperCase()).join("") || "N/A";

    if (url?.length > 5) {
        // IIFE to immediately create a new Image for the cache
        const image = imageCache[url] ?? (() => {
            const image = imageCache[url] = new Image();
            // Show a loader while the image downloads.
            image.src = `data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="32px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="%2340c4ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle><!-- [ldio] generated by https://loading.io/ --></svg>`;

            // Fetch the image via JS and cache it as base64
            window.fetch(url)
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }))
                .then(base64 => {
                    image.src = base64 as any;
                    image['clones'].forEach((clone: HTMLImageElement) => {
                        clone.src = image.src;
                    });
                })
                .catch(err => {
                    delete imageCache[url];
                });

            image['clones'] = [];
            return image;
        })();
        const clone = image.cloneNode(true);

        wrapper.append(clone);
        image['clones'].push(clone);
    }
    else if (defaultInitials) {
        if (/[^a-zA-Z0-9\_ '"`\-\/]/.test(initials)) {
            console.error("Initials: ", initials, user);
            throw new Error("ELV0620: Invalid initials");
        }

        wrapper.innerHTML = `<div class="initials variant-${(user?.id ?? 0) % 10}">${DOMPurify.sanitize(initials)}</div>`;
    }
    else {
        wrapper.innerHTML = `<img src='/assets/dtos/user.svg'/>`;
    }

    if (user) {
        const statusIndicator = document.createElement("div");
        statusIndicator.classList.add("status-indicator");

        const lastActive = new Date(user.lastActive);
        const now = new Date();
        lastActive.setHours(0,0,0,0);
        now.setHours(0,0,0,0);

        const daysAgo = Math.round((now.getTime() - lastActive.getTime()) / (24*60*60*1000));

        statusIndicator.style.backgroundColor = window.root.dto.getDiscussionColor(daysAgo);
        wrapper.append(statusIndicator);
    }

    if (showPopup) {
        let timeout;
        wrapper.addEventListener("pointerenter", () => {
            timeout = setTimeout(() => {
                timeout = null;
                openTooltip(
                    window.root.matDialog,
                    UserPreviewCardComponent,
                    user,
                    wrapper as any as HTMLElement,
                    {
                        customClass: ['br-12']
                    }
                );
            }, 300);
        });
        wrapper.addEventListener("pointerleave", () => {
            timeout && clearTimeout(timeout);
            timeout = null;
        });
    }

    return wrapper;
}

/**
 * Get the small profile picture used on PMO screens based on a selected event.
 * @param event Event that the user is resolved for
 * @param users List of all users relative to the scope
 * @returns
 */
export const getUserSmallPicture = (event: Event_v1, users: User_v1[], classes: string[] = []) => {
    if (!event) return '';

    let u = getPrimaryAssociation(event, users);
    if (!u)
        return "";

    return getUserProfileImageElement(u, classes);
}


/**
 * Identify the project for the specified event.
 * Returns null if no project can be resolved in the given data set.
 */
export const getEventProject = (event: Event_v1, events: Event_v1[]) => {
    if (event.eventType == EventType.PROJECT)
        return event;

    const p = events.find(e => e.id == event.parentId);

    return p ? getEventProject(p, events) : null;
};

/**
 * Check if the specified event is a descendant of the specified ancestor.
 *
 * Returns true if the specified event and ancestor event are the same entry.
 *
 *
 * !! This might be broken. Please validate if using this.
 */
export const isEventDescendantOf = (event: Event_v1, ancestor: Event_v1, events: Event_v1[]) => {
    if (!event || !ancestor) return false;

    const parent1 = events.find(ev => ev.id == event.parentId);
    const parent2 = events.find(ev => ev.id == parent1?.parentId);
    const parent3 = events.find(ev => ev.id == parent2?.parentId);
    const parent4 = events.find(ev => ev.id == parent3?.parentId);
    const parent5 = events.find(ev => ev.id == parent4?.parentId);
    const parent6 = events.find(ev => ev.id == parent5?.parentId);
    const parent7 = events.find(ev => ev.id == parent6?.parentId);

    return event.id == ancestor.id ||
        parent1?.id == ancestor.id ||
        parent2?.id == ancestor.id ||
        parent3?.id == ancestor.id ||
        parent4?.id == ancestor.id ||
        parent5?.id == ancestor.id ||
        parent6?.id == ancestor.id ||
        parent7?.id == ancestor.id;
};


/**
 * Check if the specified event is a descendant of the specified ancestor.
 *
 * Returns true if the specified event and ancestor event are the same entry.
 */
export const isEventRootDescendantOf = (event: Event_v1, ancestor: Event_v1) => {
    if (!event || !ancestor) return false;

    return event.id == ancestor.id ||
        !!(event['roots'] || []).find(r => r.id == ancestor.id);
};


/**
 * Helper function to get a clean date string for a given age in ms.
 */
export const getFriendlyAge = (ms: number) => {
    const months = Math.floor(ms / (30 * 24 * 60 * 60 * 1000));
    const monthsms = ms % (30 * 24 * 60 * 60 * 1000);

    if (months > 2) return `${months} Months`;

    const days = Math.floor(monthsms / (24 * 60 * 60 * 1000));
    const daysms = monthsms % (24 * 60 * 60 * 1000);

    if (months == 1) {
        if (days == 1)
            return `1 Month, ${days} Day`;
        return `1 Month, ${days} Days`;
    }
    if (months > 0) {
        if (days == 1)
            return `${months} Months, ${days} Day`;
        return `${months} Months, ${days} Days`;
    }

    if (days > 1) return `${days} Days`;

    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);

    if (days == 1) {
        if (hours == 1)
            return `1 Day, 1 Hour`;
        return `1 Day, ${hours} Hours`;
    }
    if (hours > 1) return `${hours} Hours`;

    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);

    return ``;
}

export const compactNumber = (num: number, precision: number = 1) => {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'G', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 }
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    return found
        ? (num / found.threshold).toFixed(precision) + found.suffix
        : Math.round(num);
}

const getTargetType = (dto: string): ReferenceType => {
    const type = dto.toUpperCase().replace(/-/g, "_").replace("_V1", "");
    return ReferenceType[type];
}

export const dissociateParty = async (task: Event_v1, party: RaciAssignment | DTO, role: string) => {
    let roleId = {
        "r": RESPONSIBLE_ROLE,
        "a": ACCOUNTABLE_ROLE,
        "c": CONSULTED_ROLE,
        "i": INFORMED_ROLE,
        "ci": CONSULTED_INFORMED_ROLE,
    }[role];

    const partyType: ReferenceType = (party as DTO).dto ? DTO.getReferenceType((party as DTO).dto) : (party as RaciAssignment).type;
    let targetAttachment = task.assignedTo?.find(a => a.source == party.id && a.sourceType == partyType && a.role == roleId);

    // If we are dissociating either C or I, also check if the party is consulted+informed
    if (!targetAttachment && (role == "c" || role == "i")) {
        targetAttachment = task.assignedTo?.find(a => a.source == party.id && (a.role == CONSULTED_INFORMED_ROLE))
    }

    await DTO.attachmentAPI.delete(targetAttachment.id);
    return await Event_v1.select(task.id);
}

export const associateParty = async (task: Event_v1, party: RaciAssignment | DTO, role: "r" | "a" | "c" | "i" | "ci") => {
    const promises = [];
    let roleId = {
        "r": RESPONSIBLE_ROLE,
        "a": ACCOUNTABLE_ROLE,
        "c": CONSULTED_ROLE,
        "i": INFORMED_ROLE,
        "ci": CONSULTED_INFORMED_ROLE,
    }[role];

    if (!party) return task;

    const partyType: ReferenceType = (party as DTO).dto ? DTO.getReferenceType((party as DTO).dto) : (party as RaciAssignment).type;
    const targetAttachment = task.assignedTo?.find(a => a.source == party.id && a.sourceType == partyType);
    const oldOwner = task.assignedTo?.find(a => a.role == roleId);

    // If the owner isn't updated, skip.
    if (oldOwner?.source == party.id && oldOwner.sourceType == partyType && oldOwner.role == roleId)
        return task;

    // If we already have an attachment for the party, update it.
    if (targetAttachment) {
        // If we're adding consulted to informed, merge the roles.
        if (
            (targetAttachment.role == CONSULTED_ROLE && roleId == INFORMED_ROLE) ||
            (targetAttachment.role == INFORMED_ROLE && roleId == CONSULTED_ROLE)
        )
            roleId = CONSULTED_INFORMED_ROLE;

        targetAttachment.role = roleId;
        promises.push(DTO.attachmentAPI.update(targetAttachment));
    }
    // Create a new attachment.
    else {
        promises.push(DTO.attachmentAPI.create({
            id: -1,   // It needs a value to be parsed by C#.
            idx: "",
            parentId: party.id,
            dto: 'Attachment_v1',
            icon: null,
            name: party.name,
            description: task.name,
            source: party.id,
            sourceType: partyType,
            target: task.id,
            targetType: getTargetType(task.dto),
            access: 4, // Read/Write
            role: roleId,
            type: AttachmentType.ASSIGNMENT,
            data: ""
        }));
    }

    // If we're oversetting Accountable or Responsible,
    // default the previous user to Consulted-Informed
    if (oldOwner && (role == "a" || role == "r")) {
        oldOwner.role = CONSULTED_INFORMED_ROLE;
        promises.push(DTO.attachmentAPI.update(oldOwner));
    }

    await Promise.all(promises);
    return await Event_v1.select(task.id);
}
