import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { firstValueFrom } from 'rxjs';
import { NoteEditDialogComponent } from 'src/app/components/contribution-notes-list/note-edit-dialog/note-edit-dialog.component';
import { NoteHistoryDialogComponent } from 'src/app/components/contribution-notes-list/note-history-dialog/note-history-dialog.component';
import { AssetCacheService } from 'src/app/services/asset-cache.service';
import { Fetch } from 'src/app/services/fetch.service';
import { UserService } from 'src/app/services/user.service';
import { AssetComponent } from 'src/app/utils/asset-component.template';
import { MarkdownPipe } from 'src/app/utils/pipes/markdown.pipe';
import { Attachment, DTO } from 'src/dto/dto';

@Component({
    selector: 'app-contribution-notes-list',
    templateUrl: './contribution-notes-list.component.html',
    styleUrls: ['./contribution-notes-list.component.scss'],
    imports: [
        DatePipe,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MarkdownPipe
    ],
    standalone: true
})
export class ContributionNotesListComponent extends AssetComponent {

    notes: Attachment[] = [];

    constructor(
        private readonly fetch: Fetch,
        public readonly user: UserService,
        private readonly dialog: MatDialog,
        private readonly assetCache: AssetCacheService
    ) {
        super();
    }

    override async ngOnInit() {
        if (!this.asset) return;

        this.notes = await this.fetch.get(`/api/eda/v1.0/topic/${this.metadata._type}/${this.asset.id}`);
        this.notes.forEach(note => {
            try {
                if (typeof note.data == 'string')
                    note.data = JSON.parse(note.data);
            } catch (ex) { }
        })
    }

    deleteNote(oldNote: Attachment) {
        // Immediately remove it for visual responsiveness
        const index = this.notes.findIndex(note => note.id == oldNote.id);
        this.notes = this.notes.filter(c => c != oldNote);

        // Demolish it on the backend, reload the data
        DTO.attachmentAPI.delete(oldNote.id)
            .then(success => {
                if (success) return;

                this.notes.splice(index, 0, oldNote);
                this.notes = [...this.notes];
            });
    }

    editNote(note: Attachment) {
        this.openEditDialog(note);
    }

    viewHistory(note: Attachment) {
        this.dialog.open(NoteHistoryDialogComponent, {
            data: {
                asset: this.asset,
                note
            }
        })
    }


    copyNote(note: Attachment) {
        const id = `{{topic.${note.name}}}`;

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([id], { type: 'text/plain' })
        });

        navigator.clipboard.write([clipboardItem]);
    }


    addNote() {
        this.openEditDialog();
    }

    private openEditDialog(note?: Attachment) {
        firstValueFrom(this.dialog.open(NoteEditDialogComponent, {
            data: {
                asset: this.asset,
                note
            },
            panelClass: "dialog-draggable"
        }).afterClosed()).then((newNote) => {
            if (!newNote) return;

            const index = this.notes.findIndex(note => note.id == newNote.id);
            if (index >= 0) {
                this.notes.splice(index, 1, newNote);
            }
            else {
                try {
                    if (typeof newNote.data == 'string')
                        newNote.data = JSON.parse(newNote.data);
                } catch (ex) { }

                this.notes.push(newNote);
            }
            this.notes = [ ...this.notes ];
        });
    }
}
