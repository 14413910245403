import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { AutoCompleteAllModule, AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DTO, TagForEditor } from 'src/dto/dto';

const reservedTagNames = ['category', 'icon', 'color'];

@Component({
    selector: 'app-property-sheet',
    templateUrl: './property-sheet.component.html',
    styleUrl: './property-sheet.component.scss',
    standalone: true,
    imports: [
        HttpClientModule,
        MatTableModule,
        MatInputModule,
        MatIconModule,
        CommonModule,
        MatIconModule,
        MatAutocompleteModule,
        OverlayModule,
        AutoCompleteAllModule
    ]
})
export class PropertySheetComponent {
    public title = "Property Sheet";
    public subtitle = "";
    public tags: TagForEditor[] = [];
    public availableNames: string[] = [];
    public availableValues: string[] = [];

    private tagOptions: TagForEditor[] = [];

    private _asset: DTO;
    @Input() set asset(value: DTO) {
        this._asset = value;
        if (!this._asset.tags)
            this._asset.tags = [];

        this.tags = this._asset.tags.map(t => ({ ...t, originalTag: t })) as any as TagForEditor[];
        this.title = `${DTO.getUserFriendlyName(this.asset.dto)} Property Sheet`;
        this.subtitle = `${this._asset.tags.length} tags`;

        // TODO: Do an initial validation.
        this.checkPendingChanges();
    }
    get asset() {
        return this._asset;
    }

    constructor() { }

    ngOnInit() {
        // Get existing tags and their values. Users will still
        // be able to enter new tags and values.
        DTO.tagAPI.select().then((options: TagForEditor[]) => {
            this.tagOptions = options || [];
        })
        .catch(ex => {
            this.tagOptions = [];
        });
    }

    onTagEditStart(tagControl: AutoCompleteComponent) {
        const usedTagNames = this._asset.tags.map(tag => tag.name);
        this.availableNames = this.tagOptions.map(option => option.name).filter(name =>
            !usedTagNames.includes(name) &&
            !reservedTagNames.includes(name)
        );
        setTimeout(() => tagControl.showPopup(), 10);
    }

    onValueEditStart(valueControl: AutoCompleteComponent, row: TagForEditor) {
        this.availableValues = this.tagOptions.find(option => option.name == row.name)?.values || [];
        setTimeout(() => valueControl.showPopup(), 10);
    }

    onTagSelected(value: string, tagNameControl: AutoCompleteComponent, row: TagForEditor) {
        const usedTagNames = this._asset.tags.map(tag => tag.name);

        // Validate that this is a good tag name.
        if (!value) {
            row.isValid = false;
            row.error = "The tag name cannot be empty";
        }
        else if (usedTagNames.includes(value)) {
            row.error = "This is a duplicate tag name";
            row.isValid = false;
        }
        else if (reservedTagNames.includes(value)) {
            row.error = "This is a reserved tag name";
            row.isValid = false;
        }
        else if (!row.value) {
            row.error = "Tag must have a value";
            row.isValid = false;
        }
        else {
            row.error = null;
            row.isValid = true;
        }

        this.checkPendingChanges();
    }

    onValueSelected(value: string, tagValueControl: AutoCompleteComponent, row: TagForEditor) {
        row.hasChanged = row.originalTag?.value != value;

        if (!value) {
            row.error = "Tag must have a value";
            row.isValid = false;
        }
        else {
            row.error = null;
            row.isValid = true;
        }

        this.checkPendingChanges();
    }

    addTag() {
        this.tags.push({ name: '', value: '', values: [], isNew: true });

        this.checkPendingChanges();
    }

    removeTag(row: TagForEditor) {
        if (row.isNew) {
            this.tags = this.tags.filter(tag => tag != row);
        }
        else {
            row.isDelete = true;
        }

        this.checkPendingChanges();
    }

    async resetTag(row) {
        if (row.isNew) {
            this.tags = this.tags.filter(tag => tag != row);
        }
        else {
            row.name  = row.originalTag?.name  || "";
            row.value = row.originalTag?.value || "";
            row.hasChanged = false;
            row.isDelete = false;
        }
        this.checkPendingChanges();
    }

    async saveTag(row: TagForEditor) {
        if (!row.isValid) return;

        row.isSaving = true;
        let success = false;

        try {
            // NOTE: Renaming a tag is not allowed.
            let index = row.isNew
                      ? Number.POSITIVE_INFINITY
                      : this._asset.tags.findIndex(tag => tag.name == row.originalTag?.name);
            if (index < 0) {
                throw new Error("The original tag could not be found");
            }
            else if (row.isDelete) {
                success = await DTO.tagAPI.delete(this._asset, row.name);
                if (!success)
                    throw new Error("Could not delete this tag");

                this.tags = this.tags.filter(tag => tag.name != row.name);
                this._asset.tags.splice(index, 1);
            }
            else if (row.isNew) {
                success = await DTO.tagAPI.create(this._asset, { name: row.name, value: row.value });
                if (!success)
                    throw new Error("Could not create this tag");

                row.isNew = false;
                row.originalTag = { name: row.name, value: row.value };
                this._asset.tags.push(row.originalTag);
            }
            else if (row.hasChanged) {
                success = await DTO.tagAPI.update(this._asset, { name: row.name, value: row.value });
                if (!success)
                    throw new Error("Could not save this tag");

                row.hasChanged = false;
                this._asset.tags.splice(index, 1, { name: row.name, value: row.value });
            }
            else {
                // Do nothing.
            }

            if (success) {
                row.hasError = false;
                row.error = null;
            }
        }
        catch (ex) {
            row.hasError = true;
            row.error = ex;
        }
        finally {
            row.isSaving = false;

            this.subtitle = `${this._asset.tags.length || 0} tags`;
            this.checkPendingChanges();
        }
    }

    saveAllTags() {
        this.tags.forEach(tag => this.saveTag(tag));
    }

    public hasPendingChanges = false;
    private checkPendingChanges() {
        this.hasPendingChanges = this.tags
            .filter(tag => tag.isNew || tag.isDelete || tag.hasChanged)
            .filter(tag => tag.isValid)
            .length > 0;
    }
}
