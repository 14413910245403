import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    imports: [
        NgIf,
        MatButtonModule
    ],
    standalone: true
})
export class NotFoundComponent implements OnDestroy {

    @Input() scope = "";
    @Input() error: Error;

    isNewVersion = false;
    visible = false;
    _sub: Subscription;

    constructor(private readonly notificationService: NotificationService) {
        this.visible = location.hash != "#/";

        if (this.visible) {
            window.dtrum?.markAsErrorPage(404, "Could not find matching page from data source.");

            this._sub = notificationService.appVersion$.subscribe(v => {
                this.isNewVersion = v;
            })
        }
    }

    ngOnDestroy() {
        this._sub?.unsubscribe();
    }
}
