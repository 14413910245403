import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RootNavMenu } from 'src/app/components/navmenu/menu';
import { PersonaMenus } from 'src/app/components/navmenu/personamenu';
import { MenuDirective } from 'src/app/directives/menu.directive';
import { DialogService } from 'src/app/services/dialog.service';
import { NavMenuService } from 'src/app/services/nav-menu.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RouterService } from 'src/app/services/router.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UpdateService } from 'src/app/services/update.service';
import { BaseMenuItem, MenuItem as ContextMenuItem } from 'src/app/types/popups';
import { UserService } from '../../services/user.service';


export type Account = {
    es_tenant_name: string;
    account_name: string;
}

@Component({
    selector: 'app-navmenu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MenuDirective
    ]
})
export class NavmenuComponent {
    readonly rootMenu = RootNavMenu;
    readonly document = document;
    readonly Date = Date;

    personaMenu: BaseMenuItem[] = [];
    personaLabel: string;

    @Input() isMobile = false;

    public persona = "Persona";
    public personaShortForm = "";
    collapsed = false;
    showAdvancedMenu = false;
    allowAdvancedMenu = false;
    showAccountDropdown = false;
    loadingAccount = true;
    notificationCount = 0;

    dropdownHiddenTime = 0;

    accounts: Account[] = [];
    filteredAccounts: Account[] = [];

    headless = location.href.includes("headless=true");

    usefulLinks: ContextMenuItem[] = [
        { label: "Setup" },
        { label: "Intro" },
        "separator",
        { label: "Docs" },
        { label: "Release Notes" },
        // "separator",
        // { label: "News" },
        // { label: "Docs" },
    ]

    supportLinks: ContextMenuItem[] = [
        { label: "Dynatrace Documentation", link: "https://dt-url.net/docs", linkTarget: "_blank" },
        { label: "Dynatrace University", link: "https://dt-url.net/0j2214q", linkTarget: "_blank" },
        { label: "Elevate Video Series", link: "https://university.dynatrace.com/ondemand/course/44824?content=content&section=44825", linkTarget: "_blank" },
        // { label: "Community" }, // stackoverflow?

        // "separator",
        // { label: "Elevate API" },
        // { label: "Support Ticket" },
    ]

    profileLinks: ContextMenuItem[] = [
        // {
        //     label: "Personalize Menu",
        //     action: () => {

        //     }
        // },
        // "separator",
        {
            label: "Preferences",
            icon: 'settings',
            link: "/#/UserPreferences"
        },
        {
            label: "Setup Wizard",
            icon: 'face',
            action: () => this.openPersonaModal()
        },
        {
            label: "Feedback",
            icon: 'thumb_up_alt',
            action: () => this.openFeedback()
        },
        {
            label: "Appearance",
            icon: 'invert_colors',
            children: [
                // {
                //     label: "Browser Theme",
                //     action: () => {

                //     }
                // },
                // "separator",
                {
                    // label: "Light",
                    // 🔳🔲⬛⬜
                    labelTemplate: () => (this.theme.theme == "light" ? '🔵' : '\xA0\xA0\xA0\xA0\xA0') + ' light',
                    action: () => {
                        this.theme.setLightTheme();
                    }
                },
                {
                    // label: "Dark",
                    labelTemplate: () => (this.theme.theme == "light" ? '\xA0\xA0\xA0\xA0\xA0' : '🔵') + ' dark',
                    action: () => {
                        this.theme.setDarkTheme();
                    }
                }
            ]
        },
        { label: "Terms of Use", link: "/#/Terms", icon: "policy" },
        { label: "Account Management", link: "https://dt-url.net/myaccount", linkTarget: "_blank", icon: "manage_accounts" },
        "separator",
        { label: "Log out", icon: 'logout', link: "/api/logout?ngsw-bypass=true" },
        {
            labelTemplate: () =>
                "Version " + this.updateService.gatewayVersion + "_" + this.updateService.packageVersion,
            icon: "rocket_launch",
            link: "/#/ReleaseNotes",
        },
    ]

    constructor(
        public readonly user: UserService,
        public readonly updateService: UpdateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly theme: ThemeLoaderService,
        private readonly dialog: DialogService,
        public readonly navMenu: NavMenuService,
        private readonly router: RouterService,
        private readonly toaster: ToasterService,
        public readonly tenant: TenantService,
        private readonly notifications: NotificationService
    ) {
        user.favorites$.subscribe(f => {
            this.changeDetector.markForCheck();
            // this.changeDetector.detectChanges();
        })

        this.personaShortForm = this.user.getCurrentUserPersonaLabel();

        // When the navigation menu gets a new menu, we update here.
        // navMenu.navMenu$.subscribe(menuItems => {
        //     if (!menuItems) return;

        //     // It may be possible that we're still looking at the user object as it was returned
        //     // to us before the user switched tenants. And if so, we're using the wrong persona.
        //     this.persona = this.user.getPersonaLabel();
        // });

        user.preferences$.subscribe(pref => {
            this.allowAdvancedMenu = pref.general?.menumode != "beginner";
            this.showAdvancedMenu  = pref.general?.menumode == "advanced";

            const personaMenu = PersonaMenus.find(m => m.id == user.persona);
            this.personaMenu = personaMenu?.children ?? [];
            this.personaLabel = personaMenu?.label;

            this.personaMenu.forEach(m => {
                m['_link'] = m.linkTemplate?.({ user: this.user, tenant: this.tenant }, null)
            });
        });

        notifications.subscribe(n => {
            if (!n) return;
            this.notificationCount = n.responsible.length;
        });

        tenant.subscribe(t => {
            this.loadingAccount = false;
        })

        this.accounts = this.user.tenants as any;
        this.filterAccounts('');
    }

    hideDropdown(el: HTMLInputElement, msg?) {
        this.showAccountDropdown = false;
        this.filterAccounts('');
        el.value = '';
        this.dropdownHiddenTime = Date.now();
    }

    filterAccounts(search: string) {
        this.filteredAccounts = this.accounts.filter(acc => 1);
        // Whitespace doesn't count.
        if (search.trim().length == 0) {
            this.filteredAccounts = this.accounts;
            this.accounts.forEach(account => {
                account['_renderedLabel'] = '';
                account['_renderedHint'] = '';
            })
            return;
        }

        const queryWords = search
            .toLowerCase()
            .split(' ');

        const matchedAccounts = new Map<string, Account>();

        for (const account of this.accounts) {
            // Check the label
            if (account.account_name) {
                const commandChars = account.account_name
                    .toLowerCase();

                let renderedLabel = '';
                let lastIndex = 0;
                let isMatch = true;

                for (const char of queryWords) {
                    const index = commandChars.indexOf(char, lastIndex);

                    if (index == -1) {
                        isMatch = false;
                        break;
                    }
                    else {
                        renderedLabel += account.account_name.slice(lastIndex, index) + `<b>${account.account_name.slice(index, index + char.length)}</b>`;
                        lastIndex = index + char.length;
                    }
                }

                renderedLabel += account.account_name.slice(lastIndex);

                if (isMatch) {
                    account['_renderedLabel'] = renderedLabel;
                    matchedAccounts.set(account.es_tenant_name, account);
                }
            }

            // Check the hint
            if (account.es_tenant_name) {
                const commandChars = account.es_tenant_name
                    .toLowerCase()
                    .split('');

                let renderedHint = '';
                let lastIndex = 0;
                let isMatch = true;

                for (const char of queryWords) {
                    const index = commandChars.indexOf(char, lastIndex);

                    if (index == -1) {
                        isMatch = false;
                        break;
                    }
                    else {
                        renderedHint += account.es_tenant_name.slice(lastIndex, index) + `<b>${account.es_tenant_name.slice(index, index + 1)}</b>`;
                        lastIndex = index + 1;
                    }
                }

                renderedHint += account.es_tenant_name.slice(lastIndex);

                if (isMatch) {
                    account['_renderedHint'] = renderedHint;
                    matchedAccounts.set(account.es_tenant_name, account);
                }
            }
        }

        this.filteredAccounts = [...matchedAccounts.entries()].map(e => e[1]);
    }

    openPersonaModal() {
        this.user.openPersonaSelection().then(() => {
            this.persona = this.user.getPersonaLabel();
            this.personaShortForm = this.user.getCurrentUserPersonaLabel();
        })
    }

    toggleMenu() {
        this.user.updatePreference(
            "general",
            "menumode",
            this.showAdvancedMenu ? "intermediate" : "advanced"
        );
    }

    openAskElevate() {
        // Hide the dialog if it's already present
        if (document.querySelector('.dialog-askElevate')) {
            window['__askElevate']?.dialog?.close();
            return;
        };

        const lastX = localStorage["es-openai-dialog-x"] || '0px';
        const lastY = localStorage["es-openai-dialog-y"] || '0px';

        let pos;
        if (lastX != '0px') {
            pos = {
                left: lastX == '0px' ? null : lastX,
                top: lastY == '0px' ? null : lastY
            };
        }
        else {
            pos = {
                left: this.collapsed ? '56px' : this.isMobile ? '300px' : '200px',
                top: '46px'
            }
        }

        this.dialog.open("AskElevate", {
            disableClose: true,
            restoreFocus: true,
            hasBackdrop: false,
            panelClass: ["dialog-askElevate", "dialog-draggable"],
            position: pos,
            isModal: false
        })
    }

    openFeedback() {
        this.dialog.open('nav-feedback', { isDark: true })
    }

    onAccountChange(tenantId: string) {
        const tenant = this.accounts.find(a => a.es_tenant_name == tenantId);
        // Skip if there is no tenant or if the tenant is not changed
        if (!tenant || tenant.es_tenant_name == this.tenant.value.es_tenant_name) return false;

        if (!this.user.checkTenantAccess(tenant)) {
            this.toaster.warn("Failed to select client.", `Tenant ${tenant.es_tenant_name} was not found. Please validate your accessing the correct resource and you have sufficient rights to view it.`);
            return false;
        }

        this.loadingAccount = true;
        this.user.switchAccounts(tenant);

        return true;
    }

    displayFn(account: Account): string {
        return account.account_name;
    }

    openNotifications(el: HTMLElement) {
        // If the dialog is already open, close it.
        if (document.querySelector('.dialog-nav-actionCenter')) {
            el.classList.remove("active");
            window['__actionCenter']?.dialog?.close();
            return;
        };

        el.classList.add("active");

        this.dialog.open("nav-actionCenter", {
            inputs: {
                ...this.notifications.value
            },
            closeOnNavigation: false,
            restoreFocus: true,
            panelClass: "dialog-actionCenter",
            isDark: true,
            isModal: false,
            position: {
                bottom: "200px",
                left: this.collapsed ? "56px" : "200px"
            }
        }).then(() => {
            el.classList.remove("active");
        });
    }
}
